import { ActionTypes } from "../constant/action-types";
import { fetchuserApi } from "../../Apis/AuthApis";


export const fetchUser = () => {
  return async function (dispatch, getState) {
    const response = await fetchuserApi();
    dispatch({
      type: ActionTypes.FETCH_USER,
      payload: response?.data?.data?.user,
    });
  };
};

export const clearUser = () => {
  return {
    type: ActionTypes.CLEAR_USER,
    payload: [],
  };
};
