import React, { useState, useEffect } from "react";
import "../Styles/Category.css";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import Radio from "@mui/material/Radio";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AiFillStar } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ToggleButton from "@mui/material/ToggleButton";
import Sidebar2 from "../Sidebar/Sidebar2";
import {
  fetchCategoryProduuctApi,
  fetchChildCategoryApi,
} from "../Apis/MainApis";
import { fetchBrandApi } from "../Apis/MainApis";
import { Checkbox } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

function Category() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoryid = params?.pid;
  const childcategoryid = params?.cid;
  const categoryslug = params?.slug;

  const userid = useSelector((state) => state?.user?.user?.id);

  const [totalPageCount, setPageCount] = useState();
  const [childId, setChildId] = useState();
  const [childcategory, setChildCategory] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPrice, setMaxPrice] = useState(20000000);
  const [minPrice, setMinPrice] = useState(0);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedRating, setSelectedRating] = useState(0);
  const [wishlistchange, setWishlistChange] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchBrandApi();
      setBrands(response?.data?.data?.brands);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [categoryid]);

  useEffect(() => {
    async function fetchproduct() {
      const lang = localStorage.getItem("Yaliwe_language");
      setLoading(true);
      const data = {
        page: currentPage + 1,
        language: lang || "en",
        categoryId: categoryid,
        uid: userid || "",
        brandIds: selectedBrands,
        maxPrice: maxPrice,
        minPrice: minPrice,
        minRating: selectedRating,
      };
      // const data2 = {
      //   page: 1,
      //   language: "en",
      //   parent_id: categoryid,
      // };
      const response1 = await fetchCategoryProduuctApi(data);
      console.log("fetchCategoryProduuctApi", response1);
      setPageCount(
        Math.ceil(
          response1?.data?.data?.categoryProducts?.[0]?.totalFilteredProduct /
            10
        )
      );
      setFilteredProducts(response1?.data?.data?.categoryProducts);

      // if (categoryid) {
      //   const response2 = await fetchChildCategoryApi(data2);
      //   setChildCategory(response2?.data?.data?.childCategories);
      // }
      setLoading(false);
    }
    fetchproduct();
  }, [
    categoryid,
    userid,
    wishlistchange,
    childId,
    currentPage,
    selectedBrands,
    minPrice,
    maxPrice,
    selectedRating,
  ]);

  useEffect(() => {
    setChildId(childcategoryid);
  }, [childcategoryid]);

  const handleRatingCheckboxChange = (event) => {
    setCurrentPage(0);
    const rating = parseFloat(event.target.value);
    setSelectedRating(rating);
  };

  const handleBrandCheckboxChange = (event) => {
    setCurrentPage(0);
    const brand = event.target.value;

    const isBrandSelected = selectedBrands.includes(brand);

    if (isBrandSelected) {
      setSelectedBrands(
        selectedBrands.filter((selectedBrand) => selectedBrand !== brand)
      );
    } else {
      setSelectedBrands([...selectedBrands, brand]);
    }
  };

  const handleClearAllFilter = () => {
    setCurrentPage(0);
    setMinPrice(0);
    setMaxPrice(20000000);
    setSelectedBrands([]);
    setSelectedRating(0);
  };

  const handleCategoryNavigate = (id, productslug) => {
    navigate(`/product/${id}/${productslug}`);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const handleChildCategories = (childid) => {
    setSelectedRating(null);
    navigate(`/category/${categoryid}/${categoryslug}/${childid}`);
  };

  const handlePriceClear = () => {
    setCurrentPage(0);
    setMinPrice(0);
    setMaxPrice(20000000);
  };

  const handleReviewClear = () => {
    setCurrentPage(0);
    setSelectedRating(0);
  };

  const handleBrandClear = () => {
    setCurrentPage(0);
    setSelectedBrands([]);
  };

  const handleCategoryClear = () => {
    navigate(`/category/${categoryid}`);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const closeSidebar2 = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div>
        <div className="container-fluid py-5">
          <div className="row">
            <div className="col-lg-3 ">
              <div className="col-10 d-lg-none category_toggle d-flex justify-content-start">
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                  <FormatAlignLeftIcon />
                </ToggleButton>
                <Sidebar2
                  isOpen={isSidebarOpen}
                  closeSidebar2={closeSidebar2}
                  handleClearAllFilter={handleClearAllFilter}
                  handlePriceClear={handlePriceClear}
                  handleBrandClear={handleBrandClear}
                  handleBrandCheckboxChange={handleBrandCheckboxChange}
                  handleReviewClear={handleReviewClear}
                  selectedRating={selectedRating}
                  handleRatingCheckboxChange={handleRatingCheckboxChange}
                  minPrice={minPrice}
                  setMinPrice={setMinPrice}
                  maxPrice={maxPrice}
                  setMaxPrice={setMaxPrice}
                  brands={brands}
                  selectedBrands={selectedBrands}
                />
              </div>
              <div className="d-lg-block d-none category_filter category-scroll-column">
                <div className="d-flex align-items-center justify-content-between category_filtertop">
                  <h4 className="m-0">{t("filters")}</h4>
                  <span onClick={handleClearAllFilter}>{t("clearall")}</span>
                </div>
                <hr />
                {/* {childcategory?.length !== 0 ? (
                  <>
                    <div className="category_categories text-start">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>CATEGORIES</h4>
                        <span onClick={handleCategoryClear}>Clear</span>
                      </div>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" column="true">
                          {childcategory?.map((product, i) => (
                            <FormControlLabel
                              key={i}
                              value={product.name}
                              control={
                                <Radio
                                  checked={
                                    product.id.toString() === childcategoryid
                                  }
                                  onChange={() =>
                                    handleChildCategories(product.id)
                                  }
                                />
                              }
                              label={product.name}
                              labelPlacement="end"
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </div>
                  </>
                ) : (
                  ""
                )} */}
                <div className="category_filterprice">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="m-0">{t("prices")}</h4>
                    <span onClick={() => handlePriceClear()}>{t("clear")}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <FormControl sx={{ m: 1, minWidth: 20 }} size="small">
                        <InputLabel id="demo-select-small-label">
                          {t("min")}
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={minPrice}
                          label="Age"
                          onChange={(e) => {
                            setMinPrice(e.target.value);
                            setCurrentPage(0);
                          }}
                        >
                          <MenuItem value="0">
                            <em>0</em>
                          </MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                          <MenuItem value={500}>500</MenuItem>
                          <MenuItem value={1000}>1000</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl sx={{ m: 1, minWidth: 20 }} size="small">
                        <InputLabel
                          id="demo-select-small-label"
                          style={{ zIndex: "-1" }}
                        >
                          {t("max")}
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={maxPrice}
                          label="Age"
                          onChange={(e) => {
                            setMaxPrice(e.target.value);
                            setCurrentPage(0);
                          }}
                        >
                          <MenuItem value="20000000">
                            <em>1500+</em>
                          </MenuItem>
                          <MenuItem value={500}>500</MenuItem>
                          <MenuItem value={1000}>1000</MenuItem>
                          <MenuItem value={15000000}>1500+</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="category_brandsheading m-0">{t("brands")}</h4>
                  <span
                    onClick={() => handleBrandClear()}
                    className="category_brandsclear"
                  >
                    {t("clear")}
                  </span>
                </div>
                <div className="text-start mb-3 category_brandformcontrol">
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" column="true">
                      {brands?.map((brand, i) => (
                        <FormControlLabel
                          key={i}
                          value={brand.id}
                          control={
                            <Checkbox
                              checked={selectedBrands.includes(
                                brand.id.toString()
                              )}
                              onChange={handleBrandCheckboxChange}
                            />
                          }
                          label={brand.name}
                          labelPlacement="end"
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </div>
                <hr />
                <div className="text-start category_customerReviews mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="m-0"> {t("reviews")}</h4>
                    <span
                      onClick={() => handleReviewClear()}
                      className="category_reviewsclear"
                    >
                      {t("clear")}
                    </span>
                  </div>

                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" column="true">
                      {Array.from({ length: 5 }, (_, index) => (
                        <FormControlLabel
                          key={index + 1}
                          value={(index + 1).toString()}
                          control={
                            <Radio
                              checked={selectedRating === index + 1}
                              onChange={handleRatingCheckboxChange}
                            />
                          }
                          label={`${index + 1} & up`}
                          labelPlacement="end"
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="col-lg-9 mt-lg-0 mt-5 category-scroll-column">
              <div className="row gy-3" style={{ height: "100%" }}>
                {loading ? (
                  <div className="category-loader-container">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </div>
                ) : (
                  <>
                    {filteredProducts?.length === 0 ||
                    filteredProducts === undefined ? (
                      <>
                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                          <img src="/noitemsfound.png" alt=""></img>
                          <p>{t("noitemfound")}</p>
                        </div>
                      </>
                    ) : (
                      filteredProducts?.map((product, i) => (
                        <div
                          className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 "
                          key={i}
                        >
                          <div
                            className="card categoryproduct_card"
                            onClick={() =>
                              handleCategoryNavigate(product.id, product.slug)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="categoryproduct_cardtopportion"
                              onClick={() =>
                                handleCategoryNavigate(product.id, product.slug)
                              }
                            >
                              <div className="categoryproduct_imagebackground">
                                <img
                                  className="categoryproduct_image"
                                  src={product?.featured_image}
                                  alt=""
                                ></img>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <span className="d-flex align-items-center category_ratingbox ms-1">
                                    {product?.averageRating}
                                    <AiFillStar className="ms-1" />
                                  </span>
                                </div>
                              </div>
                              <p className="categoryproduct_cardtext text-start fw-bold m-0 py-2">
                                {product.name}
                              </p>
                              <div className="categoryproducts_span text-start d-flex align-items-center">
                                <span className="fw-bold">
                                  ${product?.sale_price}
                                </span>
                                <span className="product_actualprice">
                                  <div className="diagonal-line-wrapper">
                                    ${product?.base_price}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </>
                )}
                <div className="category_paginate">
                  {totalPageCount > 1 && (
                    <ReactPaginate
                      key={totalPageCount}
                      pageCount={totalPageCount}
                      breakLabel={"..."}
                      pageRangeDisplayed={0} //... ke bech me pages
                      marginPagesDisplayed={2} //first and last pages ...
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active-page"}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Category;
