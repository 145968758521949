import React, { useEffect } from "react";
import "../Styles/TopNavbar.css";
import { IoMdCall } from "react-icons/io";
import { BiCheckboxChecked } from "react-icons/bi";
import { MdOutlineLocalShipping } from "react-icons/md";
import { MdOutlineRefresh } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";
import { RiArrowDropDownFill } from "react-icons/ri";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function TopNavbar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const lang = localStorage.getItem("Yaliwe_language");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  const changeLanguage = (lang) => {
    localStorage.setItem("Yaliwe_language", lang);
    window.location.reload();
  };

  return (
    <>
      <div className="topnavbar_background py-1">
        <div className="container">
          <div className="row topnavbar_left d-flex align-items-center justify-content-start ">
            <div className="col-md-10 text-white d-flex align-items-center">
              <ul className="d-flex align-items-center topnavbar_ul">
                <li>
                  <div className="d-flex align-items-center">
                    <IoMdCall className="me-1" />
                    +971 54 265 6576<div className="topnavbar_divider">|</div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center">
                    support@yaliwe.com<div className="topnavbar_divider">|</div>
                  </div>
                </li>
                <li className="me-2">
                  <div className="d-flex align-items-center">
                    9.00AM - 6.30PM
                  </div>
                </li>

                <li className="me-2">
                  <div className="d-flex align-items-center">
                    <MdOutlineLocalShipping className="me-1" />
                    {t("operatebyubslogistic")}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-2 mt-2 mt-md-0 text-white d-flex align-items-center justify-content-end">
              <div
                className="topnavbar_rightbox me-3 d-flex align-items-center"
                onClick={() => {
                  changeLanguage("en");
                }}
              >
                <img
                  src="/english.png"
                  alt=""
                  style={{ width: "20px", height: "17px" }}
                ></img>
                <span>English</span>
              </div>
              <div
                className="topnavbar_rightbox me-3 d-flex align-items-center"
                onClick={() => {
                  changeLanguage("fr");
                }}
              >
                <img
                  src="/french.png"
                  alt=""
                  style={{ width: "20px", height: "15px", marginRight: "2px" }}
                ></img>
                <span>French</span>
              </div>
              <div
                className="topnavbar_rightbox d-flex align-items-center"
                // onClick={() => navigate("/success/orderId=48")}
              >
                USD
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopNavbar;
