import React from "react";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import { MdDelete } from "react-icons/md";
import "../Styles/Wishlist.css";
import { useSelector } from "react-redux";
import {
  removeFromWishlist,
  fetchWishlist,
} from "../redux/actions/wishlistActions";
import { useDispatch } from "react-redux";
import { fetchHomeProducts } from "../redux/actions/HomeProductsAction";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function Wishlist() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wishlistdata = useSelector((state) => state?.wishlist);
  const userid = useSelector((state) => state?.user?.user?.id);

  const handleWishlistDelete = async (id) => {
    if (userid === undefined) {
      toast.error("Login to continue");
      navigate("/login");
      return;
    }
    await dispatch(removeFromWishlist(id));
    await dispatch(fetchWishlist());
    await dispatch(fetchHomeProducts());
  };

  const handleAddToCart = (productid, productslug) => {
    navigate(`/product/${productid}/${productslug}`);
  };
  return (
    <>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="container mt-5 wishlist_container">
        <div className="row">
          <div className="col-lg-12">
            <table className="table wishlist_table">
              <thead>
                <tr>
                  <th>{t("mywishlist")}</th>
                </tr>
              </thead>
              <tbody>
                {wishlistdata?.wish?.length === 0 ? (
                  <tr>
                    <td>
                      <img
                        src="emptywishlist.png"
                        alt=""
                        style={{ width: "200px" }}
                      ></img>
                      <p>{t("yourwishlistisempty")}</p>
                    </td>
                  </tr>
                ) : (
                  wishlistdata?.wish?.map((wishlist, i) => (
                    <tr key={i}>
                      <td className="d-flex flex-column wishlist_data">
                        <div className="row">
                          <div className="col-lg-4 col-md-5 d-flex justify-content-center">
                            <img
                              src={wishlist?.featured_image}
                              className="wishlist_image"
                              alt=""
                            />
                          </div>
                          <div className="wishlist_info d-flex flex-column justify-content-center col-lg-8 col-md-7 mt-3 mt-md-0">
                            <div className="text-start">
                              <p>
                                <span className="fw-bold">
                                  {t("product")}:{" "}
                                </span>
                                {wishlist.name}
                              </p>
                              <p>
                                <span className="fw-bold">{t("price")}: </span>$
                                {wishlist.sale_price}
                              </p>

                              <div
                                className="wishlist_addbutton d-flex align-items-center justify-content-center"
                                onClick={() =>
                                  handleAddToCart(
                                    wishlist.prodId,
                                    wishlist.slug
                                  )
                                }
                              >
                                {t("view")}
                              </div>

                              <div
                                className="d-flex align-items-center mt-2"
                                onClick={() =>
                                  handleWishlistDelete(wishlist.prodId)
                                }
                              >
                                <span className="wishlist_deletebuttontext">
                                  {t("remove")}
                                </span>
                                <MdDelete className="wishlist_deletebutton" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Wishlist;
