import React from "react";
import Modal from "react-bootstrap/Modal";
import { Stepper } from "react-form-stepper";
import { useTranslation } from "react-i18next";

function TrackingModal({ showTrackingModal, setShowTrackingModal }) {
  const { t } = useTranslation();
  return (
    <Modal
      show={showTrackingModal}
      onHide={() => setShowTrackingModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Track Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stepper
          steps={[
            { label: t("orderconfirmed") },
            { label: t("orderdispatch") },
            { label: t("orderdelivered") },
          ]}
          activeStep={2}
        />
      </Modal.Body>
    </Modal>
  );
}

export default TrackingModal;
