import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddressModal from "../Modal/AddressModal";
import NewAddressModal from "../Modal/NewAddressModal";
import { deleteAddressApi } from "../Apis/MainApis";
import { fetchAllAddress } from "../redux/actions/AddressAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Styles/MyAddresses.css";
import { useTranslation } from "react-i18next";

function MyAddresses() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addresses = useSelector((state) => state?.addresses?.addresses);
  const [addressid, setAddressId] = useState();
  const [openAddressModal, setAddressModal] = useState(false);
  const [openNewAddressModal, setNewAddressModal] = useState(false);

  const handleDeleteAddress = async (id) => {
    const data = {
      addressId: id,
    };
    const response = await deleteAddressApi(data);
    toast(response?.data?.message);
    dispatch(fetchAllAddress());
  };

  const handleCheckoutUpdate = (id) => {
    setAddressId(id);
    setAddressModal(true);
  };
  return (
    <>
      <AddressModal
        openAddressModal={openAddressModal}
        setAddressModal={setAddressModal}
        addressid={addressid}
      />
      <NewAddressModal
        openNewAddressModal={openNewAddressModal}
        setNewAddressModal={setNewAddressModal}
      />
      <table className="table myaddresses_DeliveryTable">
        <thead>
          <tr className="text-start">
            <th> {t("youraddresses")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="d-flex flex-column myaddresses_alladdressdata">
              {addresses?.length === 0 ? (
                <tr className="d-flex flex-column align-items-center justify-content-center">
                  <td>
                    <img
                      src="/adddress.jpg"
                      alt=""
                      style={{ width: "200px" }}
                    ></img>
                    <p> {t("youhavenotaddedanyaddressyet")}</p>
                  </td>
                </tr>
              ) : (
                <>
                  {addresses?.map((address, i) => (
                    <label className="d-flex  py-3" key={i}>
                      <div className="text-start myaddresses_addressinfo">
                        <p>
                          {address.name} ({address.address_type})
                        </p>
                        <p>
                          ({address.phone_code}) {address.mobile}
                        </p>
                        <p>
                          {address.street} , {address.landmark} , {address.city}{" "}
                          , {address.state} , {address.code}{" "}
                        </p>
                        <button
                          className="myaddresses_DeliveryButton me-2"
                          onClick={() => handleCheckoutUpdate(address.id)}
                        >
                          {t("update")}
                        </button>
                        <button
                          className="myaddresses_DeliveryButton"
                          onClick={() => handleDeleteAddress(address.id)}
                        >
                          {t("remove")}
                        </button>
                      </div>
                    </label>
                  ))}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default MyAddresses;
