import React, { useEffect } from "react";
import "../Styles/BottomNavbar.css";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useState } from "react";
import { useSelector } from "react-redux";
import { parentCategoryApi } from "../Apis/MainApis";
import { useTranslation } from "react-i18next";

function BottomNavbar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isBottomNavbarDropdownOpen, setIsDropdownOpen] = useState(false);
  const [parentcategory, setParentCategory] = useState([]);

  const collectionData = useSelector(
    (state) => state?.homeproducts?.homeproducts
  );

  const toggleDropdown = () => {
    setIsDropdownOpen(!isBottomNavbarDropdownOpen);
  };

  const handleFeatureCategoriesNavigate = (id, slug) => {
    navigate(`/category/${id}/${slug}`);
  };

  useEffect(() => {
    async function fetchparentcategory() {
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        language: lang || "en",
      };
      const response = await parentCategoryApi(data);
      setParentCategory(response?.data?.data?.categories);
    }
    fetchparentcategory();
  }, []);


  return (
    <>
      <div className="bottomnavbar_background">
        <div className="container">
          <div className="row d-flex align-items-center py-1">
            <div className=" col-xl-2  col-md-2   d-flex align-items-center justify-content-start p-0">
              <div className="bottomnavbar_dropdown" onClick={toggleDropdown}>
                <p className="m-0">
                  {t("categories")}{" "}
                  <span>
                    <MdOutlineKeyboardArrowDown />
                  </span>
                </p>
                {isBottomNavbarDropdownOpen && (
                  <div className="bottom_navbardropdown">
                    <ul>
                      {parentcategory?.map((product, i) => (
                        <li
                          key={i}
                          onClick={() =>
                            handleFeatureCategoriesNavigate(
                              product?.id,
                              product?.slug
                            )
                          }
                        >
                          {product?.name?.toUpperCase()}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-8  col-md-8 center_items">
              <ul className="d-flex justify-content-between align-items-center ">
                <li className="col-md-2 bottomnavbar_contents">
                  <p
                    className="m-0 text-white fw-bold"
                    onClick={() => navigate("/")}
                  >
                    {t("home")}
                  </p>
                </li>
                {parentcategory?.slice(8, 11).map((item, i) => {
                  return (
                    <li
                      key={i}
                      className="col-md-2 bottomnavbar_contents"
                      onClick={() =>
                        handleFeatureCategoriesNavigate(item?.id, item?.slug)
                      }
                    >
                      <p className="m-0 text-white fw-bold">
                        {item?.name?.toUpperCase()}
                      </p>
                    </li>
                  );
                })}

                <li className="col-md-2 bottomnavbar_contents">
                  <p
                    className="m-0 text-white fw-bold text-start"
                    onClick={() => navigate("/find-products")}
                  >
                    {t("hardtofind")}
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 d-flex align-items-center justify-content-end cursor-pointer">
              <a
                className="list-group-item list-group-item-action"
                href="https://web.whatsapp.com/"
                target="_blank"
              >
                <div className="bottomnavbar_number text-white fw-bold d-flex justify-content-center align-items-center cursor-pointer">
                  <img
                    className="mx-2"
                    src="/whatapp-removebg-preview.png"
                    width={30}
                    height={30}
                  />
                  +971 54 265 6576
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BottomNavbar;
