import { ActionTypes } from "../constant/action-types";

const initialState = {
  homeproducts: [],
};

export const homeProductReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_HOMEPRODUCTS:
      return { ...state, homeproducts: payload };
    default:
      return state;
  }
};
