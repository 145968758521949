import { ActionTypes } from "../constant/action-types";

const initialState = {
  user: [],
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_USER:
      return { ...state, user: payload || []}; 
    case ActionTypes.CLEAR_USER:
      return { ...state, user: [] };
    default:
      return state;
  }
};
