import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { orderStatusApi } from "../Apis/MainApis";
import "../Styles/Failed.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Failed() {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const id = orderId?.split("=")[1];
  const navigate = useNavigate();

  useEffect(() => {
    async function orderstatus() {
      const data = {
        orderId: id,
      };
      await orderStatusApi(data);
    }
    orderstatus();
  }, [id]);
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="failed_card">
        <div>
          <i class="fa-solid fa-xmark-large"></i>
        </div>
        <h1>{t("paymentfailed")}</h1>
        <p className="mb-2">{t("pleasetryagainlater")}</p>
        <span className="checkout_homepage" onClick={() => navigate("/")}>
          {t("gotohomepage")}
        </span>
      </div>
    </div>
  );
}

export default Failed;
