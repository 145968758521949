export const ActionTypes = {
  FETCH_PRODUCTS: "FETCH_PRODUCTS",
  EMPTY_PRODUCTS: "EMPTY_PRODUCTS",

  FETCH_USER: "FETCH_USER",
  CLEAR_USER: "CLEAR_USER",

  FETCH_WISHLIST: "FETCH_WISHLIST",
  EMPTY_WISHLIST: "EMPTY_WISHLIST",

  FETCH_HOMEPRODUCTS: "FETCH_HOMEPRODUCTS",

  FETCH_ALLADDRESS: "FETCH_ALLADDRESS",
  EMPTY_ALLADDRESS: "EMPTY_ALLADDRESS",

  FETCH_PARENTCATEGORIES: "FETCH_PARENTCATEGORIES",

  SET_LOADING: "SET_LOADING",
};
