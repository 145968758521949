import React from "react";
import { MdLocationOn } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { useState } from "react";
import "../Styles/ContactUs.css";
import Navbar from "./Navbar";
import TopNavbar from "./TopNavbar";
import BottomNavbar from "./BottomNavbar";
import Footer from "./Footer";
import { contactUsApi } from "../Apis/MainApis";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const { t } = useTranslation();
  const [admindata, setAdminData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contactusdata = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };
    try {
      const response = await contactUsApi(contactusdata);
      console.log(response);
      Swal.fire({
        icon: "success",
        title:  t("contactus"),
        text: response.data.message,
      });
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="contact-us">
        <div className="container py-5">
          <div className="row justify-content-center mt-5">
            <div className="col-12 col-lg-2"></div>
            <div className="col-12 col-lg-8">
              <div className="row g-3">
                <div className="col-12 col-lg-4">
                  <div className="contact-card d-flex flex-column align-items-center">
                    <MdLocationOn className="contact-icon" />
                    <h4 className="contact-title">{t("ourmainoffice")}</h4>
                    <p className="contact-text">AL NAHDA 2,DUBAI </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="contact-card d-flex flex-column align-items-center">
                    <AiFillPhone className="contact-icon" />
                    <h4 className="contact-title">{t("phonenumber")}</h4>
                    <p className="contact-text">94837293784</p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="contact-card d-flex flex-column align-items-center">
                    <MdEmail className="contact-icon" />
                    <h4 className="contact-title">{t("email")}</h4>
                    <p className="contact-text">support@yaliwe.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>

          <div className="row">
            <div className="col-md-6 offset-md-3 text-center mt-5 mb-5 contact-form-background">
              <h2 className="contact-form-heading mb-3">{t("contactus")}</h2>
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control contact-input"
                    placeholder={t("enteryourname")}
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control contact-input"
                    placeholder={t("enteravalidemailaddress")}
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control contact-input"
                    rows="4"
                    placeholder={t("message")}
                    value={formData.message}
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  />
                </div>
                <button type="submit" className="contact-submit-button">
                {t("submit")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
