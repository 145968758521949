import React, { useState } from "react";
import "./LoginModal.css";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { LoginApi } from "../Apis/AuthApis";
import { fetchUser } from "../redux/actions/userActions";
import { fetchCart } from "../redux/actions/productActions";
import { fetchWishlist } from "../redux/actions/wishlistActions";
import { fetchHomeProducts } from "../redux/actions/HomeProductsAction";
import { fetchAllAddress } from "../redux/actions/AddressAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function LoginModal({ show, onClose }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login_validationErrors, setlogin_ValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const login_resetForm = () => {
    setEmail("");
    setPassword("");
    setlogin_ValidationErrors({});
  };

  const login_handleClose = () => {
    onClose();
    login_resetForm();
  };

  const login_validateform = () => {
    const login_errors = {};
    if (!email) {
      login_errors.email = t("emailisrequired");
    }
    if (!password) {
      login_errors.password = t("passwordisrequired");
    }
    setlogin_ValidationErrors(login_errors);
    return Object.keys(login_errors).length === 0;
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (login_validationErrors.email) {
      setlogin_ValidationErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (login_validationErrors.password) {
      setlogin_ValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (login_validateform()) {
      const userData = {
        email: email,
        password: password,
      };
      LoginApi(userData, (response) => {
        console.log(response);
        if (response.data.message === "Your email verification pending!") {
          Swal.fire({
            icon: "error",
            title: "Yaliwe",
            text: response.data.message,
          });
          return;
        } else if (response.data.message === "Invalid credentials.") {
          Swal.fire({
            icon: "error",
            title: "Yaliwe",
            text: response.data.message,
          });
          return;
        }

        localStorage.setItem("Yaliwe_Token", response?.data?.data?.token);
        onClose();
        login_resetForm();
        dispatch(fetchUser());
        dispatch(fetchCart());
        dispatch(fetchWishlist());
        dispatch(fetchHomeProducts());
        dispatch(fetchAllAddress());
      });
    } else {
      console.log("Form is invalid!");
    }
  };
  return (
    <>
      <Modal show={show} onHide={login_handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="login_heading_title fw-bold">
            {t("logintocontinue")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label className="signin-label"> {t("email")}</Form.Label>
              <Form.Control
                style={{
                  border: login_validationErrors.email ? "1px solid red" : "",
                  color: login_validationErrors.email ? "red" : "",
                }}
                placeholder={t("enteryouremail")}
                className="signin-formcontrol"
                type="text"
                value={email}
                onChange={handleEmailChange}
                autoComplete="username"
              />
              {login_validationErrors.email && (
                <div className="login_error">
                  {login_validationErrors.email}
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label className="signin-label">{t("password")}</Form.Label>
              <div className="password-input">
                <Form.Control
                  style={{
                    border: login_validationErrors.password
                      ? "1px solid red"
                      : "",
                    color: login_validationErrors.password ? "red" : "",
                  }}
                  placeholder={t("enteryourpassword")}
                  className="signin-formcontrol"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="current-password"
                />
                <div
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {login_validationErrors.password && (
                <div className="login_error">
                  {login_validationErrors.password}
                </div>
              )}
            </Form.Group>
            <div className="d-flex align-items-center">
              <Button
                className="signin-button px-5"
                variant="primary"
                type="submit"
              >
                {t("lognin")}
              </Button>
            </div>
            <h6 className="mt-3 login_alreadyaccount">
              {t("donthaveanaccount")}
              <span onClick={() => navigate("/signup")}> {t("signup")}</span>
            </h6>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginModal;
