import React, { useState } from "react";
import "../AuthStyles/Login.css";
import Typewriter from "typewriter-effect";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LoginApi } from "../Apis/AuthApis";
import { useDispatch } from "react-redux";
import { fetchUser } from "../redux/actions/userActions";
import { fetchCart } from "../redux/actions/productActions";
import { fetchWishlist } from "../redux/actions/wishlistActions";
import { fetchHomeProducts } from "../redux/actions/HomeProductsAction";
import { fetchAllAddress } from "../redux/actions/AddressAction";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login_validationErrors, setlogin_ValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (login_validationErrors.email) {
      setlogin_ValidationErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (login_validationErrors.password) {
      setlogin_ValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  };

  const login_validateform = () => {
    const login_errors = {};
    if (!email) {
      login_errors.email = t("emailcannotbeempty");
    }
    if (!password) {
      login_errors.password = t("passwordcannotbeempty");
    }
    setlogin_ValidationErrors(login_errors);
    return Object.keys(login_errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (login_validateform()) {
      const userData = {
        email: email,
        password: password,
      };
      LoginApi(userData, (response) => {
        console.log(response);
        if (response.data.message === "Your email verification pending!") {
          Swal.fire({
            icon: "error",
            title: "Yaliwe",
            text: response.data.message,
          });
          return;
        } else if (response.data.message === "Invalid credentials.") {
          Swal.fire({
            icon: "error",
            title: "Yaliwe",
            text: response.data.message,
          });
          return;
        }

        localStorage.setItem("Yaliwe_Token", response?.data?.data?.token);
        dispatch(fetchUser());
        dispatch(fetchCart());
        dispatch(fetchWishlist());
        dispatch(fetchHomeProducts());
        dispatch(fetchAllAddress());
        navigate("/");
      });
    } else {
      console.log("Form is invalid!");
    }
  };

  return (
    <>
      <div className="login_form">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 login_left">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(t("welcometoyaliwe"))
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(t("logintocontinue"))
                    .start();
                }}
              />
            </div>
            <div className="col-lg-6 login_right px-5">
              <div className="d-flex align-items-center mb-3">
                <img
                  src="yaliwe_navbar_icon.png"
                  alt=""
                  className="login_logo"
                  onClick={() => navigate("/")}
                />
                <h3 className="text-start mb-3">{t("login")}</h3>
              </div>

              <form onSubmit={handleSubmit}>
                <input
                  className=""
                  type="text"
                  placeholder={t("enteryouremail")}
                  value={email}
                  onChange={handleEmailChange}
                  style={{
                    border: login_validationErrors.email ? "1px solid red" : "",
                    color: login_validationErrors.email ? "red" : "",
                  }}
                  autoComplete="email"
                />

                {login_validationErrors.email && (
                  <div className="login_error text-start">
                    {login_validationErrors.email}
                  </div>
                )}

                <div className="login_password">
                  <input
                    className="mt-3"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("enteryourpassword")}
                    value={password}
                    onChange={handlePasswordChange}
                    style={{
                      border: login_validationErrors.password
                        ? "1px solid red"
                        : "",
                      color: login_validationErrors.password ? "red" : "",
                    }}
                    autoComplete="password"
                  />
                  <div
                    className="password_toggle"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>

                {login_validationErrors.password && (
                  <div className="login_error text-start">
                    {login_validationErrors.password}
                  </div>
                )}

                <button className="w-100 mt-3 login_button">{t("login")}</button>
              </form>
              <div className="d-flex justify-content-between">
                <h6 className="mt-3 login_alreadyaccount">
                {t("donthaveanaccount")}
                  <span onClick={() => navigate("/signup")}> {t("signup")}</span>
                </h6>
                <h6
                  className="mt-3 login_forgotaccount"
                  onClick={() => navigate("/forgotpassword")}
                >
                   {t("forgotpassword")}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
