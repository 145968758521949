import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function ImageModal({ setshowImageModal, showimagemodal , selectedImage }) {
  const { t } = useTranslation();
  return (
    <>
       <Modal show={showimagemodal} onHide={() => setshowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('imagepreview')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img src={selectedImage} alt="review" style={{ width: "100%" }} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageModal;
