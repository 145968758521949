import React from "react";
import "./DeleteAccountModal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { deleteAccountApi } from "../Apis/MainApis";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DeleteAccountModal({ showDeleteModal, setDeleteModal }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleDeleteAccount = () => {
    const response = deleteAccountApi();
    if (response?.data?.message === "Account deleted") {
      Swal.fire({
        icon: "success",
        title: "Yaliwe",
        text: response?.data?.message,
      });
      navigate("/login");
      localStorage.clear();
    }
  };
  return (
    <>
      <Modal show={showDeleteModal} onHide={setDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="deleteaccount_title">
          {t('deleteaccount')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "red" }}>
        {t('doyou')}
         
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="deleteaccount_button"
            onClick={handleDeleteAccount}
          >
            {t('confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteAccountModal;
