import React, { useState, useEffect } from "react";
import "../Styles/Footer.css";
import { MdOutlineLocationOn } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";
import { TfiEmail } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { parentCategoryApi } from "../Apis/MainApis";
import Swal from "sweetalert2";
import LoginModal from "../Modal/LoginModal";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [parentcategory, setParentCategory] = useState([]);
  const [showSignin, setShowSignin] = useState(false);
  const userid = useSelector((state) => state.user.user.id);

  const collectionData = useSelector(
    (state) => state?.homeproducts?.homeproducts
  );

  const handleFeatureCategoriesNavigate = (id, slug) => {
    navigate(`/category/${id}/${slug}`);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {
    async function fetchparentcategory() {
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        language: lang || "en",
      };
      const response = await parentCategoryApi(data);
      setParentCategory(response?.data?.data?.categories?.slice(0, 6));
    }
    fetchparentcategory();
  }, []);

  const handleCartNavigate = () => {
    if (userid === undefined) {
      setShowSignin(true);
      return;
    }
    navigate("/cart");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };
  const handleVendorLogin = () => {
    Swal.fire({
      title: t("vendorlogin"),
      imageUrl: "/Coming soon.jpg",
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
    });
  };
  const handleBecomeVendorLogin = () => {
    Swal.fire({
      title: t("becomeavendor"),
      imageUrl: "/Coming soon.jpg",
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
    });
  };

  const handlePartnerWithUs = () => {
    Swal.fire({
      title: t("partnerwithus"),
      imageUrl: "/Coming soon.jpg",
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
    });
  };

  const closeModals = () => {
    setShowSignin(false);
  };

  return (
    <>
      <LoginModal show={showSignin} onClose={closeModals} />
      <div className="footer_background py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="mb-3 text-start">
                <img
                  className="footer_iconimage"
                  src="/yaliwe_footer_icon.png"
                  alt=""
                />
              </div>
              <div className="text-start d-flex align-items-center footer_address mb-2">
                <span className="footer_icon">
                  <MdOutlineLocationOn />
                </span>
                <span>
                  {t("address")}: {t("UAE")}
                </span>
              </div>
              <div className="text-start d-flex align-items-center footer_call mb-2">
                <span className="footer_icon">
                  <TfiEmail />
                </span>
                <a href="mailto:support@yaliwe.com">
                  {t("email")}: support@yaliwe.com
                </a>
              </div>
              <div className="text-start d-flex align-items-center footer_call">
                <span className="footer_icon">
                  <TbPhoneCall />
                </span>
                <span>{t("whatsapp")}: +971 54 265 6576</span>
              </div>
            </div>

            <div className="col-12 col-lg-2">
              <h4 className="mb-2 text-start footer-headings">
                {t("company")}
              </h4>
              <div className="text-start">
                <ul className="m-0 p-0">
                  <li
                    className="mb-2"
                    onClick={() => {
                      navigate("/faq");
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 100);
                    }}
                  >
                    {t("faq")}
                  </li>
                  <li
                    className="mb-2"
                    onClick={() => {
                      navigate("/termsandconditions");
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 100);
                    }}
                  >
                    {t("termsandconditions")}
                  </li>
                  <li
                    className="mb-2"
                    onClick={() => {
                      navigate("/legalnotice");
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 100);
                    }}
                  >
                    {t("legalnotice")}
                  </li>
                  <li onClick={handlePartnerWithUs}>{t("partnerwithus")}</li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-lg-2">
              <h4 className="mb-2 text-start footer-headings">{t("pages")}</h4>
              <div className="text-start">
                <ul className="m-0 p-0">
                  <li className="mb-2" onClick={handleCartNavigate}>
                    {t("viewcart")}
                  </li>
                  <li className="mb-2" onClick={handleBecomeVendorLogin}>
                    {t("becomeavendor")}
                  </li>
                  <li className="mb-2" onClick={handleVendorLogin}>
                    {t("vendorlogin")}
                  </li>
                  <li
                    onClick={() => {
                      navigate("/contactus");
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 100);
                    }}
                  >
                    {t("contactus")}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-lg-2">
              <h4 className="mb-2 text-start footer-headings">
                {t("categories")}
              </h4>
              <div className="text-start">
                <ul className="m-0 p-0">
                  {parentcategory?.map((product, i) => (
                    <li
                      className="mb-2"
                      key={i}
                      onClick={() =>
                        handleFeatureCategoriesNavigate(
                          product?.id,
                          product?.slug
                        )
                      }
                    >
                      {product.name.charAt(0).toUpperCase() +
                        product.name.slice(1).toLowerCase()}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <h4 className="mb-2 text-start footer-headings">
                {t("installapp")}
              </h4>
              <div className="text-start mb-2">{t("fromapp")}</div>
              <div className="d-flex align-items-center mb-2">
                <div className="footer_googleplay">
                  <img src="/googleplay.png" alt="" />
                </div>
                <div>
                  <img src="/appstore.png" className="footer_appstore" alt="" />
                </div>
              </div>
              <div className="text-start mb-2">{t("securedpayment")}</div>
              <div className="d-flex align-items-center">
                <div className="footer_gpay">
                  <img src="/gpay.png" alt="" />
                </div>
                <div className="footer_mastercard">
                  <img src="/mastercard.png" alt="" />
                </div>
                <div className="footer_visa">
                  <img src="/visa.png" alt="" />
                </div>
                <div className="footer_amex">
                  <img src="/amex.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
