import React, { useState } from "react";
import "../AuthStyles/ForgotPassword.css";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import "../AuthStyles/ForgotPassword.css";
import Otp from "./Otp";
import { forgotpasswordApi } from "../Apis/AuthApis";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openOtpModal, setOtpModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [email, setEmail] = useState("");

  const validateForgotPassword = () => {
    const errors = {};

    if (!email) {
      errors.email = t("emailcannotbeempty");
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForgotPassword()) {
      const data = {
        username: email,
      };
      const response = await forgotpasswordApi(data);
      console.log(response?.data?.message);
      if (response?.data?.message === "Please check your mail send otp below") {
        toast(response?.data?.message);
        setEmail("");
        setOtpModal(true);
      }
    }
  };

  return (
    <>
      <Otp openOtpModal={openOtpModal} setOtpModal={setOtpModal} />
      <div className="forgot_password">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 forgotpassword_left">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(t('enteryourvalidemailaddress'))
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString("")
                    .start();
                }}
              />
            </div>
            <div className="col-lg-6 forgotpassword_right px-5">
              <div className="d-flex align-items-center mb-3">
                <img
                  src="yaliwe_navbar_icon.png"
                  alt=""
                  className="forgotpassword_logo"
                  onClick={() => navigate("/")}
                />
                <h3 className="text-start mb-3">{t('newpassword')}</h3>
              </div>

              <form onSubmit={handleSubmit}>
                <input
                  className=""
                  type="email"
                  placeholder={t('enteryouremail')}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setValidationErrors({});
                  }}
                />
                {validationErrors.email && (
                  <div className="forgotpassword_error text-start">
                    {validationErrors.email}
                  </div>
                )}
                <button className="w-100 mt-3 forgotpassword_button">
                {t('generateotp')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
