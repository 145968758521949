import React, { useState } from "react";
import "../AuthStyles/Signup.css";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { SignUpApi } from "../Apis/AuthApis";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function Signup() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signup_validationErrors, setValidationErrors] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    if (signup_validationErrors.username) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, username: "" }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (signup_validationErrors.email) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (signup_validationErrors.password) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const handleConfirmPassswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (signup_validationErrors.confirmPassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {};
    const usernameRegex = /^[a-zA-Z\-]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!usernameRegex.test(username)) {
      errors.username = t(
        "usernameshouldnotcontainanyspecialcharacterdigitspace"
      );
    }

    if (!emailRegex.test(email)) {
      if (!email.includes("@")) {
        errors.email = t("emailisnotvalid");
      } else if (!email.includes(".")) {
        errors.email = t("emailisnotvalid");
      } else if (email.indexOf("@") === 0) {
        errors.email = t("emailisnotvalid");
      } else if (email.indexOf("@") === email.length - 1) {
        errors.email = t("emailisnotvalid");
      } else if (email.indexOf(".") === email.length - 1) {
        errors.email = t("emailisnotvalid");
      } else if (email.split("@").length !== 2) {
        errors.email = t("emailisnotvalid");
      } else {
        errors.email = t("emailisnotvalid");
      }
    }

    if (!passwordRegex.test(password)) {
      errors.password = t(
        "passwordmustbeatleast8charactersandincludeatleastonelowercaseletteroneuppercaseletteronedigitandonespecialcharacter"
      );
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = t("passworddonotmatch");
    }
    if (!username) {
      errors.username = t("usernamecannotbeempty");
    }

    if (username.length < 4) {
      errors.username = t("usernameshouldbegreaterthanfour");
    }

    if (!email) {
      errors.email = t("emailcannotbeempty");
    }

    if (!password) {
      errors.password = t("passwordcannotbeempty");
    }

    if (!confirmPassword) {
      errors.confirmPassword = t("confirmpasswordcannotbeempty");
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const userData = {
        name: username,
        email: email,
        password: password,
      };
      const response = await SignUpApi(userData);
      console.log("Sign Up Component");
      console.log(response.data.message);
      if (
        response.data.message ===
        "Registration successful. Share verification link your mail please verify your email."
      ) {
        Swal.fire({
          icon: "success",
          title: "Yaliwe",
          text: response.data.message,
        });
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
      } else if (response.data.message === "Email already exists") {
        Swal.fire({
          icon: "error",
          title: "Yaliwe",
          text: response.data.message,
        });
      }
    } else {
      console.log("Form is invalid");
    }
  };

  return (
    <>
      <div className="signup_form">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 signup_left">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(t("welcometoyaliwe"))
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(t("signuptoregister"))
                    .start();
                }}
              />
            </div>
            <div className="col-lg-6 signup_right px-5">
              <div className="d-flex align-items-center mb-3">
                <img
                  src="yaliwe_navbar_icon.png"
                  alt=""
                  className="signup_logo"
                  onClick={() => navigate("/")}
                />
                <h3 className="text-start mb-3">{t("signup")}</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <input
                  className=""
                  type="text"
                  value={username}
                  placeholder={t("username")}
                  onChange={handleUsernameChange}
                  autoComplete="username"
                  style={{
                    border: signup_validationErrors.username
                      ? "1px solid red"
                      : "",
                    color: signup_validationErrors.username ? "red" : "",
                  }}
                />
                {signup_validationErrors.username && (
                  <div className="signup_error text-start">
                    {signup_validationErrors.username}
                  </div>
                )}
                <input
                  className="mt-3"
                  type="text"
                  value={email}
                  placeholder={t("email")}
                  onChange={handleEmailChange}
                  autoComplete="email"
                  style={{
                    border: signup_validationErrors.email
                      ? "1px solid red"
                      : "",
                    color: signup_validationErrors.email ? "red" : "",
                  }}
                />
                {signup_validationErrors.email && (
                  <div className="signup_error text-start">
                    {signup_validationErrors.email}
                  </div>
                )}
                <div className="signup_password">
                  <input
                    className="mt-3"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("password")}
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="password"
                    style={{
                      border: signup_validationErrors.password
                        ? "1px solid red"
                        : "",
                      color: signup_validationErrors.password ? "red" : "",
                    }}
                  />
                  <div
                    className="signuppassword_toggle"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>
                {signup_validationErrors.password && (
                  <div className="signup_error text-start">
                    {signup_validationErrors.password}
                  </div>
                )}
                <div className="signup_confirmpassword">
                  <input
                    className="mt-3"
                    type={showconfirmPassword ? "text" : "password"}
                    placeholder={t("confirmpassword")}
                    value={confirmPassword}
                    onChange={handleConfirmPassswordChange}
                    autoComplete="confirmpassword"
                    style={{
                      border: signup_validationErrors.confirmPassword
                        ? "1px solid red"
                        : "",
                      color: signup_validationErrors.confirmPassword
                        ? "red"
                        : "",
                    }}
                  />
                  <div
                    className="confirmpassword_toggle"
                    onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                  >
                    {showconfirmPassword ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>
                {signup_validationErrors.confirmPassword && (
                  <div className="signup_error text-start">
                    {signup_validationErrors.confirmPassword}
                  </div>
                )}

                <button className="w-100 mt-3 signup_button" type="submit">
                  {t("signup")}
                </button>
              </form>
              <div className="d-flex justify-content-center">
                <h6 className="mt-3 signup_alreadyaccount">
                  {t("alreadyhaveanaccount")}
                  <span onClick={() => navigate("/login")}> {t("login")}</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
