import React, { useState, useEffect } from "react";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import { useLocation } from "react-router-dom";
import "../Styles/Tracking.css";
import { singleOrderFetchApi } from "../Apis/MainApis";
import { orderCancelApi } from "../Apis/MainApis";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import TrackingModal from "../Modal/TrackingModal";
import { useTranslation } from "react-i18next";

function Tracking() {
  const { t } = useTranslation();
  const location = useLocation();
  const orderId = location.state ? location.state?.orderId : null;
  console.log(orderId);

  const [orderItems, setOrderItems] = useState([]);
  console.log(orderItems);
  const [orderAddress, setorderAddress] = useState([]);
  const [orderedCancel, setOrderCancel] = useState(false);
  const [showTrackingModal, setShowTrackingModal] = useState(false);

  const handleCancelOrder = async (product_id) => {
    const data = {
      orderId: orderItems?.order_id,
      orderItemId: [product_id],
    };
    const response = await orderCancelApi(data);
    if (response?.data?.message === "Order items cancelled successfully.") {
      setOrderCancel(true);
      toast(response?.data?.message);
    }
    console.log(response);
  };

  useEffect(() => {
    async function fetchOrderData() {
      const data = {
        orderId: orderId,
      };
      const response = await singleOrderFetchApi(data);
      setOrderItems(response?.data?.product_detail[0]);
      setorderAddress(response?.data?.product_detail[0]?.address);
    }

    fetchOrderData();
  }, [orderId, orderedCancel]);

  const trackOrder = () => {
    setShowTrackingModal(true);
  };
  return (
    <>
      <TrackingModal
        showTrackingModal={showTrackingModal}
        setShowTrackingModal={setShowTrackingModal}
      />
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-12">
            <table className="table tracking_DeliveryTable">
              <thead>
                <tr>
                  <th>{t('ordersummary')}</th>
                </tr>
              </thead>
              <tbody>
                {orderItems?.order_items?.map((order, i) => (
                  <tr key={i}>
                    <td className="d-flex flex-column tracking_allordersummarydata">
                      <div className="row">
                        <div className="col-lg-4 col-md-5 d-flex justify-content-center">
                          <img
                            src={order.product.featuredImage}
                            className="trackingproductimage"
                            alt=""
                          />
                        </div>
                        <div className="tracking_ordersummaryinfo d-flex flex-column justify-content-center col-lg-8 col-md-7 mt-3 mt-md-0">
                          <div className="text-start">
                            <p>
                              <span className="fw-bold">{t('product')}: </span>
                              {order.product.productName}
                            </p>
                            <p>
                              <span className="fw-bold">{t('quantity')}: </span>
                              {order.quantity}
                            </p>
                            <p>
                              <span className="fw-bold">{t('price')}: </span>$
                              {order.sale_price}
                            </p>
                            <p>
                              <span className="fw-bold">{t('status')}: </span>
                              {order.status === 1
                                ? "Online Payment"
                                : order.status === 2
                                ? "COD"
                                : "Order is cancelled"}
                            </p>
                            <p>
                              <span className="fw-bold">{t('deliveredto')}: </span>
                              {orderAddress.name}, {orderAddress.phone_code}{" "}
                              {orderAddress.mobile}, {orderAddress.street},{" "}
                              {orderAddress.landmark}, {orderAddress.city}{" "}
                              {orderAddress.state} {orderAddress.code}
                            </p>
                            <div className="d-flex align-items-center">
                              {order.status === 3 ? (
                                <button
                                  className="tracking_cancelsuccessbutton"
                                  disabled
                                >
                                 {t('ordercancelled')}
                                </button>
                              ) : (
                                <>
                                  <button
                                    className="tracking_cancelbutton"
                                    onClick={() =>
                                      handleCancelOrder(order?.product_id)
                                    }
                                  >
                                    {t('cancelorder')}
                                  </button>
                                  <button
                                    className="tracking_trackbutton ms-2"
                                    onClick={trackOrder}
                                  >
                                    {t('trackorder')}
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tracking;
