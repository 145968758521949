import React from "react";
import "../Styles/Blog.css";
import { useTranslation } from "react-i18next";

function Blog() {
  const { t } = useTranslation();
  return (
    <div className="mb-5 blog_container">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 col-lg-12">
            <div className="fw-bold blog_heading">
              {t("ourlatest")} <span className="blog_highlight"> {t("blog")} </span>
            </div>
            {/* <p className="blog_para mb-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. has been make a type specimen book.
            </p> */}
          </div>
        </div>
        <div className="row gy-3">
          <div className="col-12 col-lg-4">
            <div className="card blog_card">
              <img className="card-img-top" src="blog1.png" alt=""></img>
              <div className="card-body">
                {/* <h6 className="blog_date text-start">May / 07 / 2023</h6> */}
                <h4 className="blog_cardtitle text-start">
                  {t("blog1heading")}
                </h4>
                <p className="blog_cardtext text-start">{t("blog1text")}</p>
                {/* <div className="text-start">
                  <button className="blog_cardbutton text-start">
                    Read More
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="card blog_card">
              <img className="card-img-top" src="blog2.png" alt=""></img>
              <div className="card-body">
                {/* <h6 className="blog_date text-start">May / 07 / 2023</h6> */}
                <h4 className="blog_cardtitle text-start">
                  {t("blog2heading")}
                </h4>
                <p className="blog_cardtext text-start">{t("blog2text")}</p>
                {/* <div className="text-start">
                  <button className="blog_cardbutton text-start">
                    Read More
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="card blog_card">
              <img className="card-img-top" src="blog3.png" alt=""></img>
              <div className="card-body">
                {/* <h6 className="blog_date text-start">May / 07 / 2023</h6> */}
                <h4 className="blog_cardtitle text-start">
                  {t("blog3heading")}
                </h4>
                <p className="blog_cardtext text-start">{t("blog3text")}</p>
                {/* <div className="text-start">
                  <button className="blog_cardbutton text-start">
                    Read More
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
