import React from "react";
import "../Sidebar/Sidebar3.css";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineContactMail } from "react-icons/md";
import { TbGavel } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { profilePicApi } from "../Apis/MainApis";
import { fetchUser } from "../redux/actions/userActions";
import { useTranslation } from "react-i18next";

function Sidebar3({
  isOpen,
  closeSidebar3,
  handleProfilenavigate,
  handleOrdernavigate,
  handleLogout,
  handleImageUpload,
  fileInputRef,
  handleUploadButtonClick,
  handleAdressesNavigate,
  setShowResetPassword,
  setDeleteModal,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);

  return (
    <div className={`sidebar2 ${isOpen ? "open" : ""}`}>
      <div className="text-end me-2 mb-3" onClick={() => closeSidebar3()}>
        <IoClose />
      </div>
      <div className="sidebar3_myaccountleftbar">
        <Avatar
          alt={user?.name_en}
          src={user?.image}
          className="mb-2"
          sx={{ width: 74, height: 74 }}
        />
        <h6 className="text-start fw-bold">{user?.name_en}</h6>
        <h6 className="text-start fw-bold">{user?.email}</h6>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <button
          onClick={handleUploadButtonClick}
          className="uploadImageButton mb-3"
        >
          {t("uploadimage")}
        </button>

        <div className="sidebar3_myaccountleftbarbox2 d-flex">
          <div>
            <IoPersonOutline style={{ color: "#4cab37", fontSize: "larger" }} />
          </div>
          <div className="ms-3">
            <ul className="sidebar3_account_setting text-start m-0 p-0">
              <li
                style={{ color: "black", fontWeight: "bold" }}
                className="mb-1"
              >
                {t("myaccount")}
              </li>
              <li onClick={() => handleProfilenavigate("profile")}>
                {t("myprofile")}
              </li>
              <li
                onClick={() => {
                  setShowResetPassword(true);
                  closeSidebar3();
                }}
              >
                {t("changepassword")}
              </li>
              <li
                onClick={() => {
                  setDeleteModal(true);
                  closeSidebar3();
                }}
              >
                {t("deleteaccount")}
              </li>
              {/* <li>Notification</li> */}
            </ul>
          </div>
        </div>

        <div className="sidebar3_myaccountleftbarbox3 d-flex">
          <div>
            <MdOutlineContactMail
              style={{ color: "#4cab37", fontSize: "larger" }}
            />
          </div>
          <div className="ms-3">
            <ul className="sidebar3_account_setting text-start m-0 p-0">
              <li
                style={{ color: "black", fontWeight: "bold" }}
                className="mb-1"
              >
                {t("mystuff")}
              </li>

              <li onClick={() => handleOrdernavigate("orders")}>
                {t("myorders")}
              </li>
              <li onClick={() => handleAdressesNavigate("addresses")}>
                My Addresses
              </li>
              <li onClick={() => navigate("/wishlist")}> {t("mywishlist")}</li>
              <li onClick={() => navigate("/cart")}>{t("mycart")}</li>
              {/* <li onClick={() => navigate("/cart")}>My Coupons</li> */}
            </ul>
          </div>
        </div>

        <div className="sidebar3_myaccountleftbarbox4 d-flex">
          <div>
            <TbGavel style={{ color: "#4cab37", fontSize: "larger" }} />
          </div>
          <div className="ms-3">
            <ul className="sidebar3_account_setting text-start m-0 p-0">
              <li
                style={{ color: "black", fontWeight: "bold" }}
                className="mb-1"
              >
                {t("legal")}
              </li>

              <li onClick={() => navigate("/faq")}>{t("faq")}</li>
              <li onClick={() => navigate("/termsandconditions")}>
                {t("termsandcondition")}
              </li>
              <li onClick={() => navigate("/legalnotice")}>
                {t("legalnotice")}
              </li>
            </ul>
          </div>
        </div>

        <div className="sidebar3_myaccountleftbarbox5 d-flex">
          <div>
            <MdLogout style={{ color: "#4cab37", fontSize: "larger" }} />
          </div>
          <div className="ms-3">
            <ul className="sidebar3_account_setting text-start m-0 p-0">
              <li
                style={{ color: "black", fontWeight: "bold" }}
                onClick={handleLogout}
              >
                {t("logout")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar3;
