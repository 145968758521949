import React, { useState, useEffect } from "react";
import "../Styles/MyOrder.css";
import { orderFetchApi } from "../Apis/MainApis";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";


function MyOrders() {
  const { t } = useTranslation();
  const [totalPageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [orders, setOrders] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const orderId = location.state ? location.state.orderId : null;

  useEffect(() => {
    async function fetchorder() {
      const data = {
        page: currentPage + 1,
      };
      const response = await orderFetchApi(data);
      setPageCount(response?.data?.data?.length / 10);
      setOrders(response?.data?.product_detail);
    }
    fetchorder();
  }, [currentPage]);

  const handleTracking = (orderId) => {
    navigate(`/account/myorders/tracking`, { state: { orderId } });
  };


  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <div className="myorder_tableresponsive">
        <div className="table-responsive mt-3 mt-lg-0 ">
          <table className="table myorderTable">
            <thead>
              <tr>
                <th>{t("orderid")}</th>
                <th>{t("transactionid")}</th>
                <th>{t("paymenttype")}</th>
                <th>{t("totalamount")}</th>
                <th>{t("actions")}</th>
              </tr>
            </thead>
            <tbody>
              {orders?.length === 0 || orders === undefined ? (
                <>
                  <tr>
                    <td colSpan="6">
                      <img
                        src="/order.png"
                        alt=""
                        style={{ width: "200px" }}
                      ></img>
                      <p>{t("youhavenotorderanythingyet")}</p>
                    </td>
                  </tr>
                </>
              ) : (
                orders?.map((order, i) => (
                  <tr key={i}>
                    <td>{order?.order_id}</td>
                    <td>{order?.transaction_id}</td>
                    <td>{order?.payment_type}</td>
                    <td>{order?.pay_amount}</td>
                    <td>
                      <div
                        className="d-flex align-items-center justify-content-around myorder_addbutton"
                        onClick={() => handleTracking(order?.id)}
                      >
                        {t("view")}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {totalPageCount > 1 && (
          <ReactPaginate
            pageCount={totalPageCount}
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active-page"}
          />
        )}
      </div>
    </>
  );
}

export default MyOrders;
