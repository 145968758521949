import React, { useState, useEffect } from "react";
import "../Styles/FeaturedCategories.css";
import { useNavigate } from "react-router-dom";
import { parentCategoryApi } from "../Apis/MainApis";
import { useTranslation } from "react-i18next";

function FeaturedCategories() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [parentcategory, setParentCategory] = useState([]);

  const handleFeatureCategoriesNavigate = (pid, slug) => {
    navigate(`/category/${pid}/${slug}`);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {
    async function fetchparentcategory() {
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        language: lang || "en",
      };
      const response = await parentCategoryApi(data);
      setParentCategory(response?.data?.data?.categories);
    }
    fetchparentcategory();
  }, []);

  return (
    <>
      <div className="featured_categories py-5" id="featuredCategoryId">
        <div className="container">
          <div className="row gy-3">
            <div className="featuredcategories_heading text-start mb-3">
              {t("featuredcategories")}
            </div>

            {parentcategory?.map((product, i) => (
              <div
                className="col-12 col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-sm-6"
                key={i}
                onClick={() =>
                  handleFeatureCategoriesNavigate(product?.id, product?.slug)
                }
              >
                <div className="featuredcategories_imagebackground1 d-flex flex-column align-items-center justify-content-center">
                  <div className="featuredcategories_imagebackground2">
                    <img
                      className="featuredcategories_image "
                      src={product?.image}
                      alt=""
                    ></img>
                  </div>
                  <div className="mt-2 featuredcategories_description">
                    {product?.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturedCategories;
