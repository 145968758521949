// eslint-disable-next-line import/no-anonymous-default-export
export default {
  whatareyoulookingfor: "qu'est-ce que tu cherches",
  emailcannotbeempty: "l'e-mail ne peut pas être vide",
  enteryourvalidemailaddress: "entrez votre adresse email valide",
  newpassword: "nouveau mot de passe",
  enteryouremail: "entrer votre Email",
  generateotp: "générer otp",
  passwordcannotbeempty: "le mot de passe ne peut pas être vide",
  welcometoyaliwe: "bienvenue pour être mangé",
  logintocontinue: "connectez-vous pour continuer....",
  login: "se connecter",
  enteryourpassword: "tapez votre mot de passe",
  signup: "s'inscrire",
  donthaveanaccount: "je n'ai pas de compte?",
  forgotpassword: "Mot de passe oublié ?",
  passwordmustbeatleast8charactersandincludeatleastonelowercaseletteroneuppercaseletteronedigitandonespecialcharacter:
    "le mot de passe doit comporter au moins 8 caractères et inclure au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial",
  newpasswordandconfirmpasswordnotmatch:
    "le nouveau mot de passe et la confirmation du mot de passe ne correspondent pas",
  newpasswordcannotbeempty: "le nouveau mot de passe ne peut pas être vide",
  confirmpasswordcannotbeempty:
    "confirmer que le mot de passe ne peut pas être vide",
  enteryournewpassword: "Entrez votre nouveau mot de passe",
  confirmpassword: "Confirmez le mot de passe",
  confirmnewpasswordpassword: "confirmer le nouveau mot de passe",
  submit: "soumettre",
  enterotp: "entrez OTP",
  verifyotp: "vérifier OTP",
  usernameshouldnotcontainanyspecialcharacterdigitspace:
    "le nom d'utilisateur ne doit contenir aucun caractère spécial, chiffres ni espace",
  emailisnotvalid: "L'email n'est pas valide",
  passworddonotmatch: "le mot de passe ne correspond pas",
  usernamecannotbeempty: "le nom d'utilisateur ne peut pas être vide",
  usernameshouldbegreaterthanfour:
    "la longueur du nom d'utilisateur doit être supérieure à quatre",
  signuptoregister: "inscrivez-vous pour vous inscrire",
  username: "nom d'utilisateur",
  email: "E-mail",
  password: "mot de passe",
  alreadyhaveanaccount: "Vous avez déjà un compte?",
  uploadimage: "Télécharger une image",
  myaccount: "MON COMPTE",
  myprofile: "Mon profil",
  changepassword: "Changer le mot de passe",
  deleteaccount: "Supprimer le compte",
  mystuff: "MES AFFAIRES",
  myorders: "Mes commandes",
  myaddresses: "Mes adresses",
  mywishlist: "Ma liste d'envies",
  mycart: "Mon panier",
  legal: "LÉGALE",
  faq: "Faq",
  termsandcondition: "Termes et conditions",
  legalnotice: "Mention légale",
  logout: "SE DÉCONNECTER",
  banner1:
    "Explorez notre large gamme d'équipements de construction conçus pour répondre aux normes les plus élevées de durabilité et de performance. Que vous soyez un entrepreneur professionnel ou un bricoleur, trouvez les outils dont vous avez besoin pour effectuer le travail efficacement.",
  banner2:
    " Découvrez nos dernières collections électroniques, dotées d'une technologie de pointe et de designs innovants. Des gadgets intelligents aux appareils électroménagers, découvrez le meilleur des avancées électroniques.",
  banner3:
    "Transformez votre look avec nos collections de perruques haut de gamme. Choisissez parmi une variété de styles et de couleurs pour exprimer votre personnalité unique. Découvrez la confiance qui accompagne une transformation capillaire parfaite.",
  bestselling: "Meilleure vente",
  products: "Des produits",
  bestproducts:
    "Découvrez notre sélection triée sur le volet de produits les mieux notés. Chaque article est soigneusement sélectionné pour vous offrir le meilleur en termes de qualité, de style et d'innovation. ",
  categories: "Catégories",
  home: "MAISON",
  hardtofind: "DIFFICILE À TROUVER",
  yourcartisempty: "Votre panier est vide",
  product: "Produit",
  price: "Prix",
  subtotal: "Total",
  remove: "Retirer",
  coupons: "Coupons",
  apply: "Appliquer",
  checkout: "Vérifier",
  total: "Totale",
  filters: "Filtres",
  clearall: "Tout effacer",
  prices: "Des prix",
  clear: "Claire",
  min: "Min.",
  max: "Max.",
  brands: "Marques",
  reviews: "Commentaires",
  noitemfound: "Aucun élément trouvé",
  checkoutitems: "Articles à la caisse",
  thereisnoitemsforcheckout: "Il n'y a aucun article à commander",
  gotohomepage: "Aller à la page d'accueil",
  discount: "Rabais",
  selectdeliveryaddress: "Sélectionnez l'adresse de livraison",
  addnew: "Ajouter un nouveau",
  pleaseaddnewaddress: "Veuillez ajouter une nouvelle adresse",
  edit: "Modifier",
  selectpaymentoption: "Sélectionnez l'option de paiement",
  paypal: "Pay Pal",
  stripe: "Bande",
  proceedtopay: "Procéder au paiement",
  contactus: "Contactez-nous",
  ourmainoffice: "Notre bureau principal",
  phonenumber: "Numéro de téléphone",
  enteryourname: "Entrez votre nom",
  enteravalidemailaddress: "Entrez une adresse mail valide",
  message: "Message",
  whatisyaliwe: "Qu'est-ce que Yaliwe",
  It_is_the_first_platform_of_its_kind_in_the_region_to_offer:
    "Il s'agit de la première plateforme de ce type dans la région à vous offrir un moyen simple d'acheter des produits des quatre coins du monde, certifiés et garantis à un prix abordable.",
  At_Yaliwe_you_BUY_with_peace_of_mind_Our_main_aim_is_to_keep:
    "Chez Yaliwe, vous ACHETEZ en toute sérénité. Notre objectif principal est de garder nos clients heureux et satisfaits de leurs produits achetés et c'est pourquoi nous fournissons les éléments suivants : Qualité – Tous les produits sont soumis à des contrôles de qualité intensifs par nos experts certifiés. Garantie – La plupart des produits bénéficient d’une garantie de 3 à 12 mois.",
  _How_long_does_the_delivery_take: "Combien de temps prend la livraison",
  After_your_order_once_payment_has_been_made_Your_item_should:
    "Après votre commande, une fois le paiement effectué ? Votre article doit être livré sous 6 à 14 jours ouvrés environ, ce délai peut être modifié en fonction de l'article commandé et de votre ville de résidence.",
  What_should_I_do_if_I_am_not_satisfied_with_the_product:
    "Que dois-je faire si je ne suis pas satisfait du produit reçu",
  youcancontactusat: "Vous pouvez nous contacter au",
  howtoreturnanitem: "Comment retourner un article",
  _Return_cancellation_replacement_and_refund_policy_You_can:
    "Politique de retour, d'annulation, de remplacement et de remboursement. Vous pouvez engager une procédure de retour/annulation pour un produit qui ne figure pas sur la liste des produits non retournables et si vous avez reçu :",
  an_incorrect_product:
    "Un produit incorrect (si le produit n'a pas été utilisé, comprend toutes les étiquettes et est dans son emballage d'origine non endommagé).",
  adamagedproduct:
    "Un produit endommagé/défectueux (pour les appareils électroniques, les produits ouverts seront acceptés s'ils diffèrent de la description ou de l'image présentée et incluent toutes les étiquettes et dans leur emballage d'origine non endommagé).",
  In_the_event_that_you_need_to_return_your_item_for_any_reason:
    "Dans le cas où vous devez retourner votre article pour quelque raison que ce soit, nous n'accepterons un retour que sous certaines conditions :",
  The_products_are_in_their_original_retail_packaging:
    "Les produits sont dans leur emballage de vente au détail d'origine (boîte scellée/fermée) et ne sont ni endommagés ni altérés, quelles que soient leurs conditions. Le sceau de l'article doit être en bon état.",
  _For_watches_the_product_must_be_in_its_original_packaging_and:
    "Pour les montres, le produit doit être dans son emballage d’origine et comporter toutes les étiquettes d’origine.",
  _For_all_other_products_returns_are_accepted_if_the_product_is:
    "Pour tous les autres produits, les retours sont acceptés si le produit est dans son emballage d'origine intact et comprend tous les accessoires.",
  _If_the_packaging_has_minor_soiling_or_is_opened_and_the:
    "Si l'emballage présente des salissures mineures ou est ouvert et que le produit n'est pas défectueux, n'a pas été utilisé, ne présente aucune rayure ou marque visible, des frais de restockage de 30 % (pourcentage) seront déduits lors de l'échange/remboursement.",
  You_have_the_right_to_return_a_product_within_ten:
    "Vous avez le droit de retourner un produit dans les dix (10) jours suivant la réception de l'expédition. C'est le client qui prend en charge les frais de retour du produit.",
  nonreturnableproduct: "Produits non retournables.",
  Products_that_you_have_used_or_damaged_or_which_are_not_in_the:
    "Les produits que vous avez utilisés ou endommagés ou qui ne sont pas dans le même état que vous les avez reçus ;",
  _Any_consumable_product_that_has_been_used_or_installed:
    " Tout produit consommable ayant été utilisé ou installé ;",
  _Products_with_falsified_or_missing_serial_numbers:
    "Produits avec des numéros de série falsifiés ou manquants",
  reimbursementprocedure: "Procédure de remboursement",
  wewillrefund:
    "Nous vous rembourserons en fonction du mode de paiement que vous avez utilisé, comme suit :",
  ifyoupaidby:
    " Si vous avez payé par carte de crédit/débit, les remboursements seront effectués uniquement selon le mode de paiement d'origine.",
  ifyoupaidbycod:
    "Si vous avez payé par COD, nous effectuerons un remboursement sur votre portefeuille/virement bancaire ;",
  formoreinformation:
    " Contactez-nous : Pour plus d'informations, consultez nos conditions de vente, envoyez un e-mail à notre équipe d'assistance à",
  featuredcategories: "catégories vedettes",
  mobilenoshouldbebetween8to12digits:
    "Le numéro de portable doit comporter entre 8 et 12 chiffres",
  datasubmittedsuccessfully: "Données soumises avec succès",
  emailisrequired: "L'e-mail est requis",
  phoneisrequired: "Le téléphone est requis",
  usernameisrequired: "Nom d'utilisateur est nécessaire",
  textareaisrequired: "La zone de texte est obligatoire",
  brandisrequired: "La marque est obligatoire",
  modelnameisrequired: "Le nom du modèle est requis",
  hardtofindproducts: "Produits difficiles à trouver",
  havingtrouble: "J'ai du mal à trouver une certaine pièce",
  whetherthepartisobsolute:
    "Que la pièce soit obsolète, abandonnée ou tout simplement peu courante, avec les informations appropriées de votre part, les membres de notre équipe peuvent retrouver la plupart des articles que personne ne semble fournir.",
  pleasefilltheform:
    "Veuillez remplir le formulaire avec autant d'informations dont vous disposez. Nous nous occuperons du reste.",
  iteminformation: "Informations sur l'élément",
  brand: "Marque",
  model: "Modèle",
  partno: "Numéro de pièce",
  personalinformation: "Informations personnelles",
  name: "Nom",
  phone: "Téléphone",
  comments: "commentaires",
  sendrequest: "Envoyer une demande",
  vendorlogin: "Connexion du fournisseur",
  becomeavendor: "Devenez vendeur",
  partnerwithus: "Devenez partenaire avec nous",
  address: "Adresse",
  UAE: "AL Nahda 2, Dubaï, Émirats Arabes Unis",
  whatsapp: "WhatsApp",
  company: "Entreprise",
  termsandconditions: "Termes et conditions",
  pages: "Pages",
  viewcart: "Voir le panier",
  installapp: "Installer l'application",
  fromapp: "Depuis l'App Store ou Google Play",
  securedpayment: "Passerelle de paiement sécurisée",
  hairperfector: "Perfecteur de cheveux",
  forstylish: "Pour des produits de perruque de cheveux élégants",
  shopcollectionnow: "Achetez la collection maintenant",
  pleaseread:
    "Merci de lire attentivement les différentes conditions d'utilisation de ce site avant de parcourir ses pages. En vous connectant à ce site, vous acceptez, sans réserve, les présentes conditions générales.",
  alsoinaccordance:
    "Aussi, conformément à l'article n°6 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, les mentions à noter sur ce site sont :",
  termsofuse: "Conditions d'utilisation :",
  thissite: "Ce site",
  offeredindifferent:
    "est proposé dans différents langages web (HTML, HTML5, Javascript, CSS, etc.) pour un plus grand confort d'utilisation et un graphisme plus agréable.",
  werecommendthat:
    "Nous vous recommandons d'utiliser des navigateurs modernes tels qu'Internet Explorer, Safari, Firefox, Google Chrome, etc.",
  yaliweimplements:
    "Yaliwe met en œuvre tous les moyens dont elle dispose pour assurer une information fiable et une mise à jour fiable de son site Internet.",
  howevererrors:
    "Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de l'exactitude des informations auprès d'un agent yaliwe ou UBS GROUP dans la rubrique Contact ou sur www.ubslogistic.com, et signaler toute modification du site qu'il jugerait utile. Yaliwe n'est en aucun cas responsable de la mauvaise utilisation de ces informations, et de tout préjudice direct ou indirect qui pourrait en résulter.",
  cookies: "Biscuits :",
  mayask:
    "peut vous demander d'accepter les cookies à des fins de statistiques et d'affichage. Un cookie est une information stockée sur votre disque dur par le serveur du site que vous visitez.",
  itcontains:
    "Il contient plusieurs éléments de données stockés sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations. Certaines parties de ce site ne peuvent pas être fonctionnelles sans accepter les cookies.",
  hypertextlinks: "Liens hypertextes :",
  thissitemayoffer:
    "Le site peut proposer des liens vers d'autres sites internet ou d'autres ressources disponibles sur Internet. Les utilisateurs, les abonnés et les visiteurs du site ne peuvent mettre en place un hyperlien en direction de ce site sans l'autorisation expresse et préalable de Yaliwe.",
  inthevent:
    "Dans l'hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en direction d'un site internet, il lui appartiendra d'adresser un email accessible sur le site afin de formuler votre demande de mise en place d'un hyperlien.",
  yaliwereserves:
    "yaliwe se réserve le droit d'accepter ou de refuser un hyperlien sans avoir à justifier sa décision",
  servicesprovided: "Services fournis:",
  allofthecompany:
    "Toutes les activités de l'entreprise ainsi que ses informations sont présentées sur notre site",
  theyaregiven:
    "Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site sont données à titre indicatif, et sont susceptibles de changer ou d'évoluer dans le temps.",
  contractual: " Limitation contractuelle des données :",
  theinformationcontained:
    "Les informations contenues sur ce site sont les plus précises possibles et le site mis à jour à différentes périodes de l'année, afin d'éviter des inexactitudes ou des omissions.",
  ifyounoticeagap:
    "Si vous constatez une lacune, une erreur ou ce qui semble être un dysfonctionnement, merci de le signaler par email à support@yaliwe.com ,ou à la rubrique contact du site,en décrivant le problème le plus précisément possible (page à l'origine du problème, type d'ordinateur et de navigateur utilisé, etc.).",
  inaddition:
    "Par ailleurs, l'utilisateur du site s'engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour.",
  intellectualproperty: "Propriété intellectuelle :",
  allcontentofthis:
    "Tout le contenu de ce site, incluant, sans limitation, les graphismes, images, textes, vidéos, animations, sons, logos gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l'exception des marques, logos ou contenus appartenant à d'autres sociétés ou auteurs partenaires.",
  anyreproduction:
    "Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord exprès et écrit.",
  disputes: "Des disputes:",
  theseconditionsofthesite: "Ces conditions du site",
  aregovernedby:
    "sont régies par les lois des Emirats Arabes Unis, et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. Les langues de référence pour régler d’éventuels litiges sont le français et l’anglais.",
  personaldata: "Données personnelles :",
  generallyspeaking:
    "D’une manière générale, vous n’êtes pas tenu de nous fournir vos données personnelles lorsque vous visitez notre site Internet.",
  howeverthisprinciple:
    "Ce principe connaît cependant certaines exceptions. En effet, pour certains services proposés par l'intermédiaire de notre site, ou pour passer commande, vous pourrez être amenés à nous fournir certaines données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. . C'est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ».",
  inanycase:
    "Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de demander des informations sur notre société, ou de recevoir des newsletters.",
  finallywe:
    "Enfin, nous pouvons être amenés à collecter automatiquement certaines informations vous concernant lors d'une simple navigation sur notre site internet, notamment : des informations concernant l'utilisation de notre site, telles que les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d'accès.",
  suchinformation:
    "Ces informations sont utilisées exclusivement à des fins statistiques internes, afin d'améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées.",
  youraddresses: "Vos adresses",
  youhavenotaddedanyaddressyet: "Vous n'avez pas encore ajouté d'adresse",
  update: "Mise à jour",
  yourcoupons: "Vos bons de réduction",
  youhavenocoupons: "Vous n'avez pas de coupons",
  orderid: "Numéro de commande",
  transactionid: "Identifiant de transaction",
  paymenttype: "Type de paiement",
  totalamount: "Montant total",
  actions: "Actions",
  youhavenotorderanythingyet: "Vous n'avez encore rien commandé",
  view: "Voir",
  loading: "Chargement",
  account: "Compte",
  cart: "Chariot",
  wishlist: "Liste de souhaits",
  totalprice: "Prix ​​total",
  ratingfeedbackandmessagerequired: "Note, commentaires et message requis",
  reviewdeletedsuccessfully: "Avis supprimé avec succès",
  addtocart: "Ajouter au panier",
  youcannotuploadmorethan3images:
    "Vous ne pouvez pas télécharger plus de 3 images",
  originals: "Produits 100% originaux",
  getitby: "Obtenez-le Acheter",
  payonline: "Payer en ligne",
  easyreturn: "Retour et échange faciles en 4 jours disponibles",
  aboutyaliwe: "À propos de Yaliwe",
  aboutyaliwepara:
    "Yaliwe est la première plateforme de ce type dans la région à vous offrir un moyen simple d'acheter des produits des quatre coins du monde, certifiés et garantis à un prix abordable.",
  productdescription: "Description du produit",
  topreviews: "Meilleurs avis",
  noreviews: "Aucun avis à afficher",
  delete: "Supprimer",
  addyourreview: "Ajoutez votre avis",
  enteryourfeedback: "Entrez vos commentaires",
  namefieldisrequired: "Le champ Nom est obligatoire",
  phonenumbershouldbebetween8to12digits:
    "Le numéro de téléphone doit comporter entre 8 et 12 chiffres",
  profileupdated: "Profil mis à jour",
  editprofile: "Editer le profil",
  mobilenumber: "Numéro de portable",
  emailaddress: "Adresse e-mail",
  savedetails: "Enregistrer les détails",
  addaddress: "Ajoutez l'adresse",
  mobile: "Mobile",
  country: "Pays",
  state: "État",
  city: "Ville",
  street: "Rue",
  landmark: "Point de repère",
  pincode: "Code PIN",
  addresstype: "Type d'adresse",
  office: "Bureau",
  setasmydefaultaddress: "Définir comme mon adresse par défaut",
  saveaddress: "Enregistrer l'adresse",
  aboutus: "À PROPOS DE NOUS",
  foundedin:
    "Fondée en 2023, et filiale d'UBS (Unique Business Services), Yaliwe est une plateforme de vente en ligne. Le meilleur endroit pour acheter et vendre des choses en Afrique, depuis les quatre coins du monde (Dubaï, Chine, Turquie, Afrique, Europe, Amérique). Notre mission est d'accompagner le public dans l'achat et la livraison de produits à usage personnel ou commercial dans ses opérations quotidiennes grâce à une vente et une livraison rapide, via notre site internet et notre application mobile.",
  order: "COMMANDE",
  whetherforthe:
    "Que ce soit pour la livraison de cosmétiques, de produits ménagers, d'électroménagers, d'électronique, de mécanique, d'automobile ou encore industriels et bien d'autres… notre communauté dynamique, composée de fournisseurs et intermédiaires et détaillants honnêtes est toujours prête à vous aider à livrer vos commandes. En tant que service d'intermédiation et de livraison de produits, yaliwe est bien implanté en Afrique. Notre service de livraison personnalisé répond à tous vos besoins en matière d'achat à des prix très compétitifs.",
  delivery: "LIVRAISON",
  toensure:
    "Afin de garantir une livraison rapide de votre commande, un livreur vous sera assigné dès réception. de votre commande. Sous 3 à 7 jours en AFRIQUE et 10 à 15 jours en Europe et Amérique selon votre lieu de résidence, Yaliwe travaille en étroite collaboration avec plusieurs Agences de Livraison.",
  customerservice: "SERVICE CLIENT",
  ourtelephone:
    "Notre service de suivi téléphonique est tout aussi simple ! vous avez accès gratuitement à notre service d’information personnalisé par téléphone. Un membre de notre équipe de courtoisie vous guidera étape par étape pour effectuer un suivi en ligne. Les numéros Whatsapp sont disponibles pour le service pendant et après-vente. 24h/24 et 7j/7",
  navigate: "NAVIGUER",
  yaliweallows:
    "Yaliwe vous permet de faire un choix éclairé parmi des centaines de produits. Lorsque vous ouvrez le site ou l'Application, vous pouvez rechercher un produit et passer votre commande en toute quiétude.",
  tracking: "SUIVI",
  whentheorder:
    "Une fois la commande passée, un membre du service client vous contactera pour confirmer les détails de la commande reçue, puis un livreur sera envoyé pour livrer votre commande.",
  termsofservice: "Conditions d'utilisation",
  keyprinciples: "LES PRINCIPES CLÉS",
  thesegeneral:
    "Les présentes conditions générales (« Conditions ») s'appliquent aux applications mobiles et/ou sites Internet de",
  theweb:
    "Les applications web et mobiles permettent de commander des produits auprès de différents fournisseurs et intermédiaires ou directement auprès de UNIQUE BUSINESS SERVICE puis de les livrer. Pour plus d’informations, veuillez consulter cette page.",
  foryaliweproducts:
    "Pour les produits et le service de livraison Yaliwe, vous êtes lié par un contrat directement avec UNIQUE BUSINESS SERVICE. Yaliwe agit comme une plateforme d'intermédiation technologique pour vous permettre de demander et de recevoir des produits, également comme une centrale d'achat et de livraison et vous en garantit la bonne exécution dans les conditions prévues dans ces informations.",
  forproductsand:
    "Pour les produits et le service de livraison fournis par Yaliwe, il peut vous être demandé d'accepter des conditions distinctes qui régissent votre utilisation de ses produits et du service de livraison.",
  pleasereadtheseterms:
    "Veuillez lire attentivement ces conditions. Ils comprennent des informations sur les produits et le service de livraison et vos droits, ainsi que d'autres informations importantes.",
  definitions: "DÉFINITIONS",
  suppliersandintermediary:
    "Les Fournisseurs et Prestataires de Services Intermédiaires sont des intermédiaires indépendants, notamment les prestataires de transport indépendants, les partenaires de livraison, les fournisseurs de produits, les prestataires logistiques et/ou les prestataires d'autres produits et le service de livraison de mobilité qui utilisent des applications mobiles et/ou les sites internet de Yaliwe.",
  theproductsand:
    "Les produits et le service de livraison fournis par Yaliwe désignent les produits et le service de livraison qui vous sont fournis directement par Yaliwe et qui peuvent être soumis à des conditions distinctes.",
  weourteamdesignates:
    "Nous, Notre équipe désigne les noms d'entreprise, les logos, les noms de produits ou de produits et service de livraison, les marques de produits, les présentations commerciales, d'autres signes de propriété ou droits d'auteur de Yaliwe ou de son concédant de licence.",
  conditions: "Conditions",
  resume: "CV",
  thesegeneralconditions:
    "Les présentes conditions générales régissent votre utilisation des applications mobiles et/ou sites internet de Yaliwe les Conditions. Vous devez lire attentivement et accepter les présentes conditions avant toute création de votre compte client via les applications mobiles et/ou les sites Internet Yaliwe, la création d'un compte client étant un prérequis obligatoire pour pouvoir demander les produits et le service de livraison assuré par Yaliwe ou intermédiaire. produits et service de livraison. Votre acceptation de ces Conditions établit une relation contractuelle entre vous et Yaliwe. Les présentes conditions remplacent expressément tout accord ou arrangement antérieur entre vous et Yaliwe concernant les produits et les applications/sites Web du service de livraison (tels que définis ci-dessous).",
  theseconditions:
    "Ces Conditions s'appliquent dans la mesure où elles n'entrent pas en vigueur en conflit avec les Conditions d'utilisation et toutes conditions supplémentaires qui s'appliquent spécifiquement aux applications mobiles et/ou sites Web Yaliwe. Il vous sera demandé d'accepter ce type de conditions séparément.",
  yaliweacts:
    "Yaliwe agit comme plateforme d'intermédiation technologique pour les produits et le service de livraison intermédiaire et comme prestataire de services pour les produits et le service de livraison fournis. Dès que les produits et la prestation de livraison les intermédiaires de transport sont accessibles via les applications mobiles et/ou le site internet Yaliwe internet s'assure de la bonne exécution desdits produits et de la prestation de transport livraison par les Prestataires de Services Intermédiaires.",
  productsanddelivery:
    "Applications de produits et de services de livraison)/Sites Web)",
  theproductsanddelivery:
    "Les produits et services de livraison qui vous sont fournis par Yaliwe comprennent :",
  accessanduse:
    "Accès et utilisation des applications mobiles et/ou sites Internet de Yaliwe qui vous permettent de demander et de recevoir des produits et services de livraison d'intermédiaire et/ou de produits et service de livraison fournis par Yaliwe.",
  productsandservice:
    "Les produits et services de livraison, de collecte d'informations et de traitement des paiements (y compris l'émission de factures et de reçus pour le compte des prestataires intermédiaires) nous permettant de vous facturer et de verser les sommes versées sur le compte bancaire des fournisseurs, prestataires et intermédiaires pour les produits et service de livraison d'un intermédiaire ou sur le compte bancaire de Yaliwe pour les produits et le service de livraison fournis par, Yaliwe peut avoir ces produits et le service de livraison en son nom par des sociétés affiliées ou un intermédiaire) (collectivement les « Applications de produits et de services de livraison) /Sites Internet)",
  thesetermsgovern:
    "Les présentes Conditions régissent votre utilisation des Produits et du Service. livraison d'applications/sites Web en AFRIQUE.",
  yaliwemobileapplication:
    "Les applications mobiles et/ou sites Web de Yaliwe seront mis à votre disposition sous différentes marques Yaliwe.",
  theproductsanddeliveryapp:
    "Les produits et services de livraison Application(s)/Site(s) Internet sont mis à votre disposition pour votre usage personnel ou pour un usage commercial.",
  youcanrequest:
    "Vous pouvez demander et recevoir des produits et services de livraison intermédiaires ou des produits et services de livraison fournis par Yaliwe via les applications mobiles et/ou le site Internet de Yaliwe.",
  productsandintermediary: "Produits et service de livraison intermédiaire",
  yaliweactas:
    "Yaliwe agit comme une plateforme d'intermédiation technologique entre vous et les fournisseurs intermédiaires pour leur permettre de vous fournir leurs produits. Tous les prestataires de services intermédiaires sont des entrepreneurs intermédiaires indépendants, des sociétés commerciales ou d'autres types d'entreprises et ne sont pas employés par Yaliwe.",
  useofproducts:
    "Utilisation des produits et services de livraison Applications)/Sites) Web",
  theyaliwemobileapplication:
    "Les applications mobiles et/ou le site Internet Yaliwe peuvent être téléchargés ou accessibles sur la plupart des appareils mobiles modernes disposant d'une connexion Internet et équipés de systèmes d'exploitation populaires tels qu'Android ou iOS.",
  youareresponsible:
    "Vous êtes responsable de l'obtention de l'accès au réseau nécessaire pour utiliser les produits et le service de livraison des applications/sites Web et tous les tarifs et frais du réseau mobile de votre fournisseur de livraison relèvent de votre responsabilité, y compris pour les données relatives à l'utilisation des applications mobiles et /ou le site Yaliwe . Il est également de votre responsabilité d'accéder aux appareils compatibles et aux systèmes d'exploitation nécessaires à l'utilisation des produits et aux applications/sites Web du service de livraison, y compris les mises à jour logicielles ou matérielles.",
  inordertouse:
    "Afin d'utiliser les produits et services de livraison Application(s)/Site(s) Internet, vous devez vous inscrire dans les applications mobiles et/ou sur le site Internet Yaliwe et créer un compte. Au moment de votre inscription, vous devez avoir l'âge de la majorité légale au Cameroun ou dans votre pays de résidence.",
  youmustprovide:
    "Vous devez nous fournir certaines informations personnelles pour vous inscrire, notamment votre numéro de téléphone et votre adresse e-mail. Pour utiliser les produits et le service de livraison d'Application(s)/Site(s) Internet, les produits et service de livraison fournis par Yaliwe ou certains produits et service de livraison intermédiaire, indiquez éventuellement au moins un moyen de paiement valide (carte de crédit, carte de débit, vous devrait utiliser l'argent mobile ou tout autre mode de paiement accepté). Vous devrez fournir des informations, telles que votre adresse et des documents supplémentaires, avant d'utiliser certains produits et le service de livraison fourni par Yaliwe ou certains produits et le service de livraison intermédiaire.",
  youarenotrequired:
    "Vous n'êtes pas obligé de vous connecter ou d'utiliser les applications mobiles et/ou le site Yaliwe, et les Prestataires de Services Intermédiaires ne le sont pas non plus. Si vous choisissez de cesser d’utiliser les applications mobiles et/ou le site Internet de Yaliwe, vous pouvez le faire sans nous en informer.",
  wemaytemporarily:
    "Nous pouvons temporairement restreindre votre accès et votre utilisation des produits et des applications/sites Web du service de livraison en cas de violation présumée de vos obligations, « Utilisation des produits et des applications/sites Web du service de livraison », « vos obligations », « Frais et paiement », « Propriété intellectuelle », figurant au sein des présentes CGU, y compris lorsque nous recevons une plainte relative à un acte frauduleux, pendant une durée qui n'excèdera pas le temps nécessaire à la vérification des informations relatives à l'acte frauduleux allégué. violation desdites obligations. Dans certaines circonstances, nous pourrions ne pas être en mesure de vous fournir des informations sur la plainte pendant qu'une enquête est en cours (par nous et/ou un intermédiaire comme la police).",
  yourobligations: "Vos obligations",
  whenyouusetheproducts:
    "Lorsque vous utilisez les produits et les applications/sites Web du service de livraison, vous devez vous conformer à toutes les législations applicables. Vous ne pouvez pas utiliser les produits et les applications/sites Web du service de livraison uniquement à des fins licites et pour lesquelles ils ont été conçus, comme indiqué dans les Conditions présentes. Vous ne devez pas utiliser à mauvais escient les applications mobiles et/ou les sites Internet de Yaliwe ou tenter de commettre un acte frauduleux à notre rencontre ou à l'encontre de prestataires de services intermédiaires.",
  youmustprovideaccurate:
    "Vous devez fournir des informations exactes et complètes lorsque vous créez un compte et lorsque vous utilisez les produits et les applications/sites Web du service de livraison.",
  youraccountispersonal:
    "Votre compte est personnel. Il ne peut être loué ou partagé sauf autorisation expresse de Yaliwe. Une fois votre compte créé :",
  youmaynotregister:
    "Vous ne pouvez pas créer plus d'un compte, sauf accord contraire écrit de notre part ;",
  youcannotauthorize:
    "Vous ne pouvez pas autoriser des intermédiaires à utiliser votre compte, ni transférer votre compte à un intermédiaire",
  youmustensure:
    "Vous devez vous assurer que les informations de votre compte sont exactes, complètes et à jour ;",
  youmustnotdisclose:
    "Vous ne devez pas divulguer vos informations de connexion confidentielles ;",
  youmustinformus:
    "Vous devez nous informer immédiatement si vous avez des raisons de croire que quelqu'un d'autre connaît votre nom d'utilisateur ou votre mot de passe ou si vous soupçonnez que quelqu'un d'autre utilise votre compte sur le bouton Contactez-nous.",
  youwillberesponsible:
    "Vous serez responsable du paiement de tous les frais pouvant être dus du fait de la fourniture de produits et du service de livraison d'intermédiaire ou de produits et du service de livraison fourni par Yaliwe demandé via votre compte, à moins que l'accès et l'utilisation de votre compte ne résultent d'un cas de force majeure, du fait d'un intermédiaire présentant les caractéristiques d'un cas de force majeure (exemple : piratage informatique, piratage informatique) ou encore d'un manquement par Yaliwe à son obligation de sécurité. En cas de vol d’informations bancaires ou mobile money, vous êtes tenu d’avertir immédiatement votre établissement bancaire afin d’effectuer les démarches nécessaires.",
  youshallnot:
    "Vous ne devez pas, dans votre utilisation des Produits et de la (des) Application(s)/Site(s) Web de Service de livraison, causer des nuisances, des problèmes, des désagréments ou des dommages matériels, que ce soit au Prestataire intermédiaire, à Yaliwe ou à toute autre personne.",
  feesrelatedtotheproducts:
    "Honoraires liés aux produits et au service intermédiaire et de livraison des produits et service de livraison Fourni par",
  ifyoumakeasolicitation:
    "Si vous effectuez une sollicitation en utilisant les applications mobiles et/ou les sites Internet Yaliwe, vous vous engagez à payer les frais applicables. Vous serez à votre charge tous les frais liés à votre compte dans les conditions décrites à l'article 6.4 ci-dessus.",
  dependingontheservice:
    "En fonction du Service fourni par Yaliwe ou du Service Intermédiaire utilisé et en fonction notamment du lieu où il est fourni, le montant total des frais relatifs à votre demande pourra être calculé et affiché sur les applications mobiles et/ou le site Internet de Yaliwe avant jusqu'à vous confirmez votre commande. A défaut, notamment dans le cas des produits et du service de livraison intermédiaire assurés par les Prestataires Intermédiaires de Dans certaines villes, les tarifs dépendent de votre utilisation des produits et services de livraison intermédiaire ou des produits et service de livraison assurés par Yaliwe (par exemple, le délai et/ou distance parcourue). Dans ce cas, la grille tarifaire applicable vous sera communiquée dans les applications mobiles et/ou sur le site Internet de Yaliwe avant la confirmation de commande. Une estimation de ces coûts peut être affichée dans des applications mobiles et/ou sur les sites Internet Yaliwe, à titre informatif uniquement.",
  iftheproductsanddelivery:
    "Si les produits et services de livraison d'intermédiaire ou les produits et services de livraison fournis par Yaliwe ne peuvent être fournis en raison d'un acte ou d'une inaction de votre part (par exemple, si vous n'êtes pas présent sur le lieu de livraison pour réceptionner une livraison), tous les frais pourront vous être facturés, sauf si vous justifiez que ce n'est pas dû à un acte ou une inaction de votre part que les produits et le service de livraison fournis n'ont pu être fournis.",
  oncetheintermediary:
    "Une fois la Prestation Intermédiaire ou le Service fourni par Yaliwe fourni, nous pouvons vous émettre une facture directement ou au nom du Prestataire intermédiaire, selon le cas.",
  feesaresubjecttochange:
    "Les frais sont susceptibles de changer dans les applications mobiles et/ou sur les sites Internet Yaliwe. Vous serez informé au préalable de toute modification des tarifs par email ou notification sur les applications mobiles et/ou sur les sites Internet de Yaliwe. Vous devrez accepter ces modifications afin de continuer à les utiliser. Si vous ne les acceptez pas, vous ne devez plus utiliser les produits et services de livraison App(s)/Web.",
  cancellation: "Annulation",
  youhavetheright:
    "Vous avez le droit d'annuler une commande, avant qu'elle ne soit expédiée dans les 24 heures, le cas échéant, Yaliwe ne vous facturera pas de frais d'annulation.",
  ifyoupurchase:
    "Si vous achetez un article sur Yaliwe, vous pouvez exercer votre droit de rétractation de la commande depuis ce Yaliwe dans les 24 heures suivant la date de votre achat. Yaliwe vous fournira toutes les informations et vous remboursera votre commande.",
  wecollectinformation:
    "Nous collectons des informations auprès de vous ou à votre sujet. Des déclarations de confidentialité supplémentaires peuvent s'appliquer, y compris aux intermédiaires responsables du traitement, pour les produits et le service de livraison fournis par Yaliwe ou les produits et services de livraison des intermédiaires.",
  yourresponsibilities: "vos responsabilités",
  youraresponsible:
    "Vous êtes responsable de tout dommage résultant de votre violation des présentes Conditions, de votre mauvaise utilisation des produits et des applications/sites Web du service de livraison ou de votre violation de toute disposition légale ou des droits d'un intermédiaire. Il est de votre responsabilité de faire et de conserver des copies des données disponibles sur votre compte dont vous avez besoin (par exemple les factures).",
  limitationofliability: "Limitation de responsabilité",
  ifyouactas:
    "Si vous agissez en qualité de consommateur, votre responsabilité contractuelle ne pourra être engagée pour des dommages imprévisibles au jour de la conclusion du contrat. contrat, lié à l’utilisation des produits et au(x) service(s) de livraison Application(s)/Site(s) Internet.",
  forallproduct:
    "Pour tous les utilisateurs du produit et le service de livraison d'Application(s)/Site(s) Web n'agissant pas en tant que consommateur, notre responsabilité ne saurait être engagée pour des dommages indirects notamment : (i) perte de profits ; (ii) perte de chiffre d’affaires ; (iii) perte d'accords ou de contrats ; (iv) perte des économies attendues ; (v) perte de données ou d’informations et (vi) perte de clientèle.",
  wearenot:
    "Nous ne sommes pas responsables des retards ou des défauts d'exécution résultant d'un cas de force majeure.",
  yaliweiscommitted:
    "Yaliwe s'engage envers le consommateur à fournir des produits et des services de qualité. Application(s)/Site(s) Web. Le Prestataire Intermédiaire est tenu de respecter les obligations découlant de la fourniture des produits et du service de livraison intermédiaire et notamment de la qualité des produits et du service de livraison (qualité, adéquation).",
  yaliweisdirectlyliable:
    "Yaliwe est directement responsable en tout ou partie en cas d'inexécution ou de mauvaise exécution des produits et du service de livraison lorsque cette inexécution ou mauvaise exécution ne vous est pas imputable, sauf lorsque cette inexécution ou mauvaise exécution résulte du fait imprévisible et insurmontable d'un intermédiaire dans la fourniture des produits et le service de livraison, ou en cas de force majeure.",
  termination: "Résiliation",
  youarefree:
    "Vous êtes libre à tout moment d'utiliser les produits et les applications/sites Web du service de livraison (le cas échéant) et vous pouvez résilier les présentes conditions ainsi que les applications/sites Web des produits et du service de livraison en fermant Votre compte.",
  yaliwemayterminate:
    "Yaliwe peut résilier les présentes Conditions ou interrompre les Produits et le(s) Service(s) de livraison Application(s)/Site(s) Web pendant une période qui n'excédera pas la durée nécessaire à la vérification des informations relatives aux violations, en cas de violation de la loi, des présentes Conditions Générales. Conditions ou de la Charte de la Communauté, en vous en informant au préalable par écrit dans un délai raisonnable.",
  general: "Générale",
  yaliwereservestheright:
    "Yaliwe se réserve le droit de modifier ces Conditions. Nous vous informerons de ces modifications par email avant leur entrée en vigueur dans un délai raisonnable. Vous serez lié par ces modifications une fois que vous les aurez acceptées dans les applications mobiles et/ou sur le site Yaliwe. et/ou par email, le cas échéant. Si vous n'êtes pas d'accord avec ces modifications, vous êtes libre de fermer votre compte.",
  wemayassign:
    "Nous pouvons céder, sous-traiter ou transférer les présentes Conditions ou l'un de nos droits ou obligations y afférents, en tout ou en partie, sans votre consentement préalable, à condition que cela n'entraîne pas une réduction des droits dont vous disposez en vertu des présentes Conditions ou des loi. Vous ne pouvez pas céder, sous-traiter ou transférer les présentes CGU ou l'un de nos droits ou obligations, en tout ou en partie, car votre utilisation des applications mobiles et/ou du site internet de Yaliwe est personnelle, sauf accord contraire.",
  theseconditionstogether:
    "Ces Conditions, ainsi que tout accord supplémentaire relatif aux produits fournis et aux applications/sites Web du service de livraison, constituent l'intégralité de notre accord avec vous et remplacent tous les accords antérieurs relatifs à votre accès et à votre utilisation des produits. et les applications/sites Web du service de livraison.",
  youmaybesasked:
    "Il peut vous être demandé d'accepter des conditions supplémentaires pour accéder ou utiliser les produits et les applications/sites Web du service de livraison. En cas de conflit entre les présentes Conditions et un accord séparé, ce dernier prévaudra, sauf stipulation contraire dans l'accord séparé.",
  disputeresolutionprocedure: "Procédure de résolution des litiges",
  yaliwemaymakeavailable:
    "Yaliwe peut mettre à la disposition des utilisateurs agissant en tant que consommateurs un système de médiation pour les litiges de consommation liés aux produits et le service de livraison des applications/sites Web ou les présentes conditions pour leur résolution à l'amiable.",
  applicablelawandcompetent: "Loi applicable et juridiction compétente",
  thesetermswillbe:
    "Les présentes Conditions seront exclusivement régies et interprétées conformément à la loi ivoirienne. En acceptant ces Conditions, vous acceptez d'être lié par celles-ci.",
  operatebyubslogistic: "Opéré par UBS Logistic",
  ordersummary: "Récapitulatif de la commande",
  quantity: "Quantité",
  status: "Statut",
  deliveredto: "Livré à",
  ordercancelled: "Commandé annulé",
  cancelorder: "annuler la commande",
  trackorder: "Suivi de commande",
  yourwishlistisempty: "Votre liste de souhaits est vide",
  updateprofile: "Mettre à jour le profil",
  doyou:
    "Voulez-vous vraiment supprimer votre compte ? Cette action est irréversible.",
  confirm: "Confirmer",
  imagepreview: "Aperçu de l'image",
  passwordisrequired: "Mot de passe requis",
  lognin: "Se connecter",
  addnewaddress: "Ajouter une nouvelle adresse",
  add: "Ajouter",
  oldpasswordcannotbeempty: "L'ancien mot de passe ne peut pas être vide",
  changepasssword: "Changer le mot de passe",
  oldpasssword: "ancien mot de passe",
  newpasssword: "nouveau mot de passe",
  confirmpasssword: "Confirmez le mot de passe",
  orderconfirmed: "Commande confirmée",
  orderdispatch: "Expédition des commandes",
  orderdelivered: "Commande livrée",
  blog1heading: "MATERIEL AUDIO ET VIDEO",
  blog1text:
    "Vous Trouverez Ici Tout Ce Dont Vous Avez Besoins En Qualités De Matériels Audiovisuel, Même Les Plus Perfectionnes",
  blog2heading: "ORDINATEURS ET MODEM WIFI",
  blog2text:
    "Parcourez Nos Ordinateurs Selon Vos Gouts Toutes Les Marques Ainsi Que Les Modems Wifi Sont A Votre Disposition. ",
  blog3heading: "ROUTEURS ET SERVEURS",
  blog3text:
    "Profitez De Nos Offres Sur Les Dernières Technologies Et Les Equipements informatique De Dernières Génération. Commandez Avec Aisance",
  ourlatest: "Notre dernière",
  blog: "Blog",
  paymentsuccess: "succès du paiement",
  wereceivedyourpurchaserequest: "nous avons reçu votre demande d'achat",
  wewillbeintouchshortly: "nous vous contacterons sous peu",
  paymentfailed: "paiement échoué",
  pleasetryagainlater: "Veuillez réessayer plus tard",
};
