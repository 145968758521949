import React from "react";
import "../Styles/Account.css";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import AccountLeftBar from "./AccountLeftBar";
import Profile from "./Profile";
import MyOrders from "./MyOrders";
import MyAddresses from "./MyAddresses";
import MyCoupons from "./MyCoupons";
import { useParams } from "react-router-dom";
import Footer from "./Footer";

function Account() {
  const { accountSlug } = useParams();
  return (
    <>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-3">
            <AccountLeftBar />
          </div>

          <div className="col-lg-9">
            {accountSlug === "profile" ? <Profile /> : ""}
            {accountSlug === "orders" ? <MyOrders /> : ""}
            {accountSlug === "addresses" ? <MyAddresses /> : ""}
            {accountSlug === "coupons" ? <MyCoupons /> : ""}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Account;
