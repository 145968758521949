import React from "react";
import "../Styles/HairPerfector.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HairPerfector() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const handleShopAllNavigate = (slug) =>{
  //   navigate(`/category/${slug}`);
  // }
  return (
    <div className="mb-3">
      <img src="middlebanner2.png" alt="" class="banner2image"></img>
      {/* <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3 py-5">
            <div className="text-start hairperfector_heading1 mb-2">
              {t("hairperfector")}
            </div>
            <div className="text-start hairperfector_heading2 mb-2">
              {t("forstylish")}
            </div>
            <div className="text-start hairperfector_para mb-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
            <div className="text-start hairperfector_button">
              <button>{t("shopcollectionnow")}</button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default HairPerfector;
