import axios from "axios";
import { baseurl } from "../baseurl";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export async function fetchuserApi() {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(`${baseurl}fetch-user`, { headers });
    return response;
  } catch (error) {
    console.log("Error in fetchuser Api:", error);
    // if (error.response?.data.message === "Unauthenticated.") {
    //   toast.error(error.response?.data.message);
    // }
    return null;
  }
}

export async function LoginApi(userData, callback) {
  try {
    axios
      .post(`${baseurl}login`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Yaliwe",
          text: "Enter correct Email or Password",
        });
      });
  } catch (error) {
    console.log("Error in Login Api:", error);
    return null;
  }
}

export async function SignUpApi(userData) {
  console.log("in signup api");
  console.log(userData);
  try {
    const response = await axios.post(`${baseurl}register`, userData);
    console.log("Response from signup api", response);
    return response;
  } catch (error) {
    console.log("Error in SignUp Api:", error);
    Swal.fire({
      icon: "error",
      title: "Yaliwe",
      text: "Something Went Wrong",
    });
    return null;
  }
}

export async function forgotpasswordApi(userData) {
  try {
    const response = await axios.post(`${baseurl}forgot-password`, userData);
    console.log("Response from forgot-password api", response);
    return response;
  } catch (error) {
    console.log("Error in forgotpassword Api:", error);
    Swal.fire({
      icon: "error",
      title: "Yaliwe",
      text: "Email is Incorrect",
    });
    return null;
  }
}

export async function otpverificationApi(userData) {
  try {
    const response = await axios.post(`${baseurl}otp-verification`, userData);
    console.log("Response from otpverificationApi", response);
    return response;
  } catch (error) {
    console.log("Error in otpverificationApi:", error);
    Swal.fire({
      icon: "error",
      title: "Yaliwe",
      text: "Invalid Otp",
    });
    return null;
  }
}

export async function newPasswordApi(userData) {
  try {
    const response = await axios.post(`${baseurl}reset-password`, userData);
    console.log("Response from newPasswordApi", response);
    return response;
  } catch (error) {
    console.log("Error in newPasswordApi:", error);
    Swal.fire({
      icon: "error",
      title: "Yaliwe",
      text: "Your request expired please try again",
    });
    return null;
  }
}
