import { ActionTypes } from "../constant/action-types";

const initialState = {
  products: [],
  loading: false,
};

export const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.EMPTY_PRODUCTS:
      return { ...state, products: payload};
    case ActionTypes.FETCH_PRODUCTS:
      return { ...state, products: payload || [], loading: false };
    case ActionTypes.SET_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};
 