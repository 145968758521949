import { ActionTypes } from "../constant/action-types";
import axios from "axios";
import { baseurl } from "../../baseurl";

export const fetchAllAddress = () => {
  return async function (dispatch, getState) {
    const token = localStorage.getItem("Yaliwe_Token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(`${baseurl}fetch-all-address`, {
      headers,
    });
    dispatch({
      type: ActionTypes.FETCH_ALLADDRESS,
      payload: response?.data?.data?.addressList, 
    });
  };
};

export const clearAddress = () =>{
  return { 
    type:ActionTypes.EMPTY_ALLADDRESS,
    payload:[]
  }
}
