import React, { useState } from "react";
import "../Styles/AccountLeftBar.css";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../redux/actions/userActions";
import { emptyCart } from "../redux/actions/productActions";
import { emptyWishlist } from "../redux/actions/wishlistActions";
import { clearAddress } from "../redux/actions/AddressAction";
import { useDispatch, useSelector } from "react-redux";
import Sidebar3 from "../Sidebar/Sidebar3";
import ToggleButton from "@mui/material/ToggleButton";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import Avatar from "@mui/material/Avatar";
import { profilePicApi } from "../Apis/MainApis";
import { fetchUser } from "../redux/actions/userActions";
import { IoPersonOutline } from "react-icons/io5";
import { MdOutlineContactMail } from "react-icons/md";
import { TbGavel } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import ResetPassword from "../Modal/ResetPassword";
import DeleteAccountModal from "../Modal/DeleteAccountModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function AccountLeftBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    dispatch(emptyCart());
    dispatch(emptyWishlist());
    dispatch(clearAddress());
    setIsSidebarOpen(false);
    navigate("/login");
  };
  const handleOrdernavigate = (accountSlug) => {
    navigate(`/account/${accountSlug}`);
    setIsSidebarOpen(false);
  };
  const handleProfilenavigate = (accountSlug) => {
    navigate(`/account/${accountSlug}`);
    setIsSidebarOpen(false);
  };

  const handleAdressesNavigate = (accountSlug) => {
    navigate(`/account/${accountSlug}`);
    setIsSidebarOpen(false);
  };

  const closeSidebar3 = () => {
    setIsSidebarOpen(false);
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const fileInputRef = React.createRef();

  async function handleImageUpload(e) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/png")) {
        toast.error("Image format should be PNG");
        return;
      }
      try {
        const base64String = await getBase64(file);
        console.log(base64String);

        const data = {
          profile: base64String,
        };

        const response = await profilePicApi(data);
        console.log(response);
        await dispatch(fetchUser());
      } catch (error) {
        console.error("Error during image upload:", error);
      }
    }
  }

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <ResetPassword
        showResetPassword={showResetPassword}
        setShowResetPassword={setShowResetPassword}
      />
      <DeleteAccountModal
        showDeleteModal={showDeleteModal}
        setDeleteModal={setDeleteModal}
      />
      <div className="col-10 d-lg-none category_toggle d-flex justify-content-start">
        <ToggleButton
          value="left"
          aria-label="left aligned"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <FormatAlignLeftIcon />
        </ToggleButton>
        <Sidebar3
          isOpen={isSidebarOpen}
          closeSidebar3={closeSidebar3}
          handleProfilenavigate={handleProfilenavigate}
          handleOrdernavigate={handleOrdernavigate}
          handleLogout={handleLogout}
          handleImageUpload={handleImageUpload}
          fileInputRef={fileInputRef}
          handleUploadButtonClick={handleUploadButtonClick}
          handleAdressesNavigate={handleAdressesNavigate}
          setShowResetPassword={setShowResetPassword}
          setDeleteModal={setDeleteModal}
        />
      </div>

      <div className="myaccountleftbar d-lg-block d-none">
        <Avatar
          alt={user?.name_en}
          src={user?.image}
          className="mb-2"
          sx={{ width: 74, height: 74 }}
        />
        <h6 className="text-start fw-bold">{user?.name_en}</h6>
        <h6 className="text-start fw-bold">{user?.email}</h6>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <button
          onClick={handleUploadButtonClick}
          className="uploadImageButton mb-3"
        >
          {t("uploadimage")}
        </button>

        <div className="myaccountleftbarbox2 d-flex">
          <div>
            <IoPersonOutline style={{ color: "#4cab37", fontSize: "larger" }} />
          </div>
          <div className="ms-3">
            <ul className="account_setting text-start m-0 p-0">
              <li
                style={{ color: "black", fontWeight: "bold" }}
                className="mb-1"
              >
                {t("myaccount")}
              </li>
              <li onClick={() => handleProfilenavigate("profile")}>
                {t("myprofile")}
              </li>
              <li onClick={() => setShowResetPassword(true)}>
                {t("changepassword")}
              </li>
              <li onClick={() => setDeleteModal(true)}>{t("deleteaccount")}</li>
            </ul>
          </div>
        </div>

        <div className="myaccountleftbarbox3 d-flex">
          <div>
            <MdOutlineContactMail
              style={{ color: "#4cab37", fontSize: "larger" }}
            />
          </div>
          <div className="ms-3">
            <ul className="account_setting text-start m-0 p-0">
              <li
                style={{ color: "black", fontWeight: "bold" }}
                className="mb-1"
              >
                {t("mystuff")}
              </li>

              <li onClick={() => handleOrdernavigate("orders")}>
                {t("myorders")}
              </li>
              <li onClick={() => handleAdressesNavigate("addresses")}>
                {t("myaddresses")}
              </li>
              <li
                onClick={() => {
                  navigate("/wishlist");
                  setIsSidebarOpen(false);
                }}
              >
                {t("mywishlist")}
              </li>
              <li
                onClick={() => {
                  navigate("/cart");
                  setIsSidebarOpen(false);
                }}
              >
                {t("mycart")}
              </li>
            </ul>
          </div>
        </div>

        <div className="myaccountleftbarbox4 d-flex">
          <div>
            <TbGavel style={{ color: "#4cab37", fontSize: "larger" }} />
          </div>
          <div className="ms-3">
            <ul className="account_setting text-start m-0 p-0">
              <li
                style={{ color: "black", fontWeight: "bold" }}
                className="mb-1"
              >
                {t("legal")}
              </li>

              <li
                onClick={() => {
                  navigate("/faq");
                  setIsSidebarOpen(false);
                }}
              >
                {t("faq")}
              </li>
              <li
                onClick={() => {
                  navigate("/termsandconditions");
                  setIsSidebarOpen(false);
                }}
              >
                {t("termsandcondition")}
              </li>
              <li
                onClick={() => {
                  navigate("/legalnotice");
                  setIsSidebarOpen(false);
                }}
              >
                {t("legalnotice")}
              </li>
            </ul>
          </div>
        </div>

        <div className="myaccountleftbarbox5 d-flex">
          <div>
            <MdLogout style={{ color: "#4cab37", fontSize: "larger" }} />
          </div>
          <div className="ms-3">
            <ul className="account_setting text-start m-0 p-0">
              <li
                style={{ color: "black", fontWeight: "bold" }}
                onClick={handleLogout}
              >
                {t("logout")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountLeftBar;
