import "./App.css";
import Route from "./Route";
import { fetchUser } from "./redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchCart } from "./redux/actions/productActions";
import { fetchWishlist } from "./redux/actions/wishlistActions";
import { fetchHomeProducts } from "./redux/actions/HomeProductsAction";
import { fetchAllAddress } from "./redux/actions/AddressAction";
import { fetchParentCategoriesProducts } from "./redux/actions/ParentCategoriesAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function App() {
  const dispatch = useDispatch();
  const userid = useSelector((state) => state?.user?.user?.id);
  let lang = localStorage.getItem("language");

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchHomeProducts(userid));
  }, [dispatch, userid]);

  if (userid) {
    dispatch(fetchCart());
    dispatch(fetchWishlist(userid));
    dispatch(fetchAllAddress());
  }
  return (
    <div className="App">
      <ToastContainer />
      <Route />
    </div>
  );
}

export default App;
