import { ActionTypes } from "../constant/action-types";
import axios from "axios";
import { baseurl } from "../../baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const fetchWishlist = (userid) => {
  return async function (dispatch, getState) {
    const token = localStorage.getItem("Yaliwe_Token");
    const lang = localStorage.getItem("Yaliwe_language");
    const data = {
      language: lang||"en",
      uid: userid || "",
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(
      `${baseurl}fetch-wishlist-products`,
      data,
      { headers }
    );
    dispatch({
      type: ActionTypes.FETCH_WISHLIST,
      payload: response?.data?.data,
    });
  };
};

export const removeFromWishlist = (id, userid) => {
  return async function (dispatch, getState) {
    const token = localStorage.getItem("Yaliwe_Token");
    const lang = localStorage.getItem("Yaliwe_language");
    const data = {
      language: lang||"en",
      productId: id,
      uid: userid || "",
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}remove-to-wishlist`, data, {
      headers,
    });
    if (response?.data?.message === "Authorization Failed") {
      toast("Login to Continue");
      return;
    }
    toast(response?.data?.message);
  };
};

export const addToWishlist = (id, userid, activeRootButtonId) => {
  return async function (dispatch, getState) {
    const token = localStorage.getItem("Yaliwe_Token");
    const lang = localStorage.getItem("Yaliwe_language");
    const data = {
      language: lang||"en",
      productId: id,
      uid: userid || "",
      variationImageId: activeRootButtonId,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}add-to-wishlist`, data, {
      headers,
    });
    if (response?.data?.message === "Authorization Failed") {
      toast("Login to Continue");
      return;
    }
    toast(response.data.message);
  };
};

export const emptyWishlist = () => {
  return {
    type: ActionTypes.EMPTY_WISHLIST,
    payload: [],
  };
};
