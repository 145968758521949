import React, { useState } from "react";
import "../Styles/Checkout.css";
import { useSelector, useDispatch } from "react-redux";
import AddressModal from "../Modal/AddressModal";
import NewAddressModal from "../Modal/NewAddressModal";
import { deleteAddressApi } from "../Apis/MainApis";
import { fetchAllAddress } from "../redux/actions/AddressAction";
import Payment from "./Payment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { removeFromCart } from "../redux/actions/productActions";
import { fetchCart } from "../redux/actions/productActions";
import { updateToCart } from "../redux/actions/productActions";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { orderCreateApi } from "../Apis/MainApis";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

function Checkout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addresses = useSelector((state) => state?.addresses?.addresses);
  const cartdata = useSelector((state) => state?.cart?.products);
  const userid = useSelector((state) => state?.user?.user?.id);

  const [openAddressModal, setAddressModal] = useState(false);
  const [openNewAddressModal, setNewAddressModal] = useState(false);
  const [addressid, setAddressId] = useState();
  const [selectedAddressId, setSelectedAddressId] = useState();
  const [openpayment, setOpenPayment] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCheckoutUpdate = (id) => {
    setAddressId(id);
    setAddressModal(true);
  };

  const handleDeleteAddress = async (id) => {
    setOpenPayment(false);
    setSelectedAddressId();
    const data = {
      addressId: id,
    };
    const response = await deleteAddressApi(data);
    if (response?.data.message === "Authorization Failed") {
      toast.error("Login to continue");
      navigate("/login");
      return;
    }
    toast(response?.data?.message);
    dispatch(fetchAllAddress());
  };

  const handleDeleteCart = async (id) => {
    setOpenPayment(false);
    if (userid === undefined) {
      toast.error("Login to continue");
      navigate("/login");
      return;
    }
    await dispatch(removeFromCart(id));
    await dispatch(fetchCart());
  };

  const handleUpdateToCart = async (
    cartId,
    productId,
    variationId,
    quantity
  ) => {
    setOpenPayment(false);
    if (userid === undefined) {
      toast.error("Login to continue");
      navigate("/login");
      return;
    }
    if (quantity === 0) {
      await dispatch(removeFromCart(cartId));
      await dispatch(fetchCart());
    } else {
      await dispatch(updateToCart(cartId, productId, variationId, quantity));
      await dispatch(fetchCart());
    }
  };

  const handleRadioChange = (id) => {
    setOpenPayment(false);
    setSelectedAddressId(id);
  };

  const handlePayment = async () => {
    if (userid === undefined) {
      toast.error("Login to continue");
      navigate("/login");
      return;
    }
    if (cartdata?.data?.length === 0 || cartdata?.length === 0) {
      toast.error("There is no items for checkout");
      setOpenPayment(false);
      navigate("/");
      return;
    }
    if (selectedAddressId) {
      if (paymentType === "") {
        toast.error("Please select payment type");
      } else if (paymentType === "paypal") {
        setOpenPayment(!openpayment);
        setPaymentType("paypal");
      } else if (paymentType === "stripe") {
        setPaymentType("stripe");
        setOpenPayment(!openpayment);
        const stripe = await loadStripe(
          "pk_test_51OlnHKSDiwRAgYmRQrqPs0jNWbPj5YSQxAm1HIQWZZTFiq1TdwPwTZ1Ve0lyrNx1Vf9GbUEwGMfZzRj8X4je1LeS00OkFjrDB4"
        );

        const dataToSend = {
          addressId: selectedAddressId,
          couponId: cartdata?.couponInfo[0]?.couponId || "",
          discountAmount: cartdata?.couponInfo[0]?.discountAmount,
          totalAmount: cartdata?.couponInfo[0]?.subtotalAmount,
          payAmount: cartdata?.couponInfo[0]?.grandTotal,
          payment_method: "stripe",
          txn_id: "",
          payment_status: 1,
          billing_shipping_address: selectedAddressId,
          item: cartdata?.data?.map((product) => ({
            productId: product.productDetails[0].id,
            variationId: product.variationId,
            quantity: product.quantity,
            base_price: parseFloat(product.variationData.base_price),
            sale_price: parseFloat(product.variationData.sale_price),
            sku: product.variationData.sku,
          })),
        };
        setLoading(true);
        const response = await orderCreateApi(dataToSend);
        console.log(
          "response for stripe by order create api",
          response.data.url
        );

        // const session = "";
        setLoading(false);
        const stripeCheckoutUrl = response.data.url;
        window.location.href = stripeCheckoutUrl;
        // const result = stripe.redirectToCheckout({
        //   sessionId: response.data.url,
        // });

        // if (result.error) {
        // }
      }
    } else {
      toast.error("Please select address");
    }
  };

  const handlePaymentChange = (type) => {
    setPaymentType(type);
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <AddressModal
            openAddressModal={openAddressModal}
            setAddressModal={setAddressModal}
            addressid={addressid}
          />
          <NewAddressModal
            openNewAddressModal={openNewAddressModal}
            setNewAddressModal={setNewAddressModal}
          />
          <div className="container py-5">
            <div className="row checkout_row">
              <div className="col-12 col-lg-6 col-md-6">
                <div className="row checkout_cartitems">
                  <h5 className="text-start fw-bold mb-5">
                    {t("checkoutitems")}
                  </h5>
                  {cartdata?.data?.length === 0 || cartdata?.length === 0 ? (
                    <>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <h3>{t("thereisnoitemsforcheckout")}</h3>
                        <h3
                          onClick={() => navigate("/")}
                          className="checkout_homepage"
                        >
                          {t("gotohomepage")}
                        </h3>
                      </div>
                    </>
                  ) : (
                    <>
                      {cartdata?.data?.map((cart, i) => (
                        <>
                          <div className="col-xl-6 col-lg-7 d-flex align-items-center justify-content-center">
                            <img
                              src={cart?.productDetails[0]?.variationImages[0]}
                              className="checkoutcart_image"
                              alt=""
                            />
                          </div>
                          <div className="checkoutcart_info col-xl-6 col-lg-5 mt-3 mt-md-0">
                            <div className="d-flex flex-column text-start">
                              <p className="mb-1">
                                <span className="fw-bold">
                                  {" "}
                                  {t("product")}:{" "}
                                </span>
                                {cart?.productDetails[0]?.name} (
                                {
                                  cart?.variationData?.variations[0]
                                    ?.variationName
                                }
                                ) (
                                {
                                  cart?.variationData?.variations[1]
                                    ?.variationName
                                }
                                )
                              </p>
                              <p className="mb-1">
                                <span className="fw-bold">{t("price")}: </span>${" "}
                                {cart?.variationData?.sale_price}
                              </p>
                              <p>
                                <span className="fw-bold">
                                  {t("subtotal")}:{" "}
                                </span>
                                ${" "}
                                {cart?.quantity *
                                  cart?.variationData?.sale_price}
                              </p>
                              <div className="d-flex align-items-center justify-content-around checkoutcart_quantity">
                                <AiOutlineMinus
                                  onClick={() =>
                                    handleUpdateToCart(
                                      cart?.cartId,
                                      cart?.productDetails[0]?.id,
                                      cart?.variationId,
                                      cart?.quantity - 1
                                    )
                                  }
                                />
                                <p className="m-0">{cart?.quantity}</p>

                                <AiOutlinePlus
                                  onClick={() =>
                                    handleUpdateToCart(
                                      cart?.cartId,
                                      cart?.productDetails[0]?.id,
                                      cart?.variationId,
                                      cart?.quantity + 1
                                    )
                                  }
                                />
                              </div>
                              <div
                                className="text-start mt-2 mb-2 d-flex align-items-center"
                                onClick={() => handleDeleteCart(cart?.cartId)}
                              >
                                <span className="checkoutcart_deletebuttontext">
                                  {t("remove")}
                                </span>
                                <MdDelete className="checkoutcart_deletebutton" />
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                        </>
                      ))}
                    </>
                  )}
                </div>
                <div>
                  {cartdata?.length !== 0 && cartdata?.data?.length !== 0 && (
                    <div className="row checkoutcart_checkout mt-3">
                      <div className="col-12 mt-3 mt-lg-0">
                        <div className="">
                          <h5 className="text-start fw-bold">
                            {t("checkout")}
                          </h5>
                          <div className=" d-flex justify-content-between align-items-center checkoutcart_total">
                            <p className="checkoutcart_total1">
                              {t("subtotal")}
                            </p>
                            <p>$ {cartdata?.couponInfo[0]?.subtotalAmount}</p>
                          </div>
                          <div className=" d-flex justify-content-between align-items-center checkoutcart_total">
                            <p className="cart_total1">{t("discount")}</p>
                            <p>$ {cartdata?.couponInfo[0]?.discountAmount}</p>
                          </div>
                          {/* <div className=" d-flex justify-content-between align-items-center checkoutcart_total">
                        <p className="checkoutcart_total1">Shipping</p>
                        <p>$ 0</p>
                      </div> */}
                          <hr />
                          <div className=" d-flex justify-content-between align-items-center checkoutcart_total">
                            <p className="checkoutcart_total1">{t("total")}</p>
                            <p>$ {cartdata?.couponInfo[0]?.grandTotal}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 checkout_addressmobile">
                <div className="checkout_address">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-start fw-bold m-0">
                      {t("selectdeliveryaddress")}
                    </h5>
                    <div className="text-start">
                      <button
                        className="checkout_addnewaddressbutton"
                        onClick={() => {
                          setNewAddressModal(true);
                          setOpenPayment(false);
                        }}
                      >
                        {t("addnew")}
                      </button>
                    </div>
                  </div>
                  {addresses?.length === 0 ? (
                    <div
                      style={{ height: "150px" }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {t("pleaseaddnewaddress")}
                    </div>
                  ) : (
                    <>
                      {addresses?.map((address, i) => (
                        <label className="d-flex py-3" key={i}>
                          <div>
                            <input
                              type="radio"
                              name="addressRadio"
                              value={address.id}
                              className="me-3"
                              onChange={() => handleRadioChange(address.id)}
                              checked={address.id === selectedAddressId}
                            />
                          </div>
                          <div className="text-start checkout_addressinfo">
                            <div className="mb-2">
                              <p className="m-0">
                                {address.name} ({address.address_type})
                              </p>
                              <p className="m-0">
                                ({address.phone_code}) {address.mobile}
                              </p>
                              <p className="m-0">
                                {address.street} , {address.landmark} ,{" "}
                                {address.city} , {address.state} ,{" "}
                                {address.code}{" "}
                              </p>
                            </div>
                            <button
                              className="checkout_DeliveryButton me-2"
                              onClick={() => handleCheckoutUpdate(address.id)}
                            >
                              {t("edit")}
                            </button>
                            <button
                              className="checkout_DeliveryButton"
                              onClick={() => handleDeleteAddress(address.id)}
                            >
                              {t("remove")}
                            </button>
                          </div>
                        </label>
                      ))}
                    </>
                  )}
                </div>

                <div className="text-start payment_selection">
                  <h5 className="text-start fw-bold mb-3">
                    {t("selectpaymentoption")}
                  </h5>
                  <div>
                    <input
                      type="radio"
                      name="paymentRadio"
                      value="paypal"
                      className="me-3"
                      onChange={() => handlePaymentChange("paypal")}
                      checked={paymentType === "paypal"}
                    />
                    <span>{t("paypal")}</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="paymentRadio"
                      value="stripe"
                      className="me-3"
                      onChange={() => handlePaymentChange("stripe")}
                      checked={paymentType === "stripe"}
                    />
                    <span>{t("stripe")}</span>
                  </div>
                </div>

                <div className="d-flex flex-column text-end mt-3">
                  <button
                    className="checkout_paymentbutton mb-5"
                    onClick={handlePayment}
                  >
                    {t("proceedtopay")} $
                    {cartdata?.couponInfo?.[0]?.grandTotal || 0}
                  </button>
                  <span
                    className="checkout_homepage"
                    onClick={() => navigate("/")}
                  >
                    {t("gotohomepage")}
                  </span>
                </div>

                {openpayment && paymentType === "paypal" ? (
                  <Payment
                    selectedAddressId={selectedAddressId}
                    couponId={cartdata?.couponInfo[0]?.couponId}
                    discount={cartdata?.couponInfo[0]?.discountAmount}
                    subtotal={cartdata?.couponInfo[0]?.subtotalAmount}
                    total={cartdata?.couponInfo[0]?.grandTotal}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Checkout;
