import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "../Styles/Payment.css";
import { orderCreateApi } from "../Apis/MainApis";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { fetchCart } from "../redux/actions/productActions";
import { useTranslation } from "react-i18next";

function Payment({ selectedAddressId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartdata = useSelector((state) => state.cart.products);

  const sendPaymentDataToServer = async (paymentDetails) => {
    const dataToSend = {
      addressId: selectedAddressId,
      couponId: cartdata?.couponInfo[0]?.couponId || "",
      discountAmount: cartdata?.couponInfo[0]?.discountAmount,
      totalAmount: cartdata?.couponInfo[0]?.subtotalAmount,
      payAmount: cartdata?.couponInfo[0]?.grandTotal,
      payment_method: "paypal",
      txn_id: paymentDetails?.purchase_units[0]?.payments?.captures[0]?.id,
      payment_status: 1,
      billing_shipping_address: selectedAddressId,
      item: cartdata?.data?.map((product) => ({
        productId: product.productDetails[0].id,
        variationId: product.variationId,
        quantity: product.quantity,
        base_price: parseFloat(product.variationData.base_price),
        sale_price: parseFloat(product.variationData.sale_price),
        sku: product.variationData.sku,
      })),
    };

    const response = await orderCreateApi(dataToSend);
    console.log("response from paypal", response);
    if (response.data.status === "success") {
      toast(response?.data?.message);
      await dispatch;
      navigate("/");
      dispatch(fetchCart());
    }
  };

  const initialOptions = {
    clientId:
      "ATVGPrvBoWlvRlRzzBPwPhqVkRXLV-W0CFpQ9crfkRxFiesECGE6ftY1b6hpaWQrhZFSZBtIMSqxjKRz",
    currency: "USD",
    intent: "capture",
  };

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "products",
            amount: {
              value: cartdata?.couponInfo[0]?.grandTotal,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderId) => {
        console.log(orderId);
        return orderId;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      if (details.status === "COMPLETED") {
        sendPaymentDataToServer(details);
      }
      console.log(details);
    });
  };

  const onError = (data, actions) => {
    console.error("Payment error:");
  };

  return (
    <>
      <div className="mt-5">
        <h1 className="text-start">
          {t("totalprice")}: ${cartdata?.couponInfo[0]?.grandTotal}
        </h1>
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </PayPalScriptProvider>
      </div>
    </>
  );
}

export default Payment;
