import React from "react";
import "./Sidebar2.css";
import { IoClose } from "react-icons/io5";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Checkbox } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import { useTranslation } from "react-i18next";

function Sidebar2({
  isOpen,
  closeSidebar2,
  handleClearAllFilter,
  handlePriceClear,
  handleBrandClear,
  handleBrandCheckboxChange,
  handleReviewClear,
  selectedRating,
  handleRatingCheckboxChange,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  brands,
  selectedBrands,
}) {
  const { t } = useTranslation();
  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div className={`sidebar2 ${isOpen ? "open" : ""}`}>
        <div className="text-end me-2 mb-3" onClick={() => closeSidebar2()}>
          <IoClose />
        </div>
        <div className="sidebar1_contents">
          <div className="d-flex align-items-center justify-content-between category_filtertop">
            <h4 className="m-0">{t("filters")}</h4>
            <span onClick={() => handleClearAllFilter()}> {t("clearall")}</span>
          </div>
          <hr />
          {/* <div className="category_categories">
            <div className="d-flex align-items-center justify-content-between">
              <h4>CATEGORIES</h4>
              <span onClick={() => handleCategoryClear()}>Clear</span>
            </div>
            <ul className="text-start p-0">
              {collectionData?.map((product, i) => (
                <li
                  key={i}
                  onClick={() =>
                    handleFeatureCategoriesNavigate(product.collectionSlug)
                  }
                  className={`category_categoriesli ${
                    product.collectionSlug === sliderSlug ? "active" : ""
                  }`}
                >
                  {product.collectionName}
                </li>
              ))}
            </ul>
          </div> */}
          <div className="category_filterprice">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <h4 className="m-0">{t("prices")}</h4>
              <span onClick={() => handlePriceClear()}>{t("clear")}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <FormControl sx={{ m: 1, minWidth: 20 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    {t("min")}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={minPrice}
                    label="Age"
                    onChange={(e) => setMinPrice(e.target.value)}
                  >
                    <MenuItem value="0">
                      <em>0</em>
                    </MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={1000}>1000</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 20 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    {t("max")}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={maxPrice}
                    label="Age"
                    onChange={(e) => setMaxPrice(e.target.value)}
                  >
                    <MenuItem value="20000000">
                      <em>1500+</em>
                    </MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={1000}>1000</MenuItem>
                    <MenuItem value={15000000}>1500+</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="category_brandsheading m-0">{t("brands")}</h4>
            <span
              onClick={() => handleBrandClear()}
              className="category_brandsclear"
            >
              {t("clear")}
            </span>
          </div>
          <div className="text-start mb-3 category_brandformcontrol">
            <FormControl component="fieldset">
              <FormGroup aria-label="position" column="true">
                {brands?.map((brand, i) => (
                  <FormControlLabel
                    key={i}
                    value={brand.id}
                    control={
                      <Checkbox
                        checked={selectedBrands.includes(brand.id.toString())}
                        onChange={handleBrandCheckboxChange}
                      />
                    }
                    label={brand.name}
                    labelPlacement="end"
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          <hr></hr>
          <div className="text-start category_customerReviews mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="m-0">{t("reviews")}</h4>
              <span
                onClick={() => handleReviewClear()}
                className="category_reviewsclear"
              >
                {t("clear")}
              </span>
            </div>

            <FormControl component="fieldset">
              <FormGroup aria-label="position" column>
                {Array.from({ length: 5 }, (_, index) => (
                  <FormControlLabel
                    key={index + 1}
                    value={(index + 1).toString()}
                    control={
                      <Radio
                        checked={selectedRating === index + 1}
                        onChange={handleRatingCheckboxChange}
                      />
                    }
                    label={`${index + 1} & up`}
                    labelPlacement="end"
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar2;
