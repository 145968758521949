import React, { useState, useEffect } from "react";
import "../Styles/MyCoupons.css";
import { discountApi } from "../Apis/MainApis";
import { useTranslation } from "react-i18next";

function MyCoupons() {
  const { t } = useTranslation();
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    async function discount() {
      const lang=localStorage.getItem('Yaliwe_language')
      const data = {
        page: 1,
        language: lang||"en",
      };
      const response = await discountApi(data);
      setCoupons(response?.data?.data?.offers);
    }
    discount();
  }, []);

  return (
    <>
      <table className="table myaddresses_DeliveryTable">
        <thead>
          <tr className="text-start">
            <th>{t("yourcoupons")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="d-flex flex-column myaddresses_alladdressdata">
              {coupons?.length === 0 ? (
                <tr className="d-flex flex-column align-items-center justify-content-center">
                  <td>
                    <img
                      src="/adddress.jpg"
                      alt=""
                      style={{ width: "200px" }}
                    ></img>
                    <p>{t("youhavenocoupons")}</p>
                  </td>
                </tr>
              ) : (
                <>
                  {coupons?.map((offer, i) => (
                    <label className="d-flex  py-3" key={i}>
                      <div key={i}>
                        <h6 className="fw-bold">{offer?.name}</h6>
                        <p className="m-0">({offer?.description})</p>
                      </div>
                    </label>
                  ))}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default MyCoupons;
