import React, { useEffect } from "react";
import "../Styles/ConstructionProduct.css";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchHomeProducts } from "../redux/actions/HomeProductsAction";
import { useSelector } from "react-redux";
import LoginModal from "../Modal/LoginModal";
import { AiFillStar } from "react-icons/ai";

function ConstructionProducts() {
  const collectionData = useSelector(
    (state) => state.homeproducts.homeproducts
  );
  const userid = useSelector((state) => state.user.user.id);

  const [newArrivalproduct, setNewArrivalProducts] = useState([]);
  const [showSignin, setShowSignin] = useState(false);
  const [sliderSlug, setSlug] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const newArrival = collectionData[1];
    setNewArrivalProducts(newArrival?.products);
    setSlug(newArrival?.collectionSlug);
  }, [collectionData]);

  const handleConstructionNavigate = (id, productslug) => {
    navigate(`/product/${id}/${productslug}`);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const slickSettings = {
    infinite: true,
    slidesToShow: newArrivalproduct?.length < 4 ? newArrivalproduct?.length : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const closeModals = () => {
    setShowSignin(false);
  };

  return (
    <>
      <LoginModal show={showSignin} onClose={closeModals} />
      <div className="constructionproduct_container">
        <div className="container py-5 ">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="d-flex justify-content-between mb-4">
                <div className="construction_products">
                  {collectionData?.[1]?.collectionName}
                </div>
              </div>
              <Slider className="slider-container" {...slickSettings}>
                {newArrivalproduct?.map((product, i) => (
                  <div className="item construction_card_container" key={i}>
                    <div
                      className="card construction_card"
                      onClick={() =>
                        handleConstructionNavigate(product.id, product.slug)
                      }
                    >
                      <div className="construction_cardtopportion">
                        <div className="construction_imagebackground">
                          <img
                            className="construction_image"
                            src={product?.featured_image}
                            alt=""
                          ></img>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <h5 className="construction_cardtitle text-start m-0">
                              {product?.categories[0]?.categoryDetails?.name}
                            </h5>
                            <span className="d-flex align-items-center category_ratingbox ms-2">
                              <AiFillStar className="me-1" />
                              {product.averageRating}
                            </span>
                          </div>
                        </div>

                        <p className="construction_cardtext text-start fw-bold m-0 py-2">
                          {product.name}
                        </p>
                        <div className="construction_span text-start d-flex align-items-center">
                          <span className="fw-bold me-2">
                            ${product.sale_price}
                          </span>
                          <span className="product_actualprice">
                            <div className="diagonal-line-wrapper">
                              ${product.base_price}
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConstructionProducts;
