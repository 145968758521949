import { ActionTypes } from "../constant/action-types";
import axios from "axios";
import { baseurl } from "../../baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const setLoading = (loading) => {
  return {
    type: ActionTypes.SET_LOADING,
    payload: loading,
  };
};

export const fetchCart = () => {
  return async function (dispatch, getState) {
    try {
      dispatch(setLoading(true));

      const token = localStorage.getItem("Yaliwe_Token");
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        language: lang||"en",
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`${baseurl}fetch-carts`, data, {
        headers,
      });

      if (response?.data?.status === "failed") {
        return;
      }
      dispatch({
        type: ActionTypes.FETCH_PRODUCTS,
        payload: response?.data,
      });
    } catch (error) {
      console.error("Error fetching cart data:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const removeFromCart = (id) => {
  return async function (dispatch, getState) {
    try {
      dispatch(setLoading(true));

      const token = localStorage.getItem("Yaliwe_Token");
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        cartId: id,
        language: lang || "en",
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`${baseurl}remove-to-cart`, data, {
        headers,
      });
      toast(response?.data?.message);
    } catch (error) {
      console.error("Error removing from cart:", error);
      toast("Error removing from cart");
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const addToCart = (productId, variationId) => {
  return async function (dispatch, getState) {
    try {
      dispatch(setLoading(true));

      const token = localStorage.getItem("Yaliwe_Token");
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        language: lang || "en",
        productId: productId,
        variationId: variationId || "",
        quantity: 1,
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`${baseurl}add-to-cart`, data, {
        headers,
      });
      console.log("add to cart", response);
      toast(response?.data?.message);
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast("Error adding to cart");
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateToCart = (cartId, productId, variationId, quantity) => {
  return async function (dispatch, getState) {
    try {
      const lang = localStorage.getItem("Yaliwe_language");
      dispatch(setLoading(true));

      const token = localStorage.getItem("Yaliwe_Token");
      const data = {
        cartId: cartId,
        productId: productId,
        variationId: variationId,
        quantity: quantity,
        language: lang || "en",
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`${baseurl}update-to-cart`, data, {
        headers,
      });
      console.log(response);
    } catch (error) {
      console.error("Error updating cart:", error.response.data.message);
      toast(error.response.data.message);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const emptyCart = () => {
  return {
    type: ActionTypes.EMPTY_PRODUCTS,
    payload: [],
  };
};
