import React, { useState } from "react";
import "../Styles/Navbar.css";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { RiAccountCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useSelector } from "react-redux";
import Sidebar1 from "../Sidebar/Sidebar1";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ToggleButton from "@mui/material/ToggleButton";
import LoginModal from "../Modal/LoginModal";
import { searchApi } from "../Apis/MainApis";
import { debounce } from "lodash";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";

function Navbar() {
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showSignin, setShowSignin] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state?.user?.user);
  const userid = useSelector((state) => state?.user?.user?.id);
  const wishlist = useSelector((state) => state?.wishlist?.wish);
  const cartdata = useSelector((state) => state?.cart?.products);

  const collectionData = useSelector(
    (state) => state?.homeproducts?.homeproducts
  );
  const navigate = useNavigate();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 1,
      top: 9,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      color: "white",
      backgroundColor: "green",
      size: "12px",
    },
  }));

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleSearchNavigate = (id, slug) => {
    navigate(`/product/${id}/${slug}`);
  };

  const handleNavigateCart = () => {
    if (userid === undefined) {
      setShowSignin(true);
      return;
    }
    navigate("/cart");
  };

  const handleNavigateWishlist = () => {
    if (userid === undefined) {
      setShowSignin(true);
      return;
    }
    navigate("/wishlist");
  };

  const closeModals = () => {
    setShowSignin(false);
  };

  const handleSearch = async (query) => {
    setLoading(true);
    if (query === "") {
      setSearchResults([]);
      return;
    }
    try {
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        language: lang || "en",
        keyword: query,
      };
      const response = await searchApi(data);
      setSearchResults(response?.data?.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
    setLoading(false);
  };

  const debouncedSearch = debounce((query) => handleSearch(query), 300);
  return (
    <>
      <LoginModal show={showSignin} onClose={closeModals} />
      <div className="navbar_background py-2">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div
              className="col-2 col-md-2 col-lg-1 col-xl-1 col-xxl-1 d-flex justify-content-start"
              onClick={() => navigate("/")}
            >
              <img
                className="navbar_icon"
                src="/yaliwe_footer_icon.png"
                alt="Navbar Icon"
              />
            </div>

            <div className="col-10 d-md-none navbar_toggle d-flex justify-content-end">
              <ToggleButton
                value="left"
                aria-label="left aligned"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              >
                <FormatAlignLeftIcon />
              </ToggleButton>
              <Sidebar1 isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
            </div>

            <div className="col-12 col-md-6 col-lg-8 col-xl-9 col-xxl-9 mt-3 mt-md-0  d-flex justify-content-center align-items-center">
              <div className="navbar_search w-100">
                <div className="d-flex justify-content-center align-items-center ">
                  <div className="search-bar">
                    <input
                      type="text"
                      placeholder={t("whatareyoulookingfor")}
                      onChange={(e) => debouncedSearch(e.target.value)}
                    />
                  </div>
                  <div className="navbar_searchicon">
                    <AiOutlineSearch />
                  </div>
                </div>
                {searchResults?.length > 0 && (
                  <div className="search_results">
                    {loading ? (
                      <div>{t("loading")}...</div>
                    ) : (
                      <ul>
                        {searchResults?.map((result) => (
                          <li
                            key={result.id}
                            onClick={() =>
                              handleSearchNavigate(result?.id, result?.slug_en)
                            }
                          >
                            {result?.name_en}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end navbar_right">
              {user?.length !== 0 ? (
                <div
                  className="navbar_account"
                  onClick={() => navigate("/account/profile")}
                >
                  <p className="text-white">
                    {/* <Avatar src={user.image} sx={{ width: 20, height: 20 }}/> */}
                    {/* <IconButton aria-label="account">
                      <RiAccountCircleLine />
                    </IconButton> */}
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      src={
                        user?.image
                          ? user?.image
                          : "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?size=626&ext=jpg&ga=GA1.1.87170709.1707523200&semt=ais"
                      }
                      alt="image"
                    />
                  </p>
                  <p className="navbar_space text-white mt-2">{t("account")}</p>
                </div>
              ) : (
                <div
                  className="navbar_account"
                  onClick={() => navigate("/login")}
                >
                  <p className="text-white">
                    <IconButton aria-label="account">
                      <PermIdentityIcon />
                    </IconButton>
                  </p>
                  <p className="navbar_space text-white">{t("login")}</p>
                </div>
              )}
              <div className="navbar_cart" onClick={handleNavigateCart}>
                <p className="text-white">
                  <IconButton aria-label="cart">
                    <StyledBadge
                      badgeContent={cartdata?.data?.length}
                      color="secondary"
                    >
                      <AiOutlineShoppingCart />
                    </StyledBadge>
                  </IconButton>
                </p>
                <p className="navbar_space text-white">{t("cart")}</p>
              </div>
              <div className="navbar_wishlist" onClick={handleNavigateWishlist}>
                <p className="text-white">
                  <IconButton aria-label="wishlist">
                    <StyledBadge badgeContent={wishlist?.length}>
                      <FavoriteBorderIcon />
                    </StyledBadge>
                  </IconButton>
                </p>
                <p className="navbar_space text-white">{t("wishlist")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
