// eslint-disable-next-line import/no-anonymous-default-export
export default {
  whatareyoulookingfor: "What are you looking for?",
  emailcannotbeempty: "Email cannot be empty",
  enteryourvalidemailaddress: "Enter your valid email address",
  newpassword: "New Password",
  enteryouremail: "Enter your email",
  generateotp: "Generate otp",
  passwordcannotbeempty: "Password cannot be empty",
  welcometoyaliwe: "Welcome To Yaliwe",
  logintocontinue: "Login To Continue....",
  login: "Login",
  enteryourpassword: "Enter your password",
  signup: "SignUp",
  donthaveanaccount: "Don't Have An Account?",
  forgotpassword: "Forgot Password ?",
  passwordmustbeatleast8charactersandincludeatleastonelowercaseletteroneuppercaseletteronedigitandonespecialcharacter:
    "Password must be at least 8 characters and include at least one lowercase letter, one uppercase letter, one digit, and one special character.",
  newpasswordandconfirmpasswordnotmatch:
    "New Password and Confirm Password not match",
  newpasswordcannotbeempty: "NewPassword cannot be empty",
  confirmpasswordcannotbeempty: "Confirm password cannot be empty",
  enteryournewpassword: "Enter your new password",
  confirmpassword: "Confirm Password",
  confirmnewpasswordpassword: "Confirm new password",
  submit: "Submit",
  enterotp: "Enter Otp",
  verifyotp: "Verify Otp",
  usernameshouldnotcontainanyspecialcharacterdigitspace:
    "username should not contain any special character,digit,space",
  emailisnotvalid: "Email is not valid",
  passworddonotmatch: "Passwords do not match",
  usernamecannotbeempty: "Username cannot be empty",
  usernameshouldbegreaterthanfour: "Username length should be greater than 4",
  signuptoregister: "SignUp To Register",
  username: "Username",
  email: "Email",
  password: "Password",
  alreadyhaveanaccount: "Already Have An Account?",
  uploadimage: "Upload Image",
  myaccount: "MY ACCOUNT",
  myprofile: "My Profile",
  changepassword: "Change Password",
  deleteaccount: "Delete Account",
  mystuff: "MY STUFF",
  myorders: "My Orders",
  myaddresses: "My Addresses",
  mywishlist: " My Wishlist",
  mycart: "My Cart",
  legal: "LEGAL",
  faq: "Faq",
  termsandcondition: "Terms and Conditions",
  legalnotice: "Legal Notice",
  logout: "LOGOUT",
  banner1:
    " Explore our wide range of construction equipment designed to meet the highest standards of durability and performance.Whether you're a professional contractor or a DIY enthusiast,find the tools you need to get the job done efficiently.",
  banner2:
    " Explore our latest electronic collections, featuring cutting-edge technology and innovative designs. From smart gadgets to home appliances, discover the best in electronic advancements.",
  banner3:
    "Transform your look with our premium hair wig collections.Choose from a variety of styles and colors to express your unique personality. Experience the confidence that comes with a perfect hair transformation.",
  bestselling: "Best Selling",
  products: "Products",
  bestproducts:
    "Explore our handpicked selection of top-rated products. Each item is carefully curated to bring you the best in quality, style, and innovation. Discover a world of excellence as you browse through our collection of must-have products. Elevate your lifestyle with our best-selling items that redefine luxury and functionality.",
  categories: "Categories",
  home: "HOME",
  hardtofind: "HARD TO FIND",
  yourcartisempty: "Your Cart Is Empty",
  product: "Product",
  price: "Price",
  subtotal: "Subtotal",
  remove: "Remove",
  coupons: "Coupons",
  apply: "Apply",
  checkout: "Checkout",
  total: "Total",
  filters: "Filters",
  clearall: "Clear All",
  prices: "Prices",
  clear: "Clear",
  min: "Min",
  max: "Max",
  brands: "Brands",
  reviews: "Reviews",
  noitemfound: "No Item Found",
  checkoutitems: "Checkout Items",
  thereisnoitemsforcheckout: "There is no items for checkout",
  gotohomepage: "Go to home page",
  discount: "Discount",
  selectdeliveryaddress: "Select Delivery Address",
  addnew: "Add New",
  pleaseaddnewaddress: "Please add new address",
  edit: "Edit",
  selectpaymentoption: "Select Payment Option",
  paypal: "Paypal",
  stripe: "Stripe",
  proceedtopay: "Proceed to pay",
  contactus: "Contact Us",
  ourmainoffice: "Our Main Office",
  phonenumber: "Phone Number",
  enteryourname: "Enter Your Name",
  enteravalidemailaddress: "Enter a valid email address",
  message: "Message",
  whatisyaliwe: "What is Yaliwe",
  It_is_the_first_platform_of_its_kind_in_the_region_to_offer:
    "It is the first platform of its kind in the region to offer you an easy way to purchase products from all corners of the world, certified and guaranteed at an affordable price.",
  At_Yaliwe_you_BUY_with_peace_of_mind_Our_main_aim_is_to_keep:
    "At Yaliwe, you BUY with peace of mind. Our main aim is to keep our customers happy and satisfied with their purchased products and that is why we provide the following: Quality - All products go through intensive quality checks by our certified experts. Warranty – Most products come with a 3-12 month warranty.",
  _How_long_does_the_delivery_take: "How long does the delivery take",
  After_your_order_once_payment_has_been_made_Your_item_should:
    "After your order, once payment has been made? Your item should be delivered within approximately 6-14 business days, this time can be modified depending on the item ordered and your city of residence.",
  What_should_I_do_if_I_am_not_satisfied_with_the_product:
    " What should I do if I am not satisfied with the product received",
  youcancontactusat: "You can contact us at",
  howtoreturnanitem: "How to return an item",
  _Return_cancellation_replacement_and_refund_policy_You_can:
    " Return, cancellation, replacement and refund policy .You can initiate a return/cancellation procedure for a product that is not on the list of non-returnable products and if you have received:",
  an_incorrect_product:
    "An incorrect product (if the product has not been used,includes all labels and is in its original, undamaged packaging).",
  adamagedproduct:
    "A damaged/defective product (for electronic devices, opened products will be accepted if they differ from the description or image shown and include all tags and in original, undamaged packaging).",
  In_the_event_that_you_need_to_return_your_item_for_any_reason:
    "In the event that you need to return your item for any reason,we will only accept a return under certain conditions:",
  The_products_are_in_their_original_retail_packaging:
    "The products are in their original retail packaging (sealed/closed box) and are undamaged or altered in any condition. The item seal must be in undamaged condition.",
  _For_watches_the_product_must_be_in_its_original_packaging_and:
    "For watches, the product must be in its original packaging and have all original labels.",
  _For_all_other_products_returns_are_accepted_if_the_product_is:
    "For all other products, returns are accepted if the product is in its original intact packaging and includes all accessories.",
  _If_the_packaging_has_minor_soiling_or_is_opened_and_the:
    "If the packaging has minor soiling or is opened and the product is not defective, has not been used, has no visible scratches or marks observed, a restocking fee of 30 % (percentage) will be deducted upon exchange/refund.",
  You_have_the_right_to_return_a_product_within_ten:
    "You have the right to return a product within ten (10) days of receipt of shipment. It is the customer who takes care of the costs of returning the product.",
  nonreturnableproduct: "Non-returnable products.",
  Products_that_you_have_used_or_damaged_or_which_are_not_in_the:
    "Products that you have used or damaged or which are not in the same condition as you received them;",
  _Any_consumable_product_that_has_been_used_or_installed:
    " Any consumable product that has been used or installed;",
  _Products_with_falsified_or_missing_serial_numbers:
    "Products with falsified or missing serial numbers",
  reimbursementprocedure: "Reimbursement procedure",
  wewillrefund:
    "We will refund you based on the payment method you used, as follows:",
  ifyoupaidby:
    " If you paid by credit/debit card, refunds will only be made to the original payment method.",
  ifyoupaidbycod:
    "If you paid by COD, we will issue a refund to your wallet/bank transfer;",
  formoreinformation:
    " Contact Us: For more information, see our Terms of Sale, email our support team at",
  featuredcategories: "Featured Categories",
  mobilenoshouldbebetween8to12digits:
    "Mobile no should be between 8 to 12 digits",
  datasubmittedsuccessfully: "Data submitted successffully",
  emailisrequired: "Email is required",
  phoneisrequired: "Phone is required",
  usernameisrequired: "Username is required",
  textareaisrequired: "Text area is required",
  brandisrequired: "Brand is required",
  modelnameisrequired: "Modelname is required",
  hardtofindproducts: "Hard to Find Products",
  havingtrouble: "Having Trouble finding a certain part",
  whetherthepartisobsolute:
    "Whether the part is obsolute,discontinued or just not real common, with the proper information from you , our team members can track down most items that no one seems to supply .",
  pleasefilltheform:
    "Please fill the form with as much as information you have . We will take care of the rest.",
  iteminformation: "Item Information",
  brand: "Brand",
  model: "Model",
  partno: "Part No",
  personalinformation: "Personal Information",
  name: "Name",
  phone: "Phone",
  comments: "Comments",
  sendrequest: "Send request",
  vendorlogin: "Vendor Login",
  becomeavendor: "Become a Vendor",
  partnerwithus: "Partner with us",
  address: "Address",
  UAE: "AL Nahda 2,Dubai,UAE",
  whatsapp: "Whatsapp",
  company: "Company",
  termsandconditions: "Terms And Conditions",
  pages: "Pages",
  viewcart: "View Cart",
  installapp: "Install App",
  fromapp: "From App Store Or Google Play",
  securedpayment: "Secured Payment Gateway",
  hairperfector: "Hair Perfector",
  forstylish: "For Stylish Hair Wig Products",
  shopcollectionnow: "Shop Collection Now",
  pleaseread:
    "Please read carefully the different terms of use of this site before browsing.its pages. By connecting to this site, you accept, without reservation, these terms and conditions.",
  alsoinaccordance:
    "Also, in accordance with article n°6 of Law n°2004-575 of June 21, 2004 for confidence in the digital economy Mentions to note from this website are:",
  termsofuse: "Terms of use :",
  thissite: "This Site",
  offeredindifferent:
    "is offered in different web languages (HTML, HTML5, Javascript, CSS, etc.) for greater comfort of use and more pleasant graphics.",
  werecommendthat:
    " We recommend that you use modern browsers such as Internet Explorer, Safari, Firefox, Google Chrome, etc.",
  yaliweimplements:
    "Yaliwe implements all the means at its disposal to ensure reliable information and reliable updating of its website.",
  howevererrors:
    " However, errors or omissions may occur. The Internet user must therefore ensure the accuracy of the information with a yaliwe or UBS GROUP agent in the Contact section or on www.ubslogistic.com, and report any modifications to the site that they deem useful. Yaliwe is in no way responsible for misuse of this information, and for any direct or indirect harm that may result.",
  cookies: "Cookies :",
  mayask:
    "may ask you to accept cookies for statistical and display purposes. A cookie is information stored on your hard drive by the server of the site you are visiting.",
  itcontains:
    "It contains several pieces of data that are stored on your computer in a simple text file that a server accesses to read and save information. Certain parts of this site cannot be functional without accepting cookies.",
  hypertextlinks: "Hypertext links:",
  thissitemayoffer:
    " The site may offer links to other websites or other resources available on the Internet.Users, subscribers and visitors to the site cannot set up a hyperlink to this site without the express prior authorization of Yaliwe.",
  inthevent:
    " In the event that a user or visitor wishes to set up a hyperlink to a website, it will be up to them to send an email accessible on the site in order to formulate your request to set up a hyperlink.",
  yaliwereserves:
    " yaliwe reserves the right to accept or refuse a hyperlink without having to justify its decision",
  servicesprovided: "Services provided:",
  allofthecompany:
    "All of the company's activities as well as its information are presented on our site",
  theyaregiven:
    "They are given subject to modifications having been made since they were put online. Furthermore, all information indicated on the site is given for information purposes only, and is likely to change or evolve over time.",
  contractual: " Contractual limitation on data:",
  theinformationcontained:
    "The information contained on this site is as accurate as possible and the site updated at different times of the year,to avoid inaccuracies or omissions.",
  ifyounoticeagap:
    "If you notice a gap, error or what appears to be a malfunction, please report it by email to support@yaliwe.com ,or to the contact section of the site,by describing the problem as precisely as possible (page causing the problem,type of computer and browser used, etc.).",
  inaddition:
    "In addition, the user of the site undertakes to access the site using recent equipment, not containing viruses and with an updated latest generation browser.",
  intellectualproperty: "Intellectual property :",
  allcontentofthis:
    "All content of this site, including, without limitation, graphics, images, texts, videos, animations, sounds, logos gifs and icons as well as their formatting are the exclusive property of the company with the exception of brands, logos or content belonging to other partner companies or authors.",
  anyreproduction:
    "Any reproduction, distribution, modification, adaptation,retransmission or publication, even partial, of these various elements is strictly prohibited without the express written consent.",
  disputes: "Disputes:",
  theseconditionsofthesite: "These conditions of the site",
  aregovernedby:
    "are governed bythe laws of the United Arab Emirates,and any dispute orlitigation which could arise from the interpretation or execution of these will be under the exclusive jurisdiction of the courts on which the company's head office depends. The reference languages for settling any disputes are French and English.",
  personaldata: "Personal data :",
  generallyspeaking:
    "Generally speaking, you are not required to provide us with your personal data when you visit our website",
  howeverthisprinciple:
    "However, this principle has certain exceptions. Indeed, for certain services offered through our site, or to place an order, you may be required to provide us with certain data such as: your name, your position, the name of your company, your address electronic mail, and your telephone number. This is the case when you complete the form offered to you online,in the “ contact” section.",
  inanycase:
    " In any case, you can refuse to provide your personal data. In this case, you will not be able to use the services of the site, in particular that of requesting information about our company, or receiving newsletters.",
  finallywe:
    "Finally, we may automatically collect certain information about you during a simple navigation on our website, in particular: information concerning the use of our site, such as the areas which you visit and the services to which you access, your IP address, the type of your browser, your access times.",
  suchinformation:
    "Such information is used exclusively for internal statistical purposes, in order to to improve the quality of the services offered to you. Databases are protected.",
  youraddresses: "Your Addresses",
  youhavenotaddedanyaddressyet: "Your have not added any address yet",
  update: "Update",
  yourcoupons: "Your Coupons",
  youhavenocoupons: "Your have no coupons",
  orderid: "Order Id",
  transactionid: "Transaction Id",
  paymenttype: "Payment Type",
  totalamount: "Total Amount",
  actions: "Actions",
  youhavenotorderanythingyet: "Your have not order anything yet",
  view: "View",
  loading: "Loading",
  account: "Account",
  cart: "Cart",
  wishlist: "Wishlist",
  totalprice: "Total Price",
  ratingfeedbackandmessagerequired: "Rating,Feedback and Message required",
  reviewdeletedsuccessfully: "Review deleted successfully",
  addtocart: "Add To Cart",
  youcannotuploadmorethan3images: "You cannot upload more than 3 images",
  originals: "100% original products",
  getitby: "Get It Buy",
  payonline: "Pay Online",
  easyreturn: "Easy 4 Days Return & Exchange Available",
  aboutyaliwe: "About Yaliwe",
  aboutyaliwepara:
    "Yaliwe is the first platform of its kind in the region to offer you an easy way to purchase products from all corners of the world, certified and guaranteed at an affordable price.",
  productdescription: "Product Description",
  topreviews: "Top Reviews",
  noreviews: "No Reviews to show",
  delete: "Delete",
  addyourreview: "Add Your Review",
  enteryourfeedback: "Enter Your Feedback",
  namefieldisrequired: "Name field is required",
  phonenumbershouldbebetween8to12digits:
    "Phone no must be between 8 to 12 digits",
  profileupdated: "Profile Updated",
  editprofile: "Edit Profile",
  mobilenumber: "Mobile number",
  emailaddress: "Email Address",
  savedetails: "Save Details",
  addaddress: "Add address",
  mobile: "Mobile",
  country: "Country",
  state: "State",
  city: "City",
  street: "Street",
  landmark: "Landmark",
  pincode: "Pincode",
  addresstype: "Address Type",
  office: "Office",
  setasmydefaultaddress: "Set as my default address",
  saveaddress: "Save Address",
  aboutus: "ABOUT US",
  foundedin:
    "Founded in 2023, and a subsidiary of UBS (Unique Business Services), Yaliwe is a platform for online sale. The best place to buy and sell things in Africa, from from the four corners of the world (Dubai, China, Turkey, Africa, Europe,America). Our mission is to support the public in the purchase and delivery of products for use personal or commercial in their daily operations thanks to a sales and fast delivery,via our website and our mobile application.",
  order: "ORDER",
  whetherforthe:
    "Whether for the delivery of cosmetics, household products,household appliances, electronics, mechanical, automotive, or industrial and many others… our dynamic community, composed of suppliers and intermediaries and honest retailers is always ready to help you deliver your orders. As an intermediation and product delivery service, yaliwe is well established in Africa. Our personalized delivery service meets all your needs in purchasing matters at very competitive prices.",
  delivery: "DELIVERY",
  toensure:
    "To ensure rapid delivery of your order, a delivery person will be assigned to you upon receipt. of your order. Within 3 to 7 days in AFRICA and 10 to 15 days in Europe and America depending on your place of residence, Yaliwe works closely with several Delivery Agencies.",
  customerservice: "CUSTOMER SERVICE",
  ourtelephone:
    "  Our telephone tracking service is just as simple! you have free access to our personalized information service by telephone. A member of our courtesy team will guide you step by step to follow up online. Whatsapp numbers are available for during and after-sales service. 24/7",
  navigate: "NAVIGATE",
  yaliweallows:
    "Yaliwe allows you to make an informed choice among hundreds of products. When you open the site or the App, you can search for a product and place your order safely quietude.",
  tracking: "TRACKING",
  whentheorder:
    "When the order is placed, a customer service member will contact you to confirm the details of the order received, then a delivery person will be sent to deliver your order.",
  termsofservice: "Terms of Service",
  keyprinciples: "KEY PRINCIPLES",
  thesegeneral:
    "These general conditions (“Terms”) apply to the mobile applications and/or websites of",
  theweb:
    "The web and mobile applications allow you to order products from various suppliers and intermediaries or directly from UNIQUE BUSINESS SERVICE and then delivered. For more information, please see this page.",
  foryaliweproducts:
    "For Yaliwe products and delivery service , you are bound by a contract directly with UNIQUE BUSINESS SERVICE. Yaliwe acts as a platform intermediation technology to enable you to request and receive products, also as a purchasing and delivery center and guarantees you the good execution under the conditions provided for in this information.",
  forproductsand:
    "For products and delivery service provided by Yaliwe, you may be asked to agree to separate terms that govern your use of its products and the delivery service.",
  pleasereadtheseterms:
    "Please read these Terms carefully. They include information about the products and delivery service and your rights, as well as other information important.",
  definitions: "DEFINITIONS",
  suppliersandintermediary:
    " Suppliers and Intermediary Service Providers are independent intermediaries, including independent transport providers,delivery partners, product suppliers, logistics providers and/or providers of other products and the mobility delivery service that use mobile applications and/or les sites internet de Yaliwe.",
  theproductsand:
    "The products and delivery service provided by Yaliwe mean the products and the delivery service which are provided to you directly by Yaliwe and which can be subject to separate conditions.",
  weourteamdesignates:
    "We, Our team designates the company names, logos, product names or products and delivery service, product brands,commercial presentations, other proprietary signs or copyrights of Yaliwe or its licensor.",
  conditions: "Conditions",
  resume: "Resume",
  thesegeneralconditions:
    "These general conditions govern your use of the applications mobile and/or websites of Yaliwe the Conditions. You must read carefully and accept these conditions before any creation of your customer account via the mobile applications and/or Yaliwe websites , the creation of a customer account being a mandatory prerequisite to be able to request the products and delivery service provided by Yaliwe or intermediary products and delivery service. Your acceptance of These Terms establish a contractual relationship between you and Yaliwe. THE These Terms expressly supersede any prior agreements or arrangements between you and Yaliwe regarding the products and the delivery service App(s)/Website(s) (as defined below).",
  theseconditions:
    "These Conditions apply to the extent that they do not come into force conflict with the Terms of Use and any additional terms that apply specifically to Yaliwe mobile applications and/or websites . It will be to you asked to accept this type of conditions separately.",
  yaliweacts:
    "Yaliwe acts as a technological intermediation platform for products and the intermediary delivery service and as a service provider for the products and the delivery service provided. As soon as the products and the delivery service transport intermediaries are accessible via mobile applications and/or website Yaliwe internet ensures the proper execution of said products and the service of transport delivery by Intermediary Service Providers.",
  productsanddelivery: "Products and delivery service App(s)/Website(s)",
  theproductsanddelivery:
    "The products and delivery service provided to you by Yaliwe include:",
  accessanduse:
    "Access and use of mobile applications and/or websites from Yaliwe which allow you to request and receive products and service from delivery of intermediary and/or products and delivery service provided by Yaliwe",
  productsandservice:
    "The products and service of delivery, collection of information and processing payments (including issuing invoices and receipts on behalf of Intermediary service providers) allowing us to invoice you and remit the amounts paid to the bank account of suppliers, service providers and intermediaries for products and delivery service of intermediary or to the bank account of Yaliwe for the products and delivery service provided by, Yaliwe may have these products and the delivery service on its behalf by affiliated companies or intermediary)(collectively the “Products and Delivery Service App(s)/Website(s)",
  thesetermsgovern:
    "These Terms govern your use of the Products and the Service. delivery App(s)/Website(s) in AFRICA.",
  yaliwemobileapplication:
    "Yaliwe's mobile applications and/or websites will be made available to you under different Yaliwe Brands.",
  theproductsanddeliveryapp:
    "The products and delivery service App(s)/Website(s) are made available to you for your personal use or for commercial use",
  youcanrequest:
    "You can request and receive products and delivery service intermediary or products and delivery serviceprovided by Yaliwe via the mobile applications and/or the Yaliwe website.",
  productsandintermediary: "Products and intermediary delivery service",
  yaliweactas:
    "Yaliwe acts as a technological intermediation platform between you and the Intermediary suppliers to enable them to supply their products to you. All the Intermediary service providers are independent intermediary entrepreneurs,commercial companies or other types of businesses and are not employed by Yaliwe.",
  useofproducts: "Use of products and delivery service App(s)/Site(s) Web",
  theyaliwemobileapplication:
    "The Yaliwe mobile applications and/or website can be downloaded or accessible on most modern mobile devices with a connection internet and equipped with popular operating systems such as Android or iOS.",
  youareresponsible:
    "You are responsible for obtaining network access necessary to use the Products and the App(s)/Website(s) delivery service and all rates and charges from your delivery provider mobile network are your responsibility,including for data relating to the use mobile applications and/or the Yaliwe website . It is also your responsibility to access compatible devices and operating systems necessary to the use of the products and the delivery service App(s)/Website(s), including updates software or hardware updates.",
  inordertouse:
    "In order to use the products and delivery service App(s)/Website(s), you must register in the mobile applications and/or on the Yaliwe website and create an account. At the time of registration, you must be the age of majority legal in Cameroon or in your country of residence.",
  youmustprovide:
    "You must provide us with certain personal information to register, including your telephone number and email address. To use the products and the App(s)/Website(s) delivery service, products and delivery service provided by Yaliwe or certain products and intermediary delivery service, perhaps indicate at least one valid means of payment (credit card, debit card, you should mobile money or any other accepted payment method).You will have to provide information, such as your address and additional documents before using certain products and the delivery service provided by Yaliwe or certain products and the middleman delivery service.",
  youarenotrequired:
    "You are not required to log in or use the mobile applications and/or the Yaliwe website , and the Intermediary Service Providers are not either. If you choose to stop using the mobile applications and/or the website of Yaliwe, you can do this without informing us.",
  wemaytemporarily:
    "We may temporarily restrict your access and use of the products and delivery service App(s)/Website(s) in case of alleged violation of your obligations, “Use of the products and the delivery service App(s)/Websites”, “ your obligations”, “Fees and payment”, “Intellectual property”,appearing within the these Terms, including when we receive a complaint relating to an act fraudulent, for a period which will not exceed the time necessary for verification information relating to the alleged violation of said obligations. In certain circumstances, we may not be able to provide you with information about the complaint while an investigation is underway (by us and/or an intermediary like the police).",
  yourobligations: "Your obligations",
  whenyouusetheproducts:
    "When you use the products and delivery service App(s)/Website(s), you must comply with all applicable legislation. You cannot use the products and the delivery service App(s)/Website(s) only for lawful purposes and for which they were designed, as set out in these Terms. You do not must not misuse mobile applications and/or websites of Yaliwe or attempt to commit a fraudulent act against us or against Intermediary service providers.",
  youmustprovideaccurate:
    "You must provide accurate and complete information when you register an account and when you use the products and delivery service App(s)/Website(s).",
  youraccountispersonal:
    "Your account is personal. It may not be rented or shared unless authorized express de Yaliwe. Once you have created an account:",
  youmaynotregister:
    "You may not register more than one account unless otherwise agreed in writing by our part ;",
  youcannotauthorize:
    "You cannot authorize intermediaries to use your account, nor transfer your account to an intermediary",
  youmustensure:
    "You must ensure that your account information is accurate, complete and up to date;",
  youmustnotdisclose:
    "You must not disclose your confidential login information;",
  youmustinformus:
    "You must inform us immediately if you have reason to believe that someone else knows your username or password or if you suspect that someone else is using your account on the Contact Us Button",
  youwillberesponsible:
    "You will be responsible for paying all charges that may be due as a result of the supply of products and the delivery service of intermediary or products and the delivery service provided by Yaliwe requested through your account, unless access and use of your account results from a case of force majeure, due to of an intermediary presenting the characteristics of force majeure (example:hacking, computer hacking) or even a failure by Yaliwe to fulfill its obligation to security. In the event of theft of banking or mobile money information, you are required to immediately notify your banking establishment in order to carry out the necessary steps.",
  youshallnot:
    "You shall not, in your use of the Products and the Delivery Service App(s)/Website(s), cause nuisance,problems, inconvenience or material damage, whether to the intermediary Service Provider, to Yaliwe or to any other person.",
  feesrelatedtotheproducts:
    "Fees related to the products and the intermediary and product delivery service and delivery service Provided by",
  ifyoumakeasolicitation:
    "If you make a solicitation using mobile applications and/or sites Yaliwe internet , you agree to pay the applicable fees. You will be in charge of all costs linked to your account under the conditions described in article 6.4 above.",
  dependingontheservice:
    "Depending on the Service provided by Yaliwe or the Intermediary Service used and depending in particular the place where it is provided, the total amount of the costs relating to your request may be calculated and displayed on the mobile applications and/or the Yaliwe website before until you confirm your order. Failing this, particularly in the case of products and the transport delivery service provided by the intermediary Service Providers in In some cities, charges depend on your usage of the products and service intermediary delivery or products and delivery service provided by Yaliwe (by example, time and/or distance traveled). In this case, the price list applicable will be communicated to you in the mobile applications and/or on the website from Yaliwe before order confirmation. An estimate of these costs can be displayed in mobile applications and/or on Yaliwe websites , for information purposes only uniquely.",
  iftheproductsanddelivery:
    "If the products and delivery service of intermediary or the products and service of delivery Provided by Yaliwe cannot be provided due to an act or inaction on your part (for example, if you are not present at the place of delivery to receive a delivery), all costs may be billed to you, unless if you justify that it is not due to an act or inaction on your part that the products and delivery service provided could not be provided.",
  oncetheintermediary:
    "Once the Intermediary Service or the Service provided by Yaliwe has been provided, we can issue you an invoice directly or in the name of the Service Provider intermediary, as appropriate.",
  feesaresubjecttochange:
    "Fees are subject to change in the mobile applications and/or on Yaliwe websites . You will be informed in advance of any modification of the fees by email or notification on mobile applications and/or on websites from Yaliwe. You will need to accept these changes in order to continue using them. If you do not accept them, you must no longer use the products and services of App(s)/Web delivery.",
  cancellation: "Cancellation",
  youhavetheright:
    "You have the right to cancel an order, before it is shipped within 24 hours within where applicable, Yaliwe will not charge you cancellation fees.",
  ifyoupurchase:
    "If you purchase an item on Yaliwe, you can exercise your right of withdrawal of the order from this Yaliwe within 24 hours following the date of your purchase. Yaliwe will provide you with all the information and will reimburse you for your order.",
  wecollectinformation:
    "We collect information from or about you. Of the Additional privacy statements may apply, including those intermediaries responsible for processing, for the products and the delivery service Provided by Yaliwe or intermediaries' products and delivery service.",
  yourresponsibilities: "Your responsibilities",
  youraresponsible:
    "You are responsible for any damages resulting from your violation of these Conditions, your misuse of the products and the delivery service App(s)/Website(s) or your violation of any legal provision or the rights of a intermediate. It is your responsibility to make and keep copies of the data available on your account that you need (e.g. invoices).",
  limitationofliability: "Limitation of liability",
  ifyouactas:
    "If you act as a consumer, your contractual liability does not cannot be incurred for unforeseeable damage on the day of conclusion of the contract. contract, related to the use of the products and the delivery service App(s)/Website(s).",
  forallproduct:
    "For all product users and the App(s)/Site(s) delivery service Web does not act as a consumer, our responsibility cannot be incurred for indirect damages including: (i) loss of profits; (ii) loss of turnover; (iii) loss of agreements or contracts; (iv) loss of expected savings ; (v) loss of data or information and (vi) loss of goodwill.",
  wearenot:
    "We are not responsible for delays or failures in performance resulting from a case of force majeure.",
  yaliweiscommitted:
    "Yaliwe is committed to the consumer to provide products and service quality App(s)/Website(s). The Intermediary Service Provider is required to comply with the obligations arising from the supply of products and service intermediary delivery and in particular the quality of the products and the delivery service (quality, adequacy).",
  yaliweisdirectlyliable:
    "Yaliwe is directly liable in whole or in part in the event of of non-execution or poor execution of the products and the delivery service when this non-performance or poor performance is not attributable to you, except when this non-execution or poor execution results from an unforeseeable and insurmountable fact of a intermediary in the supply of products and the delivery service, or in the event of force majeure.",
  termination: "Termination",
  youarefree:
    "You are free at any time to use the products and the delivery service App(s)/Website(s) (if applicable) and you may terminate these Terms and the products and delivery service App(s)/Website(s) by closing your account.",
  yaliwemayterminate:
    "Yaliwe may terminate these Terms or discontinue the Products and the delivery service App(s)/Website(s) for a period which will not exceed the duration necessary to verify information relating to breaches, in the event of violation of the law, these General Conditions or the Charter of the Community, by informing you in advance in writing within a reasonable time.",
  general: "General",
  yaliwereservestheright:
    "Yaliwe reserves the right to modify these Conditions.We you will inform you of these modifications by email before their entry into force within a period reasonable.You will be bound by these changes once you accept them in mobile applications and/or on the Yaliwe website and/or by email, where applicable applicable. If you do not agree with these changes, you are free to close your account.",
  wemayassign:
    "We may assign, subcontract or transfer these Terms or any any of our rights or obligations relating thereto, in whole or in part, without your prior consent, provided that this does not result in a reduction of rights which you have under these Terms or the law. You can not assign, subcontract or transfer these Terms or any of our rights or obligations, in whole or in part, because your use of the mobile applications and/or the site Yaliwe 's internet is personal, unless otherwise agreed.",
  theseconditionstogether:
    "These Conditions, together with any additional agreements relating to the supply products and the delivery service App(s)/Website(s), constitute the entirety of our agreement with you and supersede all previous agreements relating to your access and your use of the products and the delivery service App(s)/Website(s).",
  youmaybesasked:
    "You may be asked to agree to additional terms to access or use the products and delivery service App(s)/Website(s). In the event of a conflict between these Conditions and a separate agreement, the latter will prevail, unless stipulated otherwise in the separate agreement.",
  disputeresolutionprocedure: "Dispute resolution procedure",
  yaliwemaymakeavailable:
    "Yaliwe may make available to users acting as consumers mediation system for product-related consumer disputes and the App(s)/Website(s) delivery service or these Terms for their amicable resolution.",
  applicablelawandcompetent: "Applicable law and competent jurisdiction",
  thesetermswillbe:
    "These Terms will be exclusively governed and construed in accordance with the Ivory Coast law. By accepting these Terms, you agree to be bound by them.",
  operatebyubslogistic: "Operate By UBS Logistic",
  ordersummary: "Order Summary",
  quantity: "Quantity",
  status: "Status",
  deliveredto: "Delivered To",
  ordercancelled: "Ordered Cancelled",
  cancelorder: "Cancel Order",
  trackorder: "Track Order",
  yourwishlistisempty: "Your wishlist is empty",
  updateprofile: "Update Profile",
  doyou:
    " Do you really want to delete your account ? This action is irreversible .",
  confirm: "Confirm",
  imagepreview: "Image Preview",
  passwordisrequired: "Password is required",
  lognin: "LogIn",
  addnewaddress: "Add New Address",
  add: "Add",
  oldpasswordcannotbeempty: "Old password cannot be empty",
  changepasssword: "Change Password",
  oldpasssword: "Old Password",
  newpasssword: "New Password",
  confirmpasssword: "Confirm Password",
  orderconfirmed: "Order Confirmed",
  orderdispatch: "Order Dispatch",
  orderdelivered: "Order Delivered",
  blog1heading: "AUDIO AND VIDEO",
  blog1text:
    "You Will Find Here Everything You Need in Audio and Video Equipment’s, Even The Most Advanced.",
  blog2heading: "LAPTOPS AND WIFI MODEM",
  blog2text:
    "Browse Our Computers According To Your Tastes All Brands As Well As Wi-Fi Modems Are At Your Disposal.",
  blog3heading: "ROUTERS AND SERVERS",
  blog3text:
    "Take advantage of our offers on the latest technologies and new generation equipment. Order with Ease",
  ourlatest: "Our Latest",
  blog:'Blog',
  paymentsuccess:'Payment Success',
  wereceivedyourpurchaserequest:"We received your purchase request",
  wewillbeintouchshortly:"We will be in touch shortly",
  paymentfailed:"Payment Failed",
  pleasetryagainlater:"Please Try Again Later"
};
