import React from "react";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { FaQuora } from "react-icons/fa6";
import "../Styles/Faq.css";

function Faq() {
  const { t } = useTranslation();
  return (
    <>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="container faq_container py-5">
        <div className="row">
          <div className="col-12 col-lg-12 mb-3">
            <img src="FAQ.png" className="faq_image" alt="faqImage"></img>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 mt-5 mb-3">
            <div className="faq_heading text-start">
              Frequently Asked Questions (FAQ)
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row">
            <MDBAccordion>
              <MDBAccordionItem
                collapseId="gfg1"
                headerTitle={
                  <>
                    <FaQuora />
                    &nbsp; {t("whatisyaliwe")}?
                  </>
                }
              >
                <p className="text-start">
                  {t(
                    "At_Yaliwe_you_BUY_with_peace_of_mind_Our_main_aim_is_to_keep"
                  )}
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId="gfg2"
                headerTitle={
                  <>
                    <FaQuora />
                    &nbsp; {t("_How_long_does_the_delivery_take")}?
                  </>
                }
              >
                <p className="text-start">
                  {t(
                    "After_your_order_once_payment_has_been_made_Your_item_should"
                  )}
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId="gfg3"
                headerTitle={
                  <>
                    <FaQuora />
                    &nbsp;{" "}
                    {t(
                      "What_should_I_do_if_I_am_not_satisfied_with_the_product"
                    )}
                    ?
                  </>
                }
              >
                <p className="text-start">
                  {t("youcancontactusat")}{" "}
                  <a href="mailto:support@yaliwe.com?body=My custom mail body">
                    support@yaliwe.com
                  </a>
                </p>
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId="gfg4"
                headerTitle={
                  <>
                    <FaQuora />
                    &nbsp; {t("howtoreturnanitem")}?
                  </>
                }
              >
                <p className="text-start">
                  {t(
                    "_Return_cancellation_replacement_and_refund_policy_You_can"
                  )}
                </p>
                <p className="text-start">{t("an_incorrect_product")}</p>
                <p className="text-start">{t("adamagedproduct")}</p>
                <p className="text-start">
                  {t(
                    "In_the_event_that_you_need_to_return_your_item_for_any_reason"
                  )}
                </p>
                <p className="text-start">
                  {t("The_products_are_in_their_original_retail_packaging")}
                </p>
                <p className="text-start">
                  {t(
                    "_For_watches_the_product_must_be_in_its_original_packaging_and"
                  )}
                </p>
                <p className="text-start">
                  {t(
                    "_For_all_other_products_returns_are_accepted_if_the_product_is"
                  )}
                </p>
                <p className="text-start">
                  {t(
                    "_If_the_packaging_has_minor_soiling_or_is_opened_and_the"
                  )}
                </p>
                <p className="text-start">
                  {t("You_have_the_right_to_return_a_product_within_ten")}
                </p>
                <h6 className="text-start fw-bold">
                  {t("nonreturnableproduct")}
                </h6>
                <p className="text-start">
                  {t(
                    "Products_that_you_have_used_or_damaged_or_which_are_not_in_the"
                  )}
                </p>
                <p className="text-start">
                  {t(
                    "Products_that_you_have_used_or_damaged_or_which_are_not_in_the"
                  )}
                </p>
                <p className="text-start">
                  {t("_Products_with_falsified_or_missing_serial_numbers")}
                </p>
                <h6 className="text-start fw-bold">
                  {t("reimbursementprocedure")}
                </h6>
                <p className="text-start">{t("wewillrefund")}</p>
                <p className="text-start">{t("ifyoupaidby")}</p>
                <p className="text-start">{t("ifyoupaidbycod")}</p>
                <p className="text-start">
                  {t("formoreinformation")}{" "}
                  <a href="mailto:support@yaliwe.com?body=My custom mail body">
                    support@yaliwe.com
                  </a>
                </p>
              </MDBAccordionItem>
            </MDBAccordion>
          </div>
        </div>
      </div>

      {/* <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-4">
                <h6 className="text-start fw-bold">{t("whatisyaliwe")}?</h6>
                <p className="text-start">
                  {t(
                    "At_Yaliwe_you_BUY_with_peace_of_mind_Our_main_aim_is_to_keep"
                  )}
                </p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">
                  {t("_How_long_does_the_delivery_take")}?
                </h6>
                <p className="text-start">
                  {t(
                    "After_your_order_once_payment_has_been_made_Your_item_should"
                  )}
                </p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">
                  {t("What_should_I_do_if_I_am_not_satisfied_with_the_product")}
                  ?
                </h6>
                <p className="text-start">
                  {t("youcancontactusat")}{" "}
                  <a href="mailto:support@yaliwe.com?body=My custom mail body">
                    support@yaliwe.com
                  </a>
                </p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">
                  {" "}
                  {t("howtoreturnanitem")}?
                </h6>
                <p className="text-start">
                  {t(
                    "_Return_cancellation_replacement_and_refund_policy_You_can"
                  )}
                </p>
                <p className="text-start">{t("an_incorrect_product")}</p>
                <p className="text-start">{t("adamagedproduct")}</p>
                <p className="text-start">
                  {t(
                    "In_the_event_that_you_need_to_return_your_item_for_any_reason"
                  )}
                </p>
                <p className="text-start">
                  {t("The_products_are_in_their_original_retail_packaging")}
                </p>
                <p className="text-start">
                  {t(
                    "_For_watches_the_product_must_be_in_its_original_packaging_and"
                  )}
                </p>
                <p className="text-start">
                  {t(
                    "_For_all_other_products_returns_are_accepted_if_the_product_is"
                  )}
                </p>
                <p className="text-start">
                  {t(
                    "_If_the_packaging_has_minor_soiling_or_is_opened_and_the"
                  )}
                </p>
                <p className="text-start">
                  {t("You_have_the_right_to_return_a_product_within_ten")}
                </p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">
                  {t("nonreturnableproduct")}
                </h6>
                <p className="text-start">
                  {t(
                    "Products_that_you_have_used_or_damaged_or_which_are_not_in_the"
                  )}
                </p>
                <p className="text-start">
                  {t(
                    "Products_that_you_have_used_or_damaged_or_which_are_not_in_the"
                  )}
                </p>
                <p className="text-start">
                  {t("_Products_with_falsified_or_missing_serial_numbers")}
                </p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">
                  {t("reimbursementprocedure")}
                </h6>
                <p className="text-start">{t("wewillrefund")}</p>
                <p className="text-start">{t("ifyoupaidby")}</p>
                <p className="text-start">{t("ifyoupaidbycod")}</p>
              </div>

              <div className="text-start">
                {t("formoreinformation")}{" "}
                <a href="mailto:support@yaliwe.com?body=My custom mail body">
                  support@yaliwe.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default Faq;
