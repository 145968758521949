import React from "react";
import "../AuthStyles/NewPassword.css";
import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { newPasswordApi } from "../Apis/AuthApis";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewPassword({ newPasswordModal, setnewPasswordModal, token }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showNewPassword, setNewShowPassword] = useState(true);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);

  const resetNewPasswordForm = () => {
    setNewPassword("");
    setConfirmPassword("");
    setValidationErrors({});
  };

  const validateNewPassword = () => {
    const errors = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      errors.newpassword = t(
        "passwordmustbeatleast8charactersandincludeatleastonelowercaseletteroneuppercaseletteronedigitandonespecialcharacter"
      );
    }
    if (newPassword !== confirmPassword) {
      errors.confirmpassword = t("newpasswordandconfirmpasswordnotmatch");
    }
    if (!newPassword) {
      errors.newpassword = t("newpasswordcannotbeempty");
    }

    if (!confirmPassword) {
      errors.confirmpassword = t("confirmpasswordcannotbeempty");
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (validationErrors.newpassword) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, newpassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (validationErrors.confirmpassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        confirmpassword: "",
      }));
    }
  };

  const handleSave = async () => {
    if (validateNewPassword()) {
      const data = {
        token: token,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      try {
        const response = await newPasswordApi(data);
        console.log(response);
        if (response.data.message === "Password Reset Successfully") {
          Swal.fire({
            icon: "success",
            title: "Yaliwe",
            text: response.data.message,
          });
          resetNewPasswordForm();
          setnewPasswordModal(false);
          navigate("/login");
        } else {
          Swal.fire({
            icon: "error",
            title: "Password Reset Failed",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.log("Error changing password:", error);
      }
    } else {
      console.log("Passwords do not match");
    }
  };

  return (
    <div>
      <Modal
        show={newPasswordModal}
        onHide={() => {
          setnewPasswordModal(false);
          resetNewPasswordForm();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="newpassword_title">
            {t("newpassword")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newPassword">
              <Form.Label className="newpassword-label">
                {t("newpassword")}
              </Form.Label>
              <div className="newpassword_input">
                <Form.Control
                  style={{
                    border: validationErrors.newpassword ? "1px solid red" : "",
                    color: validationErrors.newpassword ? "red" : "",
                  }}
                  type={showNewPassword ? "text" : "password"}
                  placeholder={t("enteryournewpassword")}
                  value={newPassword}
                  className="newpassword-formcontrol"
                  onChange={handleNewPasswordChange}
                />
                <div
                  className="newpassword_toggle"
                  onClick={() => setNewShowPassword(!showNewPassword)}
                >
                  {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {validationErrors.newpassword && (
                <div className="newpassword_error">
                  {validationErrors.newpassword}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="confirmPassword">
              <Form.Label className="newpassword-label">
                {t("confirmpassword")}
              </Form.Label>
              <div className="newpassword_confirmpassword_input">
                <Form.Control
                  style={{
                    border: validationErrors.confirmpassword
                      ? "1px solid red"
                      : "",
                    color: validationErrors.confirmpassword ? "red" : "",
                  }}
                  type={showconfirmPassword ? "text" : "password"}
                  placeholder={t("confirmnewpasswordpassword")}
                  value={confirmPassword}
                  className="newpassword-formcontrol"
                  onChange={handleConfirmPasswordChange}
                />
                <div
                  className="newpassword_confirmpassword_toggle"
                  onClick={() => setshowconfirmPassword(!showconfirmPassword)}
                >
                  {showconfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
              {validationErrors.confirmpassword && (
                <div className="confirmpassword_error">
                  {validationErrors.confirmpassword}
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="newpassword_button"
            onClick={handleSave}
          >
            {t('submit')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewPassword;
