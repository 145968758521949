import React, { useState, useEffect } from "react";
import "../Styles/Cart.css";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import { MdDelete } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { useSelector } from "react-redux";
import {
  removeFromCart,
  fetchCart,
  updateToCart,
} from "../redux/actions/productActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { discountApi, applyCouponApi, removeCouponApi } from "../Apis/MainApis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

function Cart() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state?.cart?.loading);
  const [shipping, setShipping] = useState(0);
  const [offers, setOffers] = useState([]);

  const cartdata = useSelector((state) => state?.cart?.products);
  console.log("cartData", cartdata);
  const userid = useSelector((state) => state?.user?.user?.id);

  const handleDeleteCart = async (id) => {
    if (userid === undefined) {
      toast.error("Login to continue");
      navigate("/login");
      return;
    }
    await dispatch(removeFromCart(id));
    await dispatch(fetchCart());
  };

  const handleUpdateToCart = async (
    cartId,
    productId,
    variationId,
    quantity
  ) => {
    if (userid === undefined) {
      toast("Login to continue");
      navigate("/login");
      return;
    }
    if (quantity === 0) {
      await dispatch(removeFromCart(cartId));
      await dispatch(fetchCart());
    } else {
      await dispatch(updateToCart(cartId, productId, variationId, quantity));
      await dispatch(fetchCart());
    }
  };

  const handleApplyCoupon = async (id, name, min_order) => {
    if (userid === undefined) {
      toast.error("Login to continue");
      navigate("/login");
      return;
    }
    const data = {
      couponCode: name,
      couponId: id,
    };

    const response = await applyCouponApi(data);
    console.log(response);
    toast(response?.data?.message);
    dispatch(fetchCart());
  };

  const handleRemoveCoupon = async () => {
    if (userid === undefined) {
      toast.error("Login to continue");
      navigate("/login");
      return;
    }
    const data = {
      removeId: cartdata?.couponInfo[0]?.discountId,
    };
    await removeCouponApi(data);
    dispatch(fetchCart());
  };

  const handleCheckout = () => {
    if (userid === undefined) {
      navigate("/login");
      toast.error("Login to continue");
      return;
    }
    if (cartdata?.length === 0) {
      toast.error("Please add atleast one item to cart");
      return;
    }
    navigate(`/checkout`);
  };

  useEffect(() => {
    async function discount() {
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        page: 1,
        language: lang || "en",
      };
      const response = await discountApi(data);
      setOffers(response?.data?.data?.offers);
    }
    discount();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <TopNavbar />
          <Navbar />
          <BottomNavbar />
          <div className="container mt-5 cart_container">
            <div className="row">
              <div className="col-lg-12">
                <table className="table cart_Table">
                  <thead>
                    <tr>
                      <th>{t("mycart")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartdata?.data?.length === 0 || cartdata?.length === 0 ? (
                      <tr>
                        <td>
                          <img
                            src="emptycart.png"
                            alt=""
                            style={{ width: "200px" }}
                          ></img>
                          <p>{t("yourcartisempty")}</p>
                        </td>
                      </tr>
                    ) : (
                      cartdata?.data?.map((cart, i) => (
                        <tr key={i}>
                          <td className="d-flex flex-column cart_data">
                            <div className="row py-3">
                              <div className="col-md-4 d-flex align-items-center justify-content-center">
                                <img
                                  src={
                                    cart?.productDetails[0]?.variationImages[0]
                                  }
                                  className="cart_image"
                                  alt=""
                                />
                              </div>
                              <div className="cart_info col-md-8 mt-3 mt-md-0">
                                <div className="d-flex flex-column text-start">
                                  <p>
                                    <span className="fw-bold">
                                      {t("product")}:{" "}
                                    </span>
                                    {cart?.productDetails[0]?.name} (
                                    {
                                      cart?.variationData?.variations[0]
                                        ?.variationName
                                    }
                                    ) (
                                    {
                                      cart?.variationData?.variations[1]
                                        ?.variationName
                                    }
                                    )
                                  </p>
                                  <p>
                                    <span className="fw-bold">
                                      {t("price")}:{" "}
                                    </span>
                                    $ {cart?.variationData?.sale_price}
                                  </p>
                                  <p>
                                    <span className="fw-bold">
                                      {t("subtotal")}:{" "}
                                    </span>
                                    ${" "}
                                    {cart?.quantity *
                                      cart?.variationData?.sale_price}
                                  </p>
                                  <div className="d-flex align-items-center justify-content-around cart_quantity">
                                    <AiOutlineMinus
                                      onClick={() =>
                                        handleUpdateToCart(
                                          cart?.cartId,
                                          cart?.productDetails[0]?.id,
                                          cart?.variationId,
                                          cart?.quantity - 1
                                        )
                                      }
                                    />
                                    <p className="m-0">{cart?.quantity}</p>

                                    <AiOutlinePlus
                                      onClick={() =>
                                        handleUpdateToCart(
                                          cart?.cartId,
                                          cart?.productDetails[0]?.id,
                                          cart?.variationId,
                                          cart?.quantity + 1
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="text-start mt-2 mb-2 d-flex align-items-center"
                                    onClick={() =>
                                      handleDeleteCart(cart?.cartId)
                                    }
                                  >
                                    <span className="cart_deletebuttontext">
                                      {t("remove")}
                                    </span>
                                    <MdDelete className="cart_deletebutton" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row d-flex justify-content-between p-0 mb-5">
              {cartdata?.length !== 0 && cartdata?.data?.length !== 0 && (
                <>
                  <div className="col-12 col-lg-4 text-start">
                    <h4 className="text-start">{t("coupons")}</h4>
                    {offers?.map((offer, i) => (
                      <div className="cart_couponlist" key={i}>
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="fw-bold">{offer?.name}</h6>
                          {offer?.id === cartdata?.couponInfo[0]?.couponId ? (
                            <button
                              className="cart_couponremovebutton"
                              onClick={handleRemoveCoupon}
                            >
                              {t("remove")}
                            </button>
                          ) : (
                            <button
                              className="cart_couponapplybutton"
                              onClick={() =>
                                handleApplyCoupon(
                                  offer?.id,
                                  offer?.name,
                                  offer?.min_order_amount,
                                  offer?.discount_type,
                                  offer?.discount_percent
                                )
                              }
                            >
                              {t("apply")}
                            </button>
                          )}
                        </div>
                        <p className="m-0">{offer?.description}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {cartdata?.length !== 0 && cartdata?.data?.length !== 0 && (
                <>
                  <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                    <div className="cart_checkout">
                      <h4 className="text-start">{t("checkout")}</h4>
                      <div className=" d-flex justify-content-between align-items-center cart_total">
                        <p className="cart_total1">{t("subtotal")}</p>
                        <p>$ {cartdata?.couponInfo[0]?.subtotalAmount}</p>
                      </div>
                      <div className=" d-flex justify-content-between align-items-center cart_total">
                        <p className="cart_total1">{t("discount")}</p>
                        <p>$ {cartdata?.couponInfo[0]?.discountAmount}</p>
                      </div>
                      {/* <div className=" d-flex justify-content-between align-items-center cart_total">
                        <p className="cart_total1">Shipping</p>
                        <p>$ {shipping}</p>
                      </div> */}
                      <hr />
                      <div className=" d-flex justify-content-between align-items-center cart_total">
                        <p className="cart_total1">{t("total")}</p>
                        <p>$ {cartdata?.couponInfo[0]?.grandTotal}</p>
                      </div>
                      <button
                        className="cartcheckout_button"
                        onClick={() => handleCheckout()}
                      >
                        {t("checkout")}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default Cart;
