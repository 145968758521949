import React from "react";
import Home from "./Home/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Product from "./Components/Product";
import Category from "./Components/Category";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import Cart from "./Components/Cart";
import Wishlist from "./Components/Wishlist";
import ForgotPassword from "./Auth/ForgotPassword";
import Account from "./Components/Account";
import Checkout from "./Components/Checkout";
import ContactUs from "./Components/ContactUs";
import Tracking from "./Components/Tracking";
import MyOrders from "./Components/MyOrders";
import TermsAndConditions from "./Components/TermsAndConditions";
import Faq from "./Components/Faq";
import LegalNotice from "./Components/LegalNotice";
import Find from "./Components/Find";
import Success from "./Components/Success";
import Failed from "./Components/Failed";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/product/:id/:productslug",
    element: <Product />,
  },
  {
    path: "/category/:pid/:slug/:cid?",
    element: <Category />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/wishlist",
    element: <Wishlist />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/account/:accountSlug",
    element: <Account />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/account/myorders/tracking",
    element: <Tracking />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/termsandconditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/legalnotice",
    element: <LegalNotice />,
  },
  {
    path: "/find-products",
    element: <Find />,
  },
  {
    path: "/success/:orderId",
    element: <Success />,
  },
  {
    path: "/cancel/:orderId",
    element: <Failed />,
  },
]);

function Route() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default Route;
