import React from "react";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

function LegalNotice() {
  const { t } = useTranslation();
  return (
    <>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-4">
                <h3 className="text-start fw-bold">{t("legalnotice")}</h3>
                <p className="text-start">{t("pleaseread")}</p>
                <p className="text-start">{t("alsoinaccordance")}</p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold"> {t("termsofuse")}</h6>
                <p className="text-start">
                  {t("thissite")}
                  <a href="www.yaliwe.com">www.yaliwe.com</a>
                </p>
                <p className="text-start">{t("werecommendthat")}</p>
                <p className="text-start">{t("yaliweimplements")}</p>
                <p className="text-start">{t("howevererrors")}</p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">{t("cookies")}</h6>
                <p className="text-start">
                  {t("thisiste")}
                  <a href="www.yaliwe.com">www.yaliwe.com</a> {t("mayask")}
                </p>
                <p className="text-start">{t("itcontains")}</p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">{t("hypertextlinks")}</h6>
                <p className="text-start">{t("thissitemayoffer")}</p>
                <p className="text-start">{t("inthevent")}</p>
                <p className="text-start">{t("yaliwereserves")}</p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">{t("servicesprovided")}</h6>
                <p className="text-start">
                  {t("allofthecompany")}{" "}
                  <a href="www.yaliwe.com">www.yaliwe.com</a>.
                </p>
                <p className="text-start">{t("theyaregiven")}</p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">{t("contractual")}</h6>
                <p className="text-start">{t("theinformationcontained")}</p>
                <p className="text-start">{t("ifyounoticeagap")}</p>
                <p className="text-start">{t("inaddition")}</p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">
                  {" "}
                  {t("intellectualproperty")}
                </h6>
                <p className="text-start">{t("allcontentofthis")}</p>
                <p className="text-start">{t("anyreproduction")}</p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">c</h6>
                <p className="text-start">
                  {t("theseconditionsofthesite")}{" "}
                  <a href="www.yaliwe.com">www.yaliwe.com</a>{" "}
                  {t("aregovernedby")}
                </p>
              </div>

              <div className="mb-4">
                <h6 className="text-start fw-bold">{t("personaldata")}</h6>
                <p className="text-start">
                  {t("generallyspeaking")}
                  <a href="www.yaliwe.com"> www.yaliwe.com</a>.
                </p>
                <p className="text-start">{t("howeverthisprinciple")}</p>
                <p className="text-start">{t("inanycase")}</p>
                <p className="text-start">{t("finallywe")}</p>
                <p className="text-start">{t("suchinformation")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LegalNotice;
