import React, { useState, useEffect } from "react";
import "../Styles/HairWigProduct.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchHomeProducts } from "../redux/actions/HomeProductsAction";
import { useSelector } from "react-redux";
import LoginModal from "../Modal/LoginModal";
import { AiFillStar } from "react-icons/ai";

function HairWigProducts({ products }) {
  const collectionData = useSelector(
    (state) => state?.homeproducts?.homeproducts
  );
  const userid = useSelector((state) => state.user.user.id);

  const [specialOfferProducts, setspecialOfferProducts] = useState([]);
  const [showSignin, setShowSignin] = useState(false);
  const [sliderSlug, setSlug] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const specialOffer = collectionData[2];
    setspecialOfferProducts(specialOffer?.products);
    setSlug(specialOffer?.collectionSlug);
  }, [collectionData]);

  const handleConstructionNavigate = (id, productslug) => {
    navigate(`/product/${id}/${productslug}`);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const slickSettings = {
    infinite: true,
    slidesToShow:
      specialOfferProducts?.length < 4 ? specialOfferProducts?.length : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const closeModals = () => {
    setShowSignin(false);
  };
  return (
    <>
      <LoginModal show={showSignin} onClose={closeModals} />
      <div className="hairwigproducts_container">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="d-flex justify-content-between mb-4">
                <div className="hairwig_products">
                  {collectionData?.[2]?.collectionName}
                </div>
              </div>

              <Slider className="slider-container" {...slickSettings}>
                {specialOfferProducts?.map((product, i) => (
                  <div className="item" key={i}>
                    <div
                      className="card hairwig_card"
                      onClick={() =>
                        handleConstructionNavigate(product.id, product.slug)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="hairwig_cardtopportion">
                        <div className="hairwig_imagebackground">
                          <img
                            className="hairwig_image"
                            src={product?.featured_image}
                            alt=""
                          ></img>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <h5 className="hairwig_cardtitle text-start m-0">
                              {product?.categories[0]?.categoryDetails?.name}
                            </h5>
                            <span className="d-flex align-items-center category_ratingbox ms-2">
                              <AiFillStar className="me-1" />
                              {product.averageRating}
                            </span>
                          </div>
                          {/* <div style={{ width: "1rem" }}>
                            <Heart
                              isActive={
                                product.wishlist === "yes" ? true : false
                              }
                              onClick={() => {
                                handleWishlist(product.id, product.wishlist);
                              }}
                            />
                          </div> */}
                        </div>
                        <p className="hairwig_cardtext text-start fw-bold m-0 py-2">
                          {product.name}
                        </p>
                        <div className="hairwig_span text-start d-flex align-items-center">
                          <span className="fw-bold">${product.sale_price}</span>
                          <span className="product_actualprice">
                            <div className="diagonal-line-wrapper">
                              ${product.base_price}
                            </div>
                          </span>
                          {/* <span>
                            <button
                              className="haiwig_button"
                              onClick={() =>
                                handleConstructionNavigate(
                                  product.id,
                                  product.slug
                                )
                              }
                            >
                              <span>View</span>
                            </button>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HairWigProducts;
