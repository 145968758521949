import React, { useEffect, useState } from "react";
import "../Sidebar/Sidebar1.css";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { parentCategoryApi } from "../Apis/MainApis";
import { useTranslation } from "react-i18next";

function Sidebar1({ isOpen, closeSidebar }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [parentcategory, setParentCategory] = useState([]);

  const handleShopAllNavigate = (slug) => {
    navigate(`/category/${slug}`);
    closeSidebar();
  };

  const handleContactUsNavigate = () => {
    navigate("/contactus");
    closeSidebar();
  };

  const handleHomeNavigate = () => {
    navigate("/");
    closeSidebar();
  };

  const handleFeatureCategoriesNavigate = (id, slug) => {
    navigate(`/category/${id}/${slug}`);
  };

  useEffect(() => {
    async function fetchparentcategory() {
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        language: lang||"en",
      };
      const response = await parentCategoryApi(data);
      setParentCategory(response?.data?.data?.categories);
    }
    fetchparentcategory();
  }, []);

  return (
    <>
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="text-end me-2">
          <IoClose onClick={() => closeSidebar()} />
        </div>
        <div className="sidebar1_contents">
          <p
            className="text-white fw-bold"
            onClick={() => handleHomeNavigate()}
          >
            {t("home")}
          </p>
        </div>
        {parentcategory?.slice(0, 3).map((item, i) => (
          <div className="sidebar1_contents">
            <p
              className="text-white fw-bold"
              onClick={() => {
                handleFeatureCategoriesNavigate(item?.id, item?.slug);
                closeSidebar();
              }}
            >
              {item?.name?.toUpperCase()}
            </p>
          </div>
        ))}

        <div className="sidebar1_contents">
          <p
            className="text-white fw-bold"
            onClick={() => {
              navigate("/find-products");
              closeSidebar();
            }}
          >
           {t("hardtofind")}
          </p>
        </div>

        <div className="sidebar1_contents">
          <p
            className="text-white fw-bold"
            onClick={() => handleContactUsNavigate()}
          >
           {t("contactus")}
          </p>
        </div>
      </div>
    </>
  );
}

export default Sidebar1;
