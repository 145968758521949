import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import Banner from "../Components/Banner";
import HotDeal from "../Components/HotDeal";
import ConstructionProducts from "../Components/ConstructionProducts";
import HairWigProducts from "../Components/HairWigProducts";
import ElectronicsProduct from "../Components/ElectronicsProduct";
import HairPerfector from "../Components/HairPerfector";
import FeaturedCategories from "../Components/FeaturedCategories";
import BestProducts from "../Components/BestProducts";
import Blog from "../Components/Blog";
import Footer from "../Components/Footer";
import "bootstrap/dist/css/bootstrap.css";
import "../Home/Home.css";

function Home() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const handlePopstate = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [location]);
  return (
    <div>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <Banner />
      <FeaturedCategories />
      <BestProducts />
      {/* <HotDeal /> */}
      <ConstructionProducts />
      <HairPerfector />
      <HairWigProducts />
      <ElectronicsProduct />
      <Blog />
      <Footer />
    </div>
  );
}

export default Home;
