import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./ResetPassword.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { changePasswordApi } from "../Apis/MainApis";
import { useTranslation } from "react-i18next";

function ResetPassword({ showResetPassword, setShowResetPassword }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showNewPassword, setNewShowPassword] = useState(true);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);

  const resetChangePasswordForm = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setValidationErrors({});
  };

  const validateNewPassword = () => {
    const errors = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      errors.newpassword = t(
        "passwordmustbeatleast8charactersandincludeatleastonelowercaseletteroneuppercaseletteronedigitandonespecialcharacter"
      );
    }
    if (newPassword !== confirmPassword) {
      errors.confirmpassword = t("passworddonotmatch");
    }
    if (!newPassword) {
      errors.newpassword = t("newpasswordcannotbeempty");
    }

    if (!confirmPassword) {
      errors.confirmpassword = t("confirmpasswordcannotbeempty");
    }
    if (!oldPassword) {
      errors.oldpassword = t("oldpasswordcannotbeempty");
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (validationErrors.newpassword) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, newpassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (validationErrors.confirmpassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        confirmpassword: "",
      }));
    }
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    if (validationErrors.oldpassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        oldpassword: "",
      }));
    }
  };

  const handleSave = async () => {
    if (validateNewPassword()) {
      console.log("Valid Form");
      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmnewPassword: confirmPassword,
      };
      try {
        const response = await changePasswordApi(data);
        console.log(response);
        if (response.data.message === "Password changed successfully.") {
          Swal.fire({
            icon: "success",
            title: "Yaliwe",
            text: response?.data?.message,
          });
          resetChangePasswordForm();
          setShowResetPassword(false);
          localStorage.clear();
          navigate("/login");
        }
      } catch (error) {
        console.log("Error changing password:", error);
      }
    } else {
      console.log("InValid Form");
    }
  };
  return (
    <Modal
      show={showResetPassword}
      onHide={() => setShowResetPassword(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="resetpassword_title">
        {t("changepasssword")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="oldPassword">
            <Form.Label className="resetpassword-label m-0 pb-2">
            {t("oldpasssword")}
            </Form.Label>
            <div className="resetpassword_input">
              <Form.Control
                style={{
                  border: validationErrors.oldpassword ? "1px solid red" : "",
                  color: validationErrors.oldpassword ? "red" : "",
                }}
                type="text"
                placeholder={t("oldpasssword")}
                value={oldPassword}
                className="newpassword-formcontrol"
                onChange={handleOldPasswordChange}
              />
            </div>
            {validationErrors.oldpassword && (
              <div className="oldpassword_error">
                {validationErrors.oldpassword}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="resetPassword">
            <Form.Label className="resetpassword-label m-0 py-2">
            {t("newpasssword")}
            </Form.Label>
            <div className="resetpassword_input">
              <Form.Control
                style={{
                  border: validationErrors.newpassword ? "1px solid red" : "",
                  color: validationErrors.newpassword ? "red" : "",
                }}
                type={showNewPassword ? "text" : "password"}
                placeholder={t("newpasssword")}
                value={newPassword}
                className="newpassword-formcontrol"
                onChange={handleNewPasswordChange}
              />
              <div
                className="newpassword_toggle"
                onClick={() => setNewShowPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaEye /> : <FaEyeSlash />}
              </div>
            </div>
            {validationErrors.newpassword && (
              <div className="newpassword_error">
                {validationErrors.newpassword}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="resetconfirmPassword">
            <Form.Label className="resetpassword-label m-0 py-2">
            {t("confirmpasssword")}
            </Form.Label>
            <div className="resetpassword_confirmpassword_input">
              <Form.Control
                style={{
                  border: validationErrors.confirmpassword
                    ? "1px solid red"
                    : "",
                  color: validationErrors.confirmpassword ? "red" : "",
                }}
                type={showconfirmPassword ? "text" : "password"}
                placeholder={t("confirmpasssword")}
                value={confirmPassword}
                className="newpassword-formcontrol"
                onChange={handleConfirmPasswordChange}
              />
              <div
                className="resetpassword_confirmpassword_toggle"
                onClick={() => setshowconfirmPassword(!showconfirmPassword)}
              >
                {showconfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </div>
            </div>
            {validationErrors.confirmpassword && (
              <div className="confirmpassword_error">
                {validationErrors.confirmpassword}
              </div>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="resetpassword_button"
          onClick={handleSave}
        >
          {t("changepasssword")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ResetPassword;
