import React from "react";
import "../Styles/Banner.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Banner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const collectionData = useSelector(
    (state) => state?.homeproducts?.homeproducts
  );

  return (
    <div className="mt-4 banner_container">
      <div className="container">
        <div className="row g-4">
          <div className="col-12 col-md-6 banner_background">
            <div className="px-5 py-5 pb-0 ">
              <div className="d-flex flex-column align-items-center">
                <h2 className="banner_constructionhead">
                  {collectionData[1]?.collectionName}
                </h2>
                <p className="banner_constructionpara">{t("banner1")}</p>
              </div>
              <img
                className="banner_contructionimage"
                src="banner1.png"
                alt=""
              ></img>
            </div>
          </div>

          <div className="col-12 col-md-6 d-flex flex-column">
            <div className="banner_background d-flex align-items-center px-3 py-4 mb-4">
              <div className="col-md-6">
                <h2 className="banner_electronichead text-start">
                  {collectionData[2]?.collectionName}
                </h2>
                <p className="banner_electronicpara text-start">
                  {t("banner2")}
                </p>
                <div className="text-start"></div>
              </div>
              <div className="col-md-6">
                <img
                  className="banner_electronicimage"
                  src="banner3.png"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="banner_background d-flex align-items-center px-3 py-4">
              <div className="col-md-6">
                <img
                  className="banner_hairimage"
                  src="banner2.png"
                  alt=""
                ></img>
              </div>
              <div className="col-md-6">
                <h2 className="banner_hairhead text-end">
                  {collectionData[3]?.collectionName}
                </h2>
                <p className="banner_hairpara text-end">{t("banner3")}</p>
                <div className="text-end"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
