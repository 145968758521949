import { combineReducers } from "redux";
import { productReducer } from "./productReducer";
import { userReducer } from "./userReducer";
import { wishlistReducer } from "./wishlistReducer";
import { homeProductReducer } from "./homeProductReducer";
import { addressReducer } from "./addressReducer";

const reducers = combineReducers({
  cart: productReducer,
  user: userReducer,
  wishlist: wishlistReducer,
  homeproducts: homeProductReducer,
  addresses: addressReducer,
});

export default reducers;
