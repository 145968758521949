import React from "react";
import "../Styles/HotDeal.css";
import { useEffect, useState } from "react";
function HotDeal() {
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  const targetDate = new Date("2023-12-31T00:00:00").getTime();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const currentDate = new Date().getTime();
  //     const timeLeft = targetDate - currentDate;

  //     if (timeLeft <= 0) {
  //       clearInterval(interval);
  //       setDays("00");
  //       setHours("00");
  //       setMinutes("00");
  //       setSeconds("00");
  //     } else {
  //       const daysRemaining = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  //       const hoursRemaining = Math.floor(
  //         (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //       );
  //       const minutesRemaining = Math.floor(
  //         (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
  //       );
  //       const secondsRemaining = Math.floor((timeLeft % (1000 * 60)) / 1000);

  //       setDays(daysRemaining.toString().padStart(2, "0"));
  //       setHours(hoursRemaining.toString().padStart(2, "0"));
  //       setMinutes(minutesRemaining.toString().padStart(2, "0"));
  //       setSeconds(secondsRemaining.toString().padStart(2, "0"));
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="mb-4 hotdeal_container">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <img src="electronicgadgets.png" alt="" className="hotdeal_image" />
          </div>
          <div className="col-12 col-lg-6 hotdeal_background d-flex flex-column justify-content-center">
            <div className=" p-4 ">
              <h3 className="hotdeal_head mb-4">HOT DEALS</h3>
              <div>
                <p className="hotdeal_para mb-5">
                  Lorem Ipsum is simply dummy text of the is simply printing and
                  typesetting industry.Lorem Ipsum is simply dummy text of the
                  is simply printing and typesetting industry.
                </p>
              </div>
              <div className="countdown mb-5">
                <div className="countdown-circle">
                  <div className="countdown-item">{days}</div>
                  <div className="countdown-label">Days</div>
                </div>
                <div className="countdown-colon">:</div>
                <div className="countdown-circle">
                  <div className="countdown-item">{hours}</div>
                  <div className="countdown-label">Hours</div>
                </div>
                <div className="countdown-colon">:</div>
                <div className="countdown-circle">
                  <div className="countdown-item">{minutes}</div>
                  <div className="countdown-label">Minutes</div>
                </div>
                <div className="countdown-colon">:</div>
                <div className="countdown-circle">
                  <div className="countdown-item">{seconds}</div>
                  <div className="countdown-label">Seconds</div>
                </div>
              </div>
              <div>
                <button className="hotdeal_button">
                  Shop Collections Now !
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotDeal;
