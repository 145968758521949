import React from "react";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t } = useTranslation();
  const listStyle = {
    listStyleType: "none",
  };
  return (
    <>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h3 className="text-start fw-bold">{t("aboutus")}</h3>
                <p className="text-start">{t("foundedin")}</p>
              </div>
              <div>
                <h6 className="text-start fw-bold">{t("order")}</h6>
                <p className="text-start">{t("whetherforthe")}</p>
              </div>
              <div>
                <h6 className="text-start fw-bold">{t("delivery")}</h6>
                <p className="text-start">{t("  toensure")}</p>
              </div>
              <div>
                <h6 className="text-start fw-bold"> {t("customerservice")}</h6>
                <p className="text-start">{t("ourtelephone")}</p>
              </div>
              <div>
                <h6 className="text-start fw-bold">{t("navigate")}</h6>
                <p className="text-start">{t("yaliweallows")}</p>
              </div>
              <div>
                <h6 className="text-start fw-bold">{t("tracking")}</h6>
                <p className="text-start">{t("whentheorder")}</p>
              </div>

              <h3 className="text-start fw-bold">{t("termsofservice")}</h3>
              <div>
                <h6 className="text-start fw-bold">{t("keyprinciples")}</h6>
                <p className="text-start">
                  {t("thesegeneral")}{" "}
                  <a href="mailto:support@yaliwe.com?body=My custom mail body">
                    support@yaliwe.com
                  </a>
                </p>
                <p className="text-start">
                  {t("theweb")}{" "}
                  <a href="mailto:support@yaliwe.com?body=My custom mail body">
                    support@yaliwe.com
                  </a>
                </p>
                <p className="text-start">{t("foryaliweproducts")}</p>
                <p className="text-start">{t("forproductsand")}</p>
                <p className="text-start">{t("pleasereadtheseterms")}</p>
              </div>

              <div>
                <h6 className="text-start fw-bold">{t("definitions")}</h6>
                <p className="text-start">{t("suppliersandintermediary")}</p>
                <p className="text-start">{t("theproductsand")}</p>
                <p className="text-start">
                {t('weourteamdesignates')}
                </p>
              </div>
              <h3 className="text-start fw-bold">{t('conditions')}</h3>
              <div>
                <h6 className="text-start fw-bold"> 1. {t('resume')}</h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    1.1. {t('thesegeneralconditions')}
                  </li>
                  <li>
                    1.2. {t('theseconditions')}
                  </li>
                  <li>
                    1.3. {t('yaliweacts')}
                  </li>
                </ul>
              </div>
              <div>
                <h6 className="text-start fw-bold">
                  2. {t('productsanddelivery')}
                </h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    2.1. {t('theproductsanddelivery')}
                  </li>
                  <ul className="text-start" style={listStyle}>
                    <li>
                      2.1.1. {t('accessanduse')}
                    </li>
                    <li>
                      2.1.2. {t('productsandservice')}
                    </li>
                  </ul>
                  <li>
                    2.2.  {t('thesetermsgovern')}
                  </li>
                  <li>
                    2.3. {t('yaliwemobileapplication')}
                  </li>
                  <li>
                    2.4. {t('theproductsanddeliveryapp')}
                  </li>
                  <li>
                    2.5. {t('youcanrequest')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">
                  3. {t('productsandintermediary')}
                </h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    3.1. {t('yaliweactas')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">
                  4. {t('useofproducts')}
                </h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    4.1. {t('theyaliwemobileapplication')}
                  </li>
                  <li>
                  
                    4.2. {t('youareresponsible')}
                  </li>
                  <li>
                    4.3. {t('inordertouse')}
                  </li>
                  <li>
                    4.4. {t('youmustprovide')}
                  </li>
                  <li>
                    4.5. {t('youarenotrequired')}
                  </li>
                  <li>
                    4.6. {t('wemaytemporarily')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">5. {t('yourobligations')}</h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    5.1. {t('whenyouusetheproducts')}
                    </li>
                  <li>
                    5.2. {t('youmustprovideaccurate')}
                  </li>
                  <li>
                    5.3. {t('youraccountispersonal')}
                  </li>
                  <ul className="text-start" style={listStyle}>
                    <li>
                      • {t('youmaynotregister')}
                    </li>
                    <li>
                      • {t('youcannotauthorize')}
                    </li>
                    <li>
                      • {t('youmustensure')}
                    </li>
                    <li>
                      • {t('youmustnotdisclose')}
                    </li>
                    <li>
                      • {t('youmustinformus')}
                    </li>
                  </ul>
                  <li>
                    5.4. {t('youwillberesponsible')}
                  </li>
                  <li>
                    5.5. {t('youshallnot')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">
                  6.1. {t('feesrelatedtotheproducts')}
                  Yaliwe
                </h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    6.1.1. {t('ifyoumakeasolicitation')}
                  </li>
                  <li>
                    6.1.2. {t('dependingontheservice')}
                  </li>
                  <li>
                    6.1.3. {t('iftheproductsanddelivery')}
                  </li>
                  <li>
                    6.1.4. {t('oncetheintermediary')}
                  </li>
                  <li>
                    6.1.5. {t('feesaresubjecttochange')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">7. {t('cancellation')}</h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    7.1. {t('youhavetheright')}
                  </li>
                  <li>
                    7.2. {t('ifyoupurchase')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">8. {t('personaldata')}</h6>
                <p className="text-start">
                {t('wecollectinformation')}
                  
                </p>
              </div>

              <div>
                <h6 className="text-start fw-bold">9. {t('yourresponsibilities')}</h6>
                <p className="text-start">
                {t('youraresponsible')}
                </p>
              </div>

              <div>
                <h6 className="text-start fw-bold">
                  10. {t('limitationofliability')}
                </h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    10.1. {t('ifyouactas')}
                  </li>
                  <li>
                    10.2.  {t('forallproduct')}
                  </li>
                  <li>
                    10.3. {t('wearenot')}
                  </li>
                  <li>
                    10.4. {t('yaliweiscommitted')}
                  </li>
                  <li>
                    10.5. {t('yaliweisdirectlyliable')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">11. {t('termination')}</h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    11.1. {t('youarefree')}
                  </li>
                  <li>
                    11.2. {t('yaliwemayterminate')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">12. {t('general')}</h6>
                <ul className="text-start" style={listStyle}>
                  <li>
                    12.1. {t('yaliwereservestheright')}
                  </li>
                  <li>
                    12.2. {t('wemayassign')}
                  </li>
                  <li>
                    12.3. {t('theseconditionstogether')}
                  </li>
                  <li>
                    12.4. {t('youmaybesasked')}
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-start fw-bold">
                  13. {t('disputeresolutionprocedure')}
                </h6>
                <p className="text-start">
                {t('yaliwemaymakeavailable')}
                </p>
              </div>

              <div>
                <h6 className="text-start fw-bold">
                  14. {t('applicablelawandcompetent')}
                </h6>
                <p className="text-start">
                {t('thesetermswillbe')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
