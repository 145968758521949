import React, { useState } from "react";
import "../AuthStyles/Otp.css";
import { Modal, Button } from "react-bootstrap";
import OTPInput from "otp-input-react";
import { otpverificationApi } from "../Apis/AuthApis";
import { toast } from "react-toastify";
import NewPassword from "./NewPassword";
import { useTranslation } from "react-i18next";

function Otp({ openOtpModal, setOtpModal }) {
  const { t } = useTranslation();
  const [OTP, setOTP] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [newPasswordModal, setnewPasswordModal] = useState(false);
  const [token, setToken] = useState("");

  const handleSubmit = async () => {
    const data = {
      otp: OTP,
    };
    const response = await otpverificationApi(data);
    console.log(response?.data?.message);
    if (response?.data?.message === "Successful verification") {
      toast(response?.data?.message);
      setOtpModal(false);
      setToken(response.data.data.token);
      setOTP("");
      setOtpModal(false);
      setVerificationSuccess(true);
      setnewPasswordModal(true);
    }
  };

  return (
    <>
      {verificationSuccess && (
        <NewPassword
          newPasswordModal={newPasswordModal}
          setnewPasswordModal={setnewPasswordModal}
          token={token}
        />
      )}
      <Modal show={openOtpModal} onHide={() => setOtpModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="otp_title"> {t("enterotp")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="otp_verify_button" onClick={() => handleSubmit()}>
          {t("verifyotp")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Otp;
