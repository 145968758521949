import { ActionTypes } from "../constant/action-types";

const initialState = {
  wish: [],
};

export const wishlistReducer = (state = initialState, { type, payload }) => { 
  switch (type) {
    case ActionTypes.FETCH_WISHLIST:
      return { ...state, wish: payload || [] };
    case ActionTypes.EMPTY_WISHLIST:
      return { ...state, wish: [] };
    default:
      return state;
  }
};
