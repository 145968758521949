import React, { useEffect, useState, useRef } from "react";
import "../Styles/Product.css";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import { AiFillStar } from "react-icons/ai";
import { BsTruck } from "react-icons/bs";
import { AiOutlineWallet } from "react-icons/ai";
import { TfiReload } from "react-icons/tfi";
import { AiOutlineReload } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Rating } from "react-simple-star-rating";
import Footer from "../Components/Footer";
import SliderImage from "react-zoom-slider";
import { useParams } from "react-router-dom";
import { addToCart, fetchCart } from "../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleProductVariationChildApi,
  fetchSingleProductVariationApi,
  addReviewApi,
  productReviewApi,
  fetchSingleProductApi,
  imageuploadApi,
} from "../Apis/MainApis";
import ConstructionProducts from "../Components/ConstructionProducts";
import ElectronicsProducts from "../Components/ElectronicsProduct";
import HairWigProducts from "../Components/HairWigProducts";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageModal from "../Modal/ImageModal";
import LoginModal from "../Modal/LoginModal";
import Heart from "react-heart";
import {
  fetchWishlist,
  removeFromWishlist,
  addToWishlist,
} from "../redux/actions/wishlistActions";
import { fetchHomeProducts } from "../redux/actions/HomeProductsAction";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { MdDelete } from "react-icons/md";
import { deleteReviewApi } from "../Apis/MainApis";
import { useTranslation } from "react-i18next";

function Product() {
  const { t } = useTranslation();
  const params = useParams();
  const productid = params.id;
  const dispatch = useDispatch();
  const userid = useSelector((state) => state?.user?.user?.id);
  const lang = localStorage.getItem("Yaliwe_language");

  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [reviewRating, setReviewRating] = useState(0);
  const [totalPageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  const [showimagemodal, setshowImageModal] = useState(false);

  const [topReviews, setTopReviews] = useState([]);
  const [userReview, setUserReview] = useState([]);

  const [showSignin, setShowSignin] = useState(false);

  const [wishlist, setWishlistChange] = useState(false);
  const [deleteReview, setDeleteReview] = useState(false);

  const [rating, setRating] = useState(0);

  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [productData, setProductData] = useState([]);
  const [rootAttribute, setRootAttribute] = useState({});
  const [subAttribute, setSubAttribute] = useState({});
  const [activeRootButtonId, setActiveRootButtonId] = useState(null);
  const [activeSubRootButtonId, setActiveSubRootButtonId] = useState(null);
  const [base_price, setBasePrice] = useState("");
  const [sale_price, setSalePrice] = useState("");
  const [variation_id, setVariationId] = useState("");
  const [selectedReviewImage, setSelectedReviewImage] = useState("");
  const [selectedReviewImages, setSelectedReviewImages] = useState([]);

  const arr = [];
  const fileInputRef = useRef(null);

  const handleReviewImageClick = (image) => {
    setSelectedReviewImage(image);
    setshowImageModal(true);
  };

  useEffect(() => {
    async function fetchsingleproduct() {
      const lang = localStorage.getItem("Yaliwe_language");
      const data = {
        productId: productid,
        language: lang || "en",
        uid: userid || "",
      };
      const response = await fetchSingleProductApi(data);
      console.log(response, "singleProductApi");
      setProductData(response?.data?.data?.product[0]);

      setBasePrice(response?.data?.data?.product[0]?.base_price);
      setSalePrice(response?.data?.data?.product[0]?.sale_price);
      setImages(response?.data?.data?.product[0]?.imagesGet);
      setMainImage(response?.data?.data?.product[0]?.imagesGet[0]);
      setRootAttribute(response?.data?.data?.product[0]?.rootAttributes);
    }

    fetchsingleproduct();
  }, [productid, userid, wishlist, currentPage]);

  useEffect(() => {
    async function review() {
      const productreviewdata = {
        page: currentPage + 1,
        productId: productid,
        uid: userid,
        filter: "highRating",
      };

      const response1 = await productReviewApi(productreviewdata);
      setTopReviews(response1?.data?.data?.reviews);
      setUserReview(response1?.data?.data?.reviewsUser);
      setPageCount(response1?.data?.data?.length / 10);
      setRating(response1?.data?.data?.averageRating);
    }
    review();
  }, [userid, currentPage, productid, deleteReview]);

  const handlerootAttribute = async (childid, variationid) => {
    if (activeRootButtonId) {
      setActiveSubRootButtonId(null);
    }
    setActiveRootButtonId(childid);
    arr[0] = childid;
    const data = {
      productId: productid,
      childId: childid,
      variationId: variationid,
      language: lang || "en",
    };
    const response = await fetchSingleProductVariationChildApi(data);
    console.log("rootattribute", response);
    setImages(response?.data?.data?.variationData?.images);
    setMainImage(response?.data?.data?.variationData?.images[0]);
    setSubAttribute(response?.data?.data?.variationData?.variationInfo[0]);
  };

  const handleSubrootAttribute = async (childId) => {
    setActiveSubRootButtonId(childId);
    arr[0] = activeRootButtonId;
    arr[1] = childId;
    const data = {
      productId: productid,
      childId: arr,
      language: lang || "en",
    };
    const response = await fetchSingleProductVariationApi(data);
    console.log("subattribute", response);
    setImages(response?.data?.data?.variation[0]?.images);
    setMainImage(response?.data?.data?.variation[0]?.images[0]);
    setBasePrice(response?.data?.data?.variation[0]?.base_price);
    setSalePrice(response?.data?.data?.variation[0]?.sale_price);
    setVariationId(response?.data?.data?.variation[0]?.id);
  };

  const handleAddToCart = async () => {
    if (userid === undefined) {
      setShowSignin(true);
      return;
    }
    if (!activeRootButtonId) {
      toast.error(`Select ${rootAttribute?.attributeName}`);
      return;
    }
    if (!activeSubRootButtonId) {
      toast.error(`Select ${subAttribute?.attributeName}`);
      return;
    }
    await dispatch(addToCart(productid, variation_id));
    await dispatch(fetchCart());
  };

  const handleReviewTitleChange = (event) => {
    if (userid === undefined) {
      setShowSignin(true);
      return;
    }
    setReviewTitle(event.target.value);
  };

  const handleReviewDescriptionChange = (event) => {
    if (userid === undefined) {
      setShowSignin(true);
      return;
    }
    setReviewDescription(event.target.value);
  };

  const handleReviewRatingChange = (newRating) => {
    if (userid === undefined) {
      setShowSignin(true);
      setReviewRating(0);
      return;
    }
    setReviewRating(newRating);
  };

  const handleReviewImageChange = async (event) => {
    if (userid === undefined) {
      setShowSignin(true);
      fileInputRef.current.value = "";
      return;
    }
    if (selectedReviewImages?.length >= 3) {
      toast.error(t("youcannotuploadmorethan3images"));
      return;
    }

    const files = event.target.files;
    const fileArray = Array.from(files);

    const base64Array = await Promise.all(
      fileArray.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            resolve(reader.result);
          };

          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    );

    setSelectedReviewImages([...selectedReviewImages, ...base64Array]);
  };

  const handleCancelImage = (indexToRemove) => {
    const updatedImages = selectedReviewImages.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedReviewImages(updatedImages);
  };

  const handleSubmitReview = async () => {
    try {
      if (userid === undefined) {
        setShowSignin(true);
        return;
      }

      if (
        reviewTitle === "" ||
        reviewDescription === "" ||
        reviewRating === 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Review",
          text: t("ratingfeedbackandmessagerequired"),
        });
        return;
      }

      const imageUrls = await Promise.all(
        selectedReviewImages?.map(async (base64String) => {
          const imagedata = {
            type: "review",
            image: base64String,
          };
          const response = await imageuploadApi(imagedata);
          console.log("REVIEW IN PRODUCT COMPONENT", response?.data?.data);
          return response.data.img;
        })
      );

      const data = {
        productId: productid,
        title: reviewTitle,
        description: reviewDescription,
        rating: reviewRating,
        images: imageUrls,
      };

      const response = await addReviewApi(data);

      if (
        response?.data?.message ===
        "Added your review successfully currently this review under process"
      ) {
        Swal.fire({
          icon: "success",
          title: "Yaliwe",
          text: response?.data?.message,
        });
      }

      setReviewTitle("");
      setReviewDescription("");
      setReviewRating(0);
      setSelectedReviewImages([]);
      fileInputRef.current.value = "";
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  const closeModals = () => {
    setShowSignin(false);
  };

  const handleWishlist = async (id, wish) => {
    if (userid === undefined) {
      setShowSignin(true);
      return;
    }
    if (activeRootButtonId === null) {
      toast.error(`Select ${rootAttribute?.attributeName}`);
      return;
    }
    try {
      if (wish === "yes") {
        await dispatch(removeFromWishlist(id, userid));
      } else {
        await dispatch(addToWishlist(id, userid, activeRootButtonId));
      }
      await dispatch(fetchWishlist(userid));
      await dispatch(fetchHomeProducts(userid));
    } catch (error) {
      console.error("Error handling wishlist:", error);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDeleteReview = async (id) => {
    const data = {
      reviewId: id,
    };
    const response = await deleteReviewApi(data);
    setDeleteReview(!deleteReview);
    toast(t("reviewdeletedsuccessfully"));
  };

  return (
    <>
      <LoginModal show={showSignin} onClose={closeModals} />
      <ImageModal
        setshowImageModal={setshowImageModal}
        showimagemodal={showimagemodal}
        selectedImage={selectedReviewImage}
      />
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="product py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div style={{ position: "relative", zIndex: "0" }}>
                <div>
                  <img
                    src={mainImage}
                    alt=""
                    className="slider_mainimage"
                  ></img>
                </div>
                <div className="row">
                  {images &&
                    images?.length > 0 &&
                    images?.map((image, index) => (
                      <div
                        className="col-4 col-sm-2 col-md-2 col-lg-3"
                        onClick={() => setMainImage(image)}
                      >
                        <img
                          src={image}
                          className="slider_smallimage"
                          alt=""
                        ></img>
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    width: "1.5rem",
                    position: "absolute",
                    top: 10,
                    right: 20,
                    zIndex: 99,
                  }}
                  onClick={() => {
                    handleWishlist(productData?.id, productData?.wishlist);
                    setWishlistChange(!wishlist);
                  }}
                >
                  <Heart
                    isActive={productData?.wishlist === "yes" ? true : false}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-lg-0 mt-3">
              <div className="product_middleinfo ">
                <h5 className="product_middletitle text-start mb-2">
                  {productData?.name}
                </h5>

                <div className="d-flex align-items-center product_middlerating mb-2">
                  <span className="d-flex align-items-center me-3 product_middleratingbox">
                    {rating || 0}
                    <AiFillStar />
                  </span>
                  {/* <span>Based on 158 Ratings</span> */}
                </div>

                <div className="d-flex align-items-center text-start mb-2">
                  <span className="product_middleprice">$ {sale_price}</span>
                  <span className="product_middlediscount">$ {base_price}</span>
                  {/* <span className="product_middleoff">30% off</span> */}
                </div>

                <h6 className="text-start fw-bold">
                  {rootAttribute?.attributeName}
                </h6>
                <div className="d-flex align-items-center mb-2">
                  {rootAttribute?.childData?.map((child, i) => (
                    <button
                      className={`product_color ${
                        child?.childId === activeRootButtonId ? "active" : ""
                      }`}
                      key={i}
                      onClick={() =>
                        handlerootAttribute(child?.childId, child?.variationId)
                      }
                    >
                      {child?.childName}
                    </button>
                  ))}
                </div>

                {subAttribute ? (
                  <div>
                    <h6 className="text-start fw-bold">
                      {subAttribute?.attributeName}
                    </h6>
                    <div className="d-flex align-items-center mb-2">
                      {subAttribute?.childData?.map((child, i) => (
                        <button
                          className={`product_size ${
                            child?.childId === activeSubRootButtonId
                              ? "active"
                              : ""
                          }`}
                          key={i}
                          onClick={() =>
                            handleSubrootAttribute(
                              child?.childId,
                              child?.variationId
                            )
                          }
                        >
                          {child?.childName}
                        </button>
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="text-start  mb-2">
                  <button
                    className="product_middletagcartbutton"
                    onClick={() => handleAddToCart()}
                  >
                    {t("addtocart")}
                  </button>
                </div>

                <div className="product_middleoriginal text-start">
                  <p>{t("originals")}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-lg-0 mt-3">
              <div className="product_middledeliverydetail">
                <div className="d-flex align-items-center mb-2 product_middledeliverytext">
                  <BsTruck className="me-3" />
                  <p className="text-start m-0">{t("getitby")} Sun, June 25</p>
                </div>

                <div className="d-flex align-items-center mb-2 product_middledeliverytext">
                  <AiOutlineWallet className="me-3" />
                  <p className="text-start m-0">{t("payonline")}</p>
                </div>

                <div className="d-flex align-items-center mb-2 product_middledeliverytext">
                  <TfiReload className="me-3" />
                  <p className="text-start m-0">{t("easyreturn")}</p>
                </div>

                {/* <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div>
                      <AiOutlineReload className="me-3" />
                    </div>
                    <div className="me-2">
                      <p className="m-0 text-start">Return & Exchange Policy</p>
                    </div>
                  </div>

                  <div>
                    <MdOutlineKeyboardArrowDown />
                  </div>
                </div> */}
                <hr />
                <div className="product_middleabout">
                  <h6 className="text-start"> {t("aboutyaliwe")}</h6>
                  <div className="d-flex align-items-center">
                    <img src="/yaliwe_navbar_icon.png" alt="" />
                    <p className="text-start ms-2">{t("aboutyaliwepara")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="product_description mt-3">
            <div>
              <h3 className="text-start"> {t("productdescription")}</h3>
              <p className="text-start">{productData?.description}</p>
            </div>
          </div>

          <h3 className="text-start">{t("topreviews")}</h3>
          <div className="product_topreviews text-start">
            {topReviews?.length === undefined ? (
              <>
                <div className="mb-2">{t("noreviews")}</div>
              </>
            ) : (
              <>
                {userReview?.map((review) => (
                  <div key={review.id} className="mb-3 product_reviewsmaindiv">
                    <p className="m-0 fw-bold">{review?.createdBy?.name_en}</p>
                    <div className="d-flex align-items-center mb-1">
                      <Rating
                        initialValue={review.rating}
                        readonly
                        size={15}
                        fillColor="gold"
                        className="me-1"
                      />
                      <p className="review_createdat m-0">
                        {review?.createdAt}
                      </p>
                    </div>
                    <h6 className="mb-1 fw-bold">{review?.title}</h6>
                    <p className="mb-0">{review?.message}</p>
                    <div className="d-flex align-items-center">
                      {review?.images?.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Review ${index + 1}`}
                          className="product_reviewsimages"
                          onClick={() => handleReviewImageClick(image)}
                        />
                      ))}
                    </div>
                    <div
                      className="d-flex align-items-center mt-2"
                      onClick={() => handleDeleteReview(review?.id)}
                    >
                      <span className="review_deletebuttontext">
                        {t("delete")}
                      </span>
                      <MdDelete className="review_deletebutton" />
                    </div>
                  </div>
                ))}
                {topReviews?.map((review) => (
                  <div key={review.id} className="mb-3 product_reviewsmaindiv ">
                    <p className="m-0 fw-bold">{review?.createdBy?.name_en}</p>
                    <div className="d-flex align-items-center">
                      <Rating
                        initialValue={review.rating}
                        readonly
                        size={15}
                        fillColor="gold"
                        className="me-1"
                      />
                      <p className="review_createdat m-0">
                        {review?.createdAt}
                      </p>
                    </div>
                    <h6 className="mb-1 fw-bold">{review?.title}</h6>
                    <p className="mb-0">{review?.message}</p>
                    <div className="d-flex align-items-center">
                      {review?.images?.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Review ${index + 1}`}
                          className="product_reviewsimages"
                          onClick={() => handleReviewImageClick(image)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="category_paginate">
            {totalPageCount > 1 && (
              <ReactPaginate
                pageCount={totalPageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active-page"}
              />
            )}
          </div>
          <div className="product_addReview">
            <h3 className="text-start my-3">{t("addyourreview")}</h3>
            <div className="text-start mb-2">
              <Rating
                initialValue={reviewRating}
                size={30}
                fillColor="gold"
                onPointerEnter={(rating) => handleReviewRatingChange(rating)}
                onClick={(rating) => handleReviewRatingChange(rating)}
              />
            </div>

            <form className="review_form">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control productreview_input"
                  placeholder={t("enteryourfeedback")}
                  value={reviewTitle}
                  onChange={handleReviewTitleChange}
                />
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control productreview_input"
                  rows="4"
                  placeholder={t("message")}
                  value={reviewDescription}
                  onChange={handleReviewDescriptionChange}
                />
              </div>
              <div className="mb-3">
                <div className="selected-images-container d-flex">
                  {selectedReviewImages?.map((image, index) => (
                    <div key={index} className="selected-image">
                      <img
                        src={image}
                        alt={`Selected ${index + 1}`}
                        style={{ width: "100px" }}
                      />
                      <span
                        className="cancel-image"
                        onClick={() => handleCancelImage(index)}
                      >
                        &#10006;
                      </span>
                    </div>
                  ))}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  className="form-control productreview_input"
                  multiple
                  onChange={handleReviewImageChange}
                  ref={fileInputRef}
                />
              </div>
              <div className="text-start">
                <button
                  type="button"
                  className="productreview_submit_button"
                  onClick={handleSubmitReview}
                >
                  {t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ConstructionProducts />
      <ElectronicsProducts />
      <HairWigProducts />
      <Footer />
    </>
  );
}

export default Product;
