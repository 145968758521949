import { ActionTypes } from "../constant/action-types";

const initialState = {
  addresses: [],
};

export const addressReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_ALLADDRESS:
      return { ...state, addresses: payload || [] };
    case ActionTypes.EMPTY_ALLADDRESS:
      return { ...state, addresses: payload };
    default:
      return state;
  }
};
