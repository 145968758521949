import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { orderStatusApi } from "../Apis/MainApis";
import "../Styles/Success.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Success() {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const id = orderId?.split("=")[1];
  const navigate = useNavigate();

  useEffect(() => {
    async function orderstatus() {
      const data = {
        orderId: id,
      };
      await orderStatusApi(data);
    }
    orderstatus();
  }, [id]);

  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="success_card">
        <div>
          <i className="checkmark">✓</i>
        </div>
        <h1>{t("paymentsuccess")}</h1>
        <p className="mb-2">
          {t("wereceivedyourpurchaserequest")}
          <br /> {t("wewillbeintouchshortly")}
        </p>
        <span className="checkout_homepage" onClick={() => navigate("/")}>
          {t("gotohomepage")}
        </span>
      </div>
    </div>
  );
}

export default Success;
