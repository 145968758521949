import React from "react";
import "../Styles/BestProducts.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LoginModal from "../Modal/LoginModal";
import { AiFillStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function BestProducts() {
  const { t } = useTranslation();
  const collectionData = useSelector(
    (state) => state?.homeproducts?.homeproducts
  );

  const userid = useSelector((state) => state.user.user.id);

  const [blackFridaySaleproduct, setNewArrivalProducts] = useState([]);
  const [showSignin, setShowSignin] = useState(false);
  const [sliderSlug, setSlug] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const newArrival = collectionData[0];
    setNewArrivalProducts(newArrival?.products);
    setSlug(newArrival?.collectionSlug);
  }, [collectionData]);

  const handleBestProductNavigate = (id, productslug) => {
    navigate(`/product/${id}/${productslug}`);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const closeModals = () => {
    setShowSignin(false);
  };

  return (
    <>
      <LoginModal show={showSignin} onClose={closeModals} />
      <div className="mb-5 bestproducts_container">
        <div className="container">
          <div className="row mb-3">
            <div className="col-lg-12">
              <div className="fw-bold bestproduct_heading">
                {t("bestselling")}{" "}
                <span className="bestproduct_highlight">{t("products")}</span>
              </div>
              <p className="bestproduct_para">{t("bestproducts")}</p>
            </div>
          </div>

          <div className="row g-4">
            {blackFridaySaleproduct?.map((product, i) => (
              <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={i}>
                <div
                  className="card bestproduct_card"
                  onClick={() =>
                    handleBestProductNavigate(product?.id, product?.slug)
                  }
                >
                  <div className="bestproduct_cardtopportion">
                    <div className="bestproduct_imagebackground">
                      <img
                        className="bestproduct_image"
                        src={product?.featured_image}
                        alt=""
                      ></img>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <h5 className="bestproduct_cardtitle text-start m-0">
                          {product?.categories[0]?.categoryDetails?.name}
                        </h5>
                        <span className="d-flex align-items-center category_ratingbox ms-2">
                          <AiFillStar className="me-1" />
                          {product.averageRating}
                        </span>
                      </div>
                    </div>
                    <p className="bestproduct_cardtext text-start fw-bold  m-0 py-2">
                      {`${product.name}`}
                    </p>
                    <div className="bestproducts_span text-start d-flex align-items-center">
                      <span className="fw-bold">${product?.sale_price}</span>
                      <span>
                        <div className="diagonal-line-wrapper">
                          ${product?.base_price}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default BestProducts;
