import React, { useState } from "react";
import "../Styles/Find.css";
import TopNavbar from "../Components/TopNavbar";
import Navbar from "../Components/Navbar";
import BottomNavbar from "../Components/BottomNavbar";
import Footer from "./Footer";
import { hardToFindApi } from "../Apis/MainApis";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function Find() {
  const { t } = useTranslation();
  const [input, setInput] = useState({
    brand: "",
    modelname: "",
    part: "",
    username: "",
    email: "",
    phone: "",
    textarea: "",
  });
  const [Form_validation_error, setForm_validation_error] = useState({});
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm_validation_error({});
    setInput({ ...input, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form_validation()) {
      if (input.phone.length < 8) {
        toast.error(t("mobilenoshouldbebetween8to12digits"));
        return;
      }
      const data = {
        brand: input.brand,
        model: input.modelname,
        part_no: input.part,
        name: input.username,
        email: input.email,
        phone: input.phone,
        comments: input.textarea,
      };

      const response = await hardToFindApi(data);
      console.log(response);
      Swal.fire({
        icon: "success",
        title: t("datasubmittedsuccessfully"),
        text: response.data.message,
      });
      setInput({
        brand: "",
        modelname: "",
        part: "",
        username: "",
        email: "",
        phone: "",
        textarea: "",
      });
    } else {
      console.log("Form is invalid");
    }
  };

  const form_validation = () => {
    let form_input = {};
    if (input.email === "") {
      form_input.email = t("emailisrequired");
    }
    if (input.phone === "") {
      form_input.phone = t("phoneisrequired");
    }
    if (input.username === "") {
      form_input.username = t("usernameisrequired");
    }
    if (input.textarea === "") {
      form_input.textarea = t("textareaisrequired");
    }
    if (input.brand === "") {
      form_input.brand = t("brandisrequired");
    }
    if (input.modelname === "") {
      form_input.modelname = t("modelnameisrequired");
    }
    setForm_validation_error(form_input);
    return Object.keys(form_input).length === 0;
  };
  return (
    <>
      <TopNavbar />
      <Navbar />
      <BottomNavbar />
      <div className="container py-5">
        <div className="row find_row">
          <div className="col-12 col-lg-6 col-md-5 d-flex flex-column ">
            <h3 className="text-start fw-bold">{t("hardtofindproducts")}?</h3>
            <h6 className="text-start fw-bold">{t("hardtofindproducts")}?</h6>
            <p className="text-start find_text">
              {t("whetherthepartisobsolute")}
            </p>
            <p className="text-start find_text">{t("pleasefilltheform")}</p>
            <div className="text-start">
              <img
                src="https://i.pinimg.com/564x/0f/58/0d/0f580d888d40fbfb9fe89a2c02c5ebf3.jpg"
                alt="find"
                className="find_image"
              ></img>
            </div>
          </div>
          <div className="col-12  col-lg-6 col-md-7 m-lg-0 mt-5">
            <div className="form">
              <div className="d-flex justify-content-center align-items-center">
                <div className="formheading">
                  <form onSubmit={handleSubmit}>
                    <div className="my-2 fs-4 text-start fw-bold">
                      {t("iteminformation")}
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 m-1">{t("brand")}*</p>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="brand"
                        value={input.brand}
                        onChange={handleInput}
                        style={{
                          border: Form_validation_error.brand
                            ? "1px solid"
                            : "",
                          color: Form_validation_error.brand ? "red" : "",
                        }}
                      />
                      {Form_validation_error.email && (
                        <div className="text-start text-danger">
                          {Form_validation_error.brand}
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 m-1">{t("model")}*</p>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="modelname"
                        value={input.modelname}
                        onChange={handleInput}
                        style={{
                          border: Form_validation_error.modelname
                            ? "1px solid"
                            : "",
                          color: Form_validation_error.modelname ? "red" : "",
                        }}
                      />
                      {Form_validation_error.modelname && (
                        <div className="text-start text-danger">
                          {Form_validation_error.modelname}
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 m-1">{t("partno")}.</p>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="part"
                        value={input.part}
                        onChange={handleInput}
                        style={{
                          border: Form_validation_error.part ? "1px solid" : "",
                          color: Form_validation_error.part ? "red" : "",
                        }}
                      />
                      {Form_validation_error.part && (
                        <div className="text-start text-danger">
                          {Form_validation_error.part}
                        </div>
                      )}
                    </div>

                    <div className="my-2 fs-4 text-start fw-bold">
                      {t("personalinformation")}{" "}
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 m-1">{t("name")}*</p>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        value={input.username}
                        onChange={handleInput}
                        style={{
                          border: Form_validation_error.username
                            ? "1px solid"
                            : "",
                          color: Form_validation_error.username ? "red" : "",
                        }}
                      />
                      {Form_validation_error.username && (
                        <div className="text-start text-danger">
                          {Form_validation_error.username}
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 m-1">{t("email")}*</p>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={input.email}
                        onChange={handleInput}
                        style={{
                          border: Form_validation_error.email
                            ? "1px solid"
                            : "",
                          color: Form_validation_error.email ? "red" : "",
                        }}
                      />
                      {Form_validation_error.email && (
                        <div className="text-start text-danger">
                          {Form_validation_error.email}
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 m-1">{t("phone")}*</p>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="phone"
                        value={input.phone}
                        onChange={handleInput}
                        style={{
                          border: Form_validation_error.phone
                            ? "1px solid"
                            : "",
                          color: Form_validation_error.phone ? "red" : "",
                        }}
                      />
                      {Form_validation_error.phone && (
                        <div className="text-start text-danger">
                          {Form_validation_error.phone}
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 m-1">{t("comments")}</p>
                      </div>
                      <textarea
                        type="text"
                        className="form-control"
                        row="5"
                        name="textarea"
                        value={input.textarea}
                        onChange={handleInput}
                        style={{
                          border: Form_validation_error.textarea
                            ? "1px solid"
                            : "",
                          color: Form_validation_error.textarea ? "red" : "",
                        }}
                      ></textarea>
                      {Form_validation_error.textarea && (
                        <div className="text-start text-danger">
                          {Form_validation_error.textarea}
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button type="submit" className="find_button">
                        {t("sendrequest")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Find;
