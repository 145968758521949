import axios from "axios";
import { baseurl } from "../baseurl";
import Swal from "sweetalert2";

export async function orderStatusApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseurl}order-payment-details`, data, {
      headers,
    });
    console.log("response from orderStatusApi", response);
    return response;
  } catch (error) {
    console.log("Error in  orderStatusApi:", error);
    Swal.fire({
      icon: "error",
      title: "Failed",
      text: error.response.data.message,
    });
    return null;
  }
}

export async function hardToFindApi(data) {
  try {
    const response = await axios.post(`${baseurl}hard-to-find`, data);
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in  hardToFindApi:", error);
    Swal.fire({
      icon: "error",
      title: "Failed",
      text: error.response.data.message,
    });
    return null;
  }
}

export async function deleteReviewApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseurl}delete-review`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in  deleteReviewApi:", error);
    Swal.fire({
      icon: "error",
      title: "Delete Review Failed",
      text: error.response.data.message,
    });
    return null;
  }
}

export async function orderCancelApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseurl}order-cancel`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in  orderCancelApi:", error);
    Swal.fire({
      icon: "error",
      title: "Ordered Cancelled Failed",
      text: error.response.data.message,
    });
    return null;
  }
}

export async function deleteAccountApi() {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(
      `${baseurl}delete-account`,
      {},
      {
        headers,
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in  changePasswordApi:", error);
    return null;
  }
}

export async function changePasswordApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseurl}password-change`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in  changePasswordApi:", error.response.data.message);
    Swal.fire({
      icon: "error",
      title: "Change Password Failed",
      text: error.response.data.message,
    });
    return null;
  }
}

export async function contactUsApi(data) {
  try {
    const response = await axios.post(`${baseurl}contact-us`, data);
    return response;
  } catch (error) {
    console.log("Error in  contactUsApi:", error);
    console.log(error.response.data.message);
    Swal.fire({
      icon: "warning",
      title: "Contact Us",
      text: error.response.data.message,
    });
    return null;
  }
}

export async function fetchBrandApi() {
  const lang = localStorage.getItem("Yaliwe_language");
  try {
    const data = {
      language: lang || "en",
    };
    const response = await axios.post(`${baseurl}fetch-brand`, data);
    return response;
  } catch (error) {
    console.log("Error in fetchBrandApi:", error);
    return null;
  }
}

export async function searchApi(data) {
  try {
    const response = await axios.post(`${baseurl}search-products`, data);
    return response;
  } catch (error) {
    console.log("Error in  searchApi:", error);
    return null;
  }
}

export async function profilePicApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseurl}profile-update`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in  profilePicApi:", error);
    return null;
  }
}

export async function updateInfoApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseurl}info-update`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in  updateInfoApi:", error);
    return null;
  }
}

export async function fetchChildCategoryApi(data) {
  try {
    const response = await axios.post(`${baseurl}fetch-child-categories`, data);
    return response;
  } catch (error) {
    console.log("Error in fetchChildCategoryApi:", error);
    return null;
  }
}

export async function deleteAddressApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}delete-address`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in deleteAddressApi:", error);
    return null;
  }
}

export async function fetchCategoryProduuctApi(data) {
  try {
    const response = await axios.post(
      `${baseurl}fetch-category-products`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error in fetchCategoryProduuctApi:", error.response);
    return null;
  }
}

export async function parentCategoryApi(data) {
  const lang = localStorage.getItem("Yaliwe_language");
  try {
    const data = {
      language: lang || "en",
    };
    const response = await axios.post(
      `${baseurl}fetch-parent-categories`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error in parentCategoryApi:", error);
    return null;
  }
}

export async function imageuploadApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}image-uploads-base`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in  imageuploadApi:", error);
    return null;
  }
}

export async function singleOrderFetchApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}order-fetch-single`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in  singleOrderFetchApi:", error);
    return null;
  }
}

export async function orderFetchApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}order-fetch`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in orderFetchApi:", error);
    return null;
  }
}

export async function orderCreateApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}order-create`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in orderCreateApi:", error);
    return null;
  }
}

export async function productReviewApi(data) {
  try {
    const response = await axios.post(`${baseurl}product-reviews`, data);
    return response;
  } catch (error) {
    console.log("Error in productReviewApi:", error);
    return null;
  }
}

export async function addReviewApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}add-review`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in addReviewApi:", error);
    Swal.fire({
      icon: "error",
      title: "Yaliwe",
      text: "There is some issue ",
    });
    return null;
  }
}

export async function fetchsingleAddressApi(id) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(`${baseurl}fetch-single-address/${id}`, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in fetchsingleAddressApi:", error);
    return null;
  }
}

export async function updateAddressApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}update-address`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in updateAddressApi:", error);
    return null;
  }
}

export async function discountApi(data) {
  try {
    const response = await axios.post(`${baseurl}discount-offers`, data);
    return response;
  } catch (error) {
    console.log("Error in discountApi:", error);
    return null;
  }
}

export async function removeCouponApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}coupon-remove`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in removeCouponApi:", error);
    return null;
  }
}

export async function applyCouponApi(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}coupon-apply`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in applyCouponApi:", error);
    return null;
  }
}

export async function addAddress(data) {
  const token = localStorage.getItem("Yaliwe_Token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseurl}add-address`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in fetchSingleProductVariationApi:", error);
    return null;
  }
}

export async function fetchSingleProductVariationApi(data) {
  try {
    const response = await axios.post(
      `${baseurl}single-product-variation`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error in fetchSingleProductVariationApi:", error);
    return null;
  }
}

export async function fetchSingleProductVariationChildApi(data) {
  try {
    const response = await axios.post(
      `${baseurl}single-product-variation-child`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error in fetchSingleProductVariationChildApi:", error);
    return null;
  }
}

export async function fetchSingleProductApi(data) {
  try {
    const response = await axios.post(`${baseurl}single-product`, data);
    return response;
  } catch (error) {
    console.log("Error in fetchSingleProductApi:", error);
    return null;
  }
}

export async function fetchParentCategoryApi(data) {
  try {
    const response = await axios.post(
      `${baseurl}fetch-parent-categories`,
      data
    );
    return response;
  } catch (error) {
    console.log("Error in fetchParentCategoryApi:", error);
    return null;
  }
}

export async function fetchHomeProductsApi(data) {
  try {
    const response = await axios.post(`${baseurl}fetch-products-home`, data);
    return response;
  } catch (error) {
    console.log("Error in fetchHomeProductsApi:", error);
    return null;
  }
}
