import { ActionTypes } from "../constant/action-types";
import axios from "axios";
import { baseurl } from "../../baseurl";

export const fetchHomeProducts = (userid) => {
  return async function (dispatch, getState) {
    const lang = localStorage.getItem("Yaliwe_language");
    const data = {
      language: lang || "en",
      uid: userid || "",
    };
    const response = await axios.post(`${baseurl}fetch-products-home`, data);
    dispatch({
      type: ActionTypes.FETCH_HOMEPRODUCTS,
      payload: response?.data?.data?.homeProduct,
    });
  };
};

export const setProducts = (products) => {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: products,
  };
};
